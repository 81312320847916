function exportReport(scope, mdPanel, reportConfig, genericFlag, externalData) {
  let rawData = [];
  let clonedData = [];
  let dataLength = 0;
  let emptyTopFilters = [];
  let externalDataExportFlag = false;

  if (externalData == null || (externalData && !Array.isArray(externalData))) {
    if (reportConfig.targetData == "filtered") {
      rawData = scope.gridApi.core.getVisibleRows(scope.gridApi.grid);

      for (let i = 0; i < rawData.length; i++) {
        const element = rawData[i];
        clonedData.push(element.entity);
      }
    }

    if (reportConfig.targetData == "grouping") {
      //console.log(scope.exportUiGridService.uiGridExporterService.getData(scope.gridApi.grid, "all", "all"));
      rawData = scope.exportUiGridService.uiGridExporterService.getData(scope.gridApi.grid, "visible", "visible");
    }

    if (reportConfig.targetData == "all" || reportConfig.targetData == "groupingSubRowsByFilters") {
      rawData = scope.exportUiGridService.uiGridExporterService.getData(scope.gridApi.grid, "all", "all");
    }

    if (reportConfig.targetData == "groupingSubRowsByFilters") {
      for (let l = 0; l < reportConfig.targetDataFilters.length; l++) {
        let filter = reportConfig.targetDataFilters[l];
        if (scope.topFilters[filter] == null || scope.topFilters[filter] == "" || scope.topFilters[filter] == "Todos") {
          emptyTopFilters.push(scope.activeTab.fields[filter].label);
        }
      }

      if (emptyTopFilters.length == 0) {
        for (let i = 0; i < rawData.length; i++) {
          const row = rawData[i];
          let parsedRow = {};
          for (let j = 0; j < row.length; j++) {
            const rowEl = row[j];
            parsedRow[scope.gridOptions.columnDefs[j].field] = rowEl.value;
          }
          for (let w = 0; w < reportConfig.targetDataFilters.length; w++) {
            const filter = reportConfig.targetDataFilters[w];
            if (scope.topFilters[filter] != parsedRow[filter]) {
              break;
            }
            if (w == reportConfig.targetDataFilters.length - 1) {
              clonedData.push(parsedRow);
            }
          }
        }
      }
    }

    if (reportConfig.targetData == "grouping" || reportConfig.targetData == "all") {
      for (let i = 0; i < rawData.length; i++) {
        const row = rawData[i];
        let parsedRow = {};
        for (let j = 0; j < row.length; j++) {
          const rowEl = row[j];
          parsedRow[scope.gridOptions.columnDefs[j].field] = rowEl.value;
        }
        clonedData.push(parsedRow);
      }
    } else if (reportConfig.targetData == "selected") {
      if (scope.selectedRow == null) {
        scope.$parent.noSeletedEntryToExport();
        return;
      } else {
        clonedData = [scope.selectedRow];
      }
    }

    clonedData = JSON.parse(JSON.stringify(clonedData));
  } else {
    externalDataExportFlag = true;
    clonedData = JSON.parse(JSON.stringify(externalData));
  }

  let parsedData = [];

  if (genericFlag) {
    if (reportConfig.activeTab) {
      parsedData = parentCommunicationParseData(scope, clonedData, true, reportConfig);
    } else {
      parsedData = parseData(scope, clonedData, true);
    }
  } else {
    parsedData = parseData(scope, clonedData);
  }

  if (reportConfig.dataTransformFunction) {
    if (reportConfig.targetData == "groupingSubRowsByFilters") {
      let transformResult = window[reportConfig.dataTransformFunction](parsedData, scope);
      parsedData = transformResult.data;
      dataLength = transformResult.length;
      showExportReportDialog();
    } else {
      if (reportConfig.promiseDataTransfFunction) {
        let promiseArr = [];
        promiseArr.push(
          window[reportConfig.dataTransformFunction](parsedData, scope, reportConfig)
            .then(function whenOk(response) {
              if (response != null && Array.isArray(response) && response.length > 0) {
                parsedData = response;
              } else if (response != null && response) {
                return parsedData;
              }
            })
            .catch(function notOk(err) {
              console.catch(err);
            })
        );
        Promise.all(promiseArr).then(function (success) {
          showExportReportDialog();
        });
      } else {
        parsedData = window[reportConfig.dataTransformFunction](parsedData, scope, reportConfig);
        if (
          (!externalDataExportFlag || reportConfig.targetData == "selected") &&
          reportConfig.sendCommunicationMode == null
        ) {
          showExportReportDialog();
        } else {
          return exportDataAsBuffer(scope, reportConfig, parsedData);
        }
      }
    }
  } else {
    if (!externalDataExportFlag) {
      showExportReportDialog();
    } else {
      return exportDataAsBuffer(scope, reportConfig, parsedData);
    }
  }

  function showExportReportDialog() {
    if (Array.isArray(parsedData[0]) && dataLength == 0) {
      for (let i = 0; i < parsedData.length; i++) {
        const el = parsedData[i];
        dataLength += el.length;
      }
    } else if (reportConfig.targetData != "groupingSubRowsByFilters") {
      dataLength = parsedData.length;
    }
    if (scope.module.limitRowExport) {
      if (dataLength > scope.module.limitRowExport) {
        scope.$mdDialog.show(
          scope.$mdDialog
            .alert()
            .clickOutsideToClose(true)
            .title("Limite de Registos a Exportar")
            .textContent(
              "Por favor reduza a informação a exportar (utilizando os filtros disponíveis) para que esta seja corretamente apresentada no relatório PDF pretendido."
            )
            .ariaLabel("Nenhum Registo a Exportar")
            .ok("Ok")
        );
        return;
      }
    }
    /* if (genericFlag) {
      dataLength -= 1;
    } */
    var confirm = scope.$mdDialog
      .confirm()
      .title(scope.translateText("exportDocDialogHeader"))
      .textContent(
        scope.translateText("exportDocDialogDesc1") +
          " " +
          dataLength +
          " " +
          scope.translateText("exportDocDialogDesc2")
      )
      .ok(scope.translateText("yes"))
      .cancel(scope.translateText("no"));

    if (dataLength > 0) {
      scope.$mdDialog.show(confirm).then(
        function () {
          scope.$parent.showLoader();

          parsedData = {
            transports: parsedData,
          };

          fetch("/api/Transp_Studs/getJSReport", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: scope.genericFactory.$http.defaults.headers.common["Authorization"],
            },
            body: JSON.stringify({
              template: {
                content: reportTemplates[reportConfig.reportTemplate],
                engine: "handlebars",
                recipe: "chrome-pdf",
                chrome: {
                  displayHeaderFooter: true,
                  printBackground: true,
                  marginTop: reportConfig.marginTop,
                  marginBottom: reportConfig.marginBottom,
                  headerTemplate: headerTemplates[reportConfig.headerTemplate],
                  footerTemplate: footerTemplates[reportConfig.footerTemplate],
                  timeout: 100000,
                },
              },
              data: parsedData,
            }),
          })
            .then(function (response) {
              return response.json();
            })
            .then(function (response) {
              response = response.$data;
              const linkSource = `data:application/pdf;base64,${response}`;
              const downloadLink = document.createElement("a");
              const fileName = reportConfig.reportFileName + new Date().toLocaleDateString();
              downloadLink.href = linkSource;
              downloadLink.download = fileName;
              downloadLink.click();
              scope.$parent.entriesExported(dataLength);
              scope.$parent.hideLoader();
            }),
            function errorCallback(response) {
              console.log(response);
              scope.$parent.entriesExportFail();
              scope.$parent.hideLoader();
            };
        },
        function () {
          return;
        }
      );
    } else {
      if (reportConfig.promiseDataTransfFunction || reportConfig.targetData != "groupingSubRowsByFilters") {
        scope.$mdDialog.show(
          scope.$mdDialog
            .alert()
            .clickOutsideToClose(true)
            .title("Nenhum Registo a Exportar")
            .textContent("")
            .ariaLabel("Nenhum Registo a Exportar")
            .ok("Ok")
        );
      } else {
        scope.$mdDialog.show(
          scope.$mdDialog
            .alert()
            .clickOutsideToClose(true)
            .title("Nenhum Registo a Exportar")
            .textContent("Por favor preencha os filtros: " + emptyTopFilters.toString().replace(/,/g, ", ") + ".")
            .ariaLabel("Nenhum Registo a Exportar")
            .ok("Ok")
        );
      }
    }
  }
}

function exportDataAsBuffer(scope, reportConfig, parsedData) {
  if (parsedData.length > 0) {
    parsedData = {
      transports: parsedData,
    };

    return fetch("/api/Transp_Studs/getJSReport", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: scope.genericFactory.$http.defaults.headers.common["Authorization"],
      },
      body: JSON.stringify({
        template: {
          content: reportTemplates[reportConfig.reportTemplate],
          engine: "handlebars",
          recipe: "chrome-pdf",
          chrome: {
            displayHeaderFooter: true,
            printBackground: true,
            marginTop: reportConfig.marginTop,
            marginBottom: reportConfig.marginBottom,
            headerTemplate: headerTemplates[reportConfig.headerTemplate],
            footerTemplate: footerTemplates[reportConfig.footerTemplate],
            timeout: 100000,
          },
        },
        data: parsedData,
      }),
    });
  } else {
    return null;
  }
}

function parseData(scope, data, genericReportExportFlag) {
  var parseValue = scope.parseValue;
  var parsedData = [];
  for (let i = 0; i < data.length; i++) {
    let row = data[i];
    for (const key in row) {
      if (row.hasOwnProperty(key)) {
        let rowElement = row[key];
        if (scope.activeTab.fields[key]) {
          if (
            scope.activeTab.fields[key].fromTable != null &&
            scope.activeTab.fields[key].viewType != "calculatedList"
          ) {
            row[key] = parseValue(rowElement, key);
          }
          if (scope.activeTab.fields[key].viewType == "date" && rowElement) {
            if (rowElement != null && (typeof rowElement != "string" || rowElement.indexOf("/") == -1)) {
              row[key] = new Date(rowElement).toLocaleDateString("pt-PT");
            }
          }
          if (
            scope.activeTab.fields[key].viewType == "dropdown" &&
            scope.activeTab.fields[key].list != null &&
            rowElement
          ) {
            row[key] = rowElement.toString();
          }

          if (scope.activeTab.fields[key].viewType == "dynamicField") {
            let genericExportDynRow = [];
            for (let j = 0; j < row[key].length; j++) {
              let dynamicSubRow = row[key][j];
              let genericExportSubDynRow = {};
              for (const dynKey in dynamicSubRow) {
                if (dynamicSubRow.hasOwnProperty(dynKey)) {
                  let dynamicElement = dynamicSubRow[dynKey];
                  if (scope.activeTab.fields[key].dynamicFields[dynKey]) {
                    if (scope.activeTab.fields[key].dynamicFields[dynKey].fromTable != null) {
                      if (
                        genericReportExportFlag &&
                        Object.keys(scope.activeTab.fields[key].dynamicFields).length > 1
                      ) {
                        if (scope.activeTab.fields[key].dynamicFields[dynKey].auxFieldName) {
                          genericExportSubDynRow[dynKey] =
                            scope.activeTab.fields[key].dynamicFields[dynKey].label +
                            ": " +
                            parseValue(
                              dynamicElement,
                              dynKey,
                              null,
                              scope.activeTab.fields[key].dynamicFields[dynKey].auxFieldName
                            );
                        } else {
                          genericExportSubDynRow[dynKey] =
                            scope.activeTab.fields[key].dynamicFields[dynKey].label +
                            ": " +
                            parseValue(dynamicElement, dynKey);
                        }
                      } else {
                        genericExportSubDynRow[dynKey] = parseValue(dynamicElement, dynKey);
                        dynamicSubRow[dynKey] = parseValue(dynamicElement, dynKey);
                      }
                    } else if (scope.activeTab.fields[key].dynamicFields[dynKey].viewType == "date" && dynamicElement) {
                      if (
                        genericReportExportFlag &&
                        Object.keys(scope.activeTab.fields[key].dynamicFields).length > 1
                      ) {
                        genericExportSubDynRow[dynKey] =
                          scope.activeTab.fields[key].dynamicFields[dynKey].label +
                          ": " +
                          new Date(dynamicElement).toLocaleDateString();
                      } else {
                        genericExportSubDynRow[dynKey] = new Date(dynamicElement).toLocaleDateString();
                        dynamicSubRow[dynKey] = new Date(dynamicElement).toLocaleDateString();
                      }
                    } else {
                      if (
                        genericReportExportFlag &&
                        Object.keys(scope.activeTab.fields[key].dynamicFields).length > 1
                      ) {
                        if (
                          dynamicSubRow[dynKey] != null &&
                          dynamicSubRow[dynKey] != "" &&
                          dynamicSubRow[dynKey] != []
                        ) {
                          genericExportSubDynRow[dynKey] =
                            scope.activeTab.fields[key].dynamicFields[dynKey].label + ": " + dynamicSubRow[dynKey];
                        }
                      } else {
                        genericExportSubDynRow[dynKey] = dynamicSubRow[dynKey];
                      }
                    }
                  }
                }
              }
              genericExportDynRow.push(genericExportSubDynRow);
            }
            if (genericReportExportFlag && row[key] != null && row[key] != "" && row[key].length > 0) {
              row[key] = genericExportDynRow;
              let rowContent = [];
              row[key].forEach((dynRow) => {
                rowContent.push(Object.values(dynRow));
              });
              rowContent.unshift(scope.activeTab.fields[key].label);
              row[key] = rowContent;
            }
          }
        }
      }
    }
    parsedData.push(row);
  }
  return parsedData;
}

// Reporting Functions

function parentCommunicationParseData(scope, data, genericReportExportFlag, reportConfig) {
  var parseValue = scope.parseValue;
  var parsedData = [];
  for (let i = 0; i < data.length; i++) {
    let row = data[i];
    for (const key in row) {
      if (row.hasOwnProperty(key)) {
        let rowElement = row[key];
        if (reportConfig.activeTab.fields[key]) {
          if (reportConfig.activeTab.fields[key].fromTable != null) {
            row[key] = parseValue(rowElement, key, reportConfig.activeTab.fields[key].fromTable);
          }
          if (reportConfig.activeTab.fields[key].viewType == "date") {
            if (rowElement != null && (typeof rowElement != "string" || rowElement.indexOf("/") == -1)) {
              row[key] = new Date(rowElement).toLocaleDateString();
            }
          }

          if (reportConfig.activeTab.fields[key].viewType == "dynamicField") {
            let genericExportDynRow = [];
            for (let j = 0; j < row[key].length; j++) {
              let dynamicSubRow = row[key][j];
              let genericExportSubDynRow = {};
              for (const dynKey in dynamicSubRow) {
                if (dynamicSubRow.hasOwnProperty(dynKey)) {
                  let dynamicElement = dynamicSubRow[dynKey];
                  if (reportConfig.activeTab.fields[key].dynamicFields[dynKey]) {
                    if (reportConfig.activeTab.fields[key].dynamicFields[dynKey].fromTable != null) {
                      if (
                        genericReportExportFlag &&
                        Object.keys(reportConfig.activeTab.fields[key].dynamicFields).length > 1
                      ) {
                        if (reportConfig.activeTab.fields[key].dynamicFields[dynKey].auxFieldName) {
                          genericExportSubDynRow[dynKey] =
                            reportConfig.activeTab.fields[key].dynamicFields[dynKey].label +
                            ": " +
                            parseValue(
                              dynamicElement,
                              dynKey,
                              reportConfig.activeTab.fields[key].dynamicFields[dynKey].fromTable,
                              reportConfig.activeTab.fields[key].dynamicFields[dynKey].auxFieldName
                            );
                        } else {
                          genericExportSubDynRow[dynKey] =
                            reportConfig.activeTab.fields[key].dynamicFields[dynKey].label +
                            ": " +
                            parseValue(
                              dynamicElement,
                              dynKey,
                              reportConfig.activeTab.fields[key].dynamicFields[dynKey].fromTable
                            );
                        }
                      } else {
                        genericExportSubDynRow[dynKey] = parseValue(
                          dynamicElement,
                          dynKey,
                          reportConfig.activeTab.fields[key].dynamicFields[dynKey].fromTable
                        );
                        dynamicSubRow[dynKey] = parseValue(
                          dynamicElement,
                          dynKey,
                          reportConfig.activeTab.fields[key].dynamicFields[dynKey].fromTable
                        );
                      }
                    } else if (reportConfig.activeTab.fields[key].dynamicFields[dynKey].viewType == "date") {
                      if (
                        genericReportExportFlag &&
                        Object.keys(reportConfig.activeTab.fields[key].dynamicFields).length > 1
                      ) {
                        genericExportSubDynRow[dynKey] =
                          reportConfig.activeTab.fields[key].dynamicFields[dynKey].label +
                          ": " +
                          new Date(dynamicElement).toLocaleDateString();
                      } else {
                        genericExportSubDynRow[dynKey] = new Date(dynamicElement).toLocaleDateString();
                        dynamicSubRow[dynKey] = new Date(dynamicElement).toLocaleDateString();
                      }
                    } else {
                      if (
                        genericReportExportFlag &&
                        Object.keys(reportConfig.activeTab.fields[key].dynamicFields).length > 1
                      ) {
                        if (
                          dynamicSubRow[dynKey] != null &&
                          dynamicSubRow[dynKey] != "" &&
                          dynamicSubRow[dynKey] != []
                        ) {
                          genericExportSubDynRow[dynKey] =
                            reportConfig.activeTab.fields[key].dynamicFields[dynKey].label +
                            ": " +
                            dynamicSubRow[dynKey];
                        }
                      } else {
                        genericExportSubDynRow[dynKey] = dynamicSubRow[dynKey];
                      }
                    }
                  }
                }
              }
              genericExportDynRow.push(genericExportSubDynRow);
            }
            if (genericReportExportFlag && row[key] != null && row[key] != "" && row[key].length > 0) {
              row[key] = genericExportDynRow;
              let rowContent = [];
              row[key].forEach((dynRow) => {
                rowContent.push(Object.values(dynRow));
              });
              rowContent.unshift(reportConfig.activeTab.fields[key].label);
              row[key] = rowContent;
            }
          }
        }
      }
    }
    parsedData.push(row);
  }
  return parsedData;
}

function genericParentCommunicationReportExport(data, scope, reportConfig) {
  //Table Columns and Row Field Columns
  let columns = [];
  data.forEach((el) => {
    el.rowDynColumnKeys = [];
    for (const field in reportConfig.activeTab.fields) {
      if (reportConfig.activeTab.fields.hasOwnProperty(field)) {
        const fieldDesc = reportConfig.activeTab.fields[field];
        if (
          fieldDesc.reportFieldConfig == null ||
          (fieldDesc.reportFieldConfig != null && fieldDesc.reportFieldConfig.hidden == null)
        ) {
          if (fieldDesc.hiddenInTable == false || fieldDesc.hiddenInTable == null) {
            if (el.rowColumnKeys == null) {
              el.rowColumnKeys = [];
            }
            el.rowColumnKeys.push(field);
            if (columns.indexOf(fieldDesc.label) == -1) {
              columns.push(fieldDesc.label);
            }
          } else if (fieldDesc.viewType == "dynamicField") {
            if (el.rowDynColumnKeys == null) {
              el.rowDynColumnKeys = [];
            }
            el.rowDynColumnKeys.push(field);
          }
        }
      }
    }
  });

  //Organization/cluster
  let cluster = scope.getOrgName(scope.currentUser.organization);

  for (let j = 0; j < data.length; j++) {
    let dataEl = data[j];
    dataEl.cluster = cluster;
    dataEl.title = reportConfig.exportTitle;
    if (reportConfig && reportConfig.reportNumber) {
      dataEl.reportNumber = reportConfig.reportNumber;
    }
    dataEl.columns = columns;
    dataEl.numColumns = columns.length;
    if (dataEl.createdBy != null && dataEl.createdBy.name != null && dataEl.createdBy.surname != null) {
      dataEl.creator = dataEl.createdBy.name + " " + dataEl.createdBy.surname;
    }
  }

  return data;
}

function genericReportExport(data, scope, reportConfig) {
  //Table Columns and Row Field Columns
  let columns = [];
  data.forEach((el) => {
    el.rowDynColumnKeys = [];
    for (const field in scope.activeTab.fields) {
      if (scope.activeTab.fields.hasOwnProperty(field)) {
        const fieldDesc = scope.activeTab.fields[field];
        if (
          fieldDesc.reportFieldConfig == null ||
          (fieldDesc.reportFieldConfig != null && fieldDesc.reportFieldConfig.hidden == null)
        ) {
          if (fieldDesc.hiddenInTable == false || fieldDesc.hiddenInTable == null) {
            if (el.rowColumnKeys == null) {
              el.rowColumnKeys = [];
            }
            el.rowColumnKeys.push(field);
            if (columns.indexOf(fieldDesc.label) == -1) {
              columns.push(fieldDesc.label);
            }
          } else if (fieldDesc.viewType == "dynamicField") {
            if (el.rowDynColumnKeys == null) {
              el.rowDynColumnKeys = [];
            }
            el.rowDynColumnKeys.push(field);
          }
        }
      }
    }
  });

  //Context
  let context = [];
  if (data.length > 0) {
    for (const topFilter in scope.topFilters) {
      if (scope.topFilters.hasOwnProperty(topFilter)) {
        const topFilterValue = scope.topFilters[topFilter];
        if (topFilterValue != null && topFilterValue != "") {
          let topFilterLabel = scope.activeTab.fields[topFilter].label;
          context.push(topFilterLabel + ": " + topFilterValue);
        }
      }
    }
  }

  context = context.toString().replace(/,/g, ",  ");

  //Organization/cluster
  let cluster = scope.getOrgName(scope.currentUser.organization);

  //Header logo
  let orgLogoPromiseArr = [];
  if (scope.$parent.clientHasOrganizationLogo) {
    let img = document.createElement("img");
    //img.src = "https://analytics.kstk.pt/api/containers/" + scope.currentUser.organization + "-logos/download/orgLogo.png";
    img.src = "/api/containers/" + scope.currentUser.organization + "-logos/download/orgLogo.svg";

    orgLogoPromiseArr.push(getImgBase64());

    function getImgBase64() {
      return new Promise((resolve, reject) => {
        img.onload = function () {
          let c = document.createElement("canvas");
          c.width = 200;
          c.height = 200;
          let ctx = c.getContext("2d");
          ctx.drawImage(img, 0, 0);
          resolve(c.toDataURL());
        };
        img.onerror = reject;
      });
    }
  }

  for (let j = 0; j < data.length; j++) {
    let dataEl = data[j];

    //Header logo
    if (scope.$parent.clientHasOrganizationLogo) {
      Promise.all(orgLogoPromiseArr).then(function (success) {
        for (let i = 0; i < success.length; i++) {
          dataEl.orgLogo = success[i];
        }
      });
    }

    if (reportConfig && reportConfig.orgNameFlag) {
      dataEl.orgName = cluster;
    } else {
      dataEl.cluster = cluster;
    }
    if (reportConfig && reportConfig.exportTitle) {
      dataEl.title = reportConfig.exportTitle;
    } else if (scope.activeTab.exportTitle) {
      dataEl.title = scope.activeTab.exportTitle;
    } else {
      dataEl.title = scope.module.name;
    }
    if (reportConfig && reportConfig.exportSubTitle) {
      dataEl.subTitle = reportConfig.exportSubTitle;
    }
    if (reportConfig && reportConfig.reportNumber) {
      dataEl.reportNumber = reportConfig.reportNumber;
    }

    //Year
    if (dataEl.year != null && dataEl.year.toString().length == 4) {
      dataEl.year = dataEl.year + "/" + (Number(dataEl.year) + 1);
    }

    if (dataEl.createdBy != null && dataEl.createdBy.name != null && dataEl.createdBy.surname != null) {
      dataEl.creator = dataEl.createdBy.name + " " + dataEl.createdBy.surname;
    }
    dataEl.context = context;
    dataEl.columns = columns;
    dataEl.numColumns = columns.length;
  }

  return data;
}

// Reporting Utils

function ptEval(data, scope) {
  data = genericReportExport(data, scope);

  let splitData = [];

  Object.values(groupBy2(data, "schoolYear")).map((arr) =>
    Object.values(groupBy2(arr, "class")).map((arr) =>
      Object.values(groupBy2(arr, "subject")).map(function (arr) {
        let finalSplitArray = Object.values(groupBy2(arr, "period"));
        for (let i = 0; i < finalSplitArray.length; i++) {
          const el = finalSplitArray[i];
          splitData.push(el);
        }
      })
    )
  );

  return splitData;
}

function ptEvalInstrumentByStudentSimplified(data, scope, reportConfig) {
  return new Promise((resolve, reject) => {
    let map = {};
    map.year = scope.selectedRow.year;
    map.schoolYear = new Array(scope.selectedRow.schoolYear);
    map.subject = new Array(scope.selectedRow.subject);
    scope.genericFactory.setRouteName("Class_Plan_Eval_Qualitative_Levels");
    scope.genericFactory
      .getByProperties(map, scope.currentUser.organization)
      .then(function (evalInstrumentQualitativeLevels) {
        let selectedRowEvalInstrumentQualitativeLevels;
        // Get eval instrument's rubrica
        if (scope.selectedRow.evalInstrumentQualitativeLevels != null) {
          selectedRowEvalInstrumentQualitativeLevels = evalInstrumentQualitativeLevels.filter(
            (el) => el.id == JSON.parse(scope.selectedRow.evalInstrumentQualitativeLevels).id
          );
          if (selectedRowEvalInstrumentQualitativeLevels.length) {
            selectedRowEvalInstrumentQualitativeLevels = selectedRowEvalInstrumentQualitativeLevels[0];
          }
        }
        // Set that as the module selected row
        let finalEvalFlag = false;
        for (let i = 0; i < data.length; ) {
          let el = data[i];

          if (el.splitFlag) {
            break;
          }

          if (finalEvalFlag && el.excludedFinalEvalFlag && el.excludedFinalEvalFlag.length > 0) {
            data.splice(i, 1);
            continue;
          }

          if (el.studentEvaluations) {
            for (let k = 0; k < el.studentEvaluations.length; k++) {
              let studEval = el.studentEvaluations[k];

              if (studEval.studEvalExcludedFinalEvalFlag && studEval.studEvalExcludedFinalEvalFlag.length > 0) {
                continue;
              }
              // Get evals when the instrument has no questions
              if (studEval.essentialLearningEvaluation != null && studEval.essentialLearningEvaluation.length > 0) {
                if (studEval.essentialLearningEvaluation && studEval.qualitativeEvalMap == null) {
                  for (let w = 0; w < studEval.essentialLearningEvaluation.length; w++) {
                    let newElement = JSON.parse(JSON.stringify(el));
                    let essenLearn = studEval.essentialLearningEvaluation[w];

                    newElement.essentialLearning = essenLearn.essentialLearning;
                    if (essenLearn.evaluation == null) {
                      continue;
                    }
                    newElement.essentialLearningEval = essenLearn.evaluation;
                    newElement.name = studEval.studentID;
                    if (studEval.student) {
                      newElement.classOrder = studEval.student.classOrder;
                    } else {
                      newElement.classOrder = studEval.studentClassOrder;
                    }
                    newElement.feedbackStud = studEval.feedbackStud;
                    newElement.splitFlag = true;
                    newElement.finalEssentialLearningEvalFlag = true;
                    prepareNewElementToBePushed(newElement);
                    data.push(newElement);
                  }
                }
              }
              // Get evals from questions
              let studEvalWithQuantitativeEvals = false;
              if (
                studEval.essentialLearningQuestionEvaluation != null &&
                studEval.essentialLearningQuestionEvaluation.length > 0
              ) {
                studEval.essentialLearningQuestionEvaluation.forEach((questionEval) => {
                  if (questionEval.evaluation != null) {
                    let newElement = JSON.parse(JSON.stringify(el));
                    newElement.question = questionEval.question;
                    if (
                      el.correctionByEssenLearnWeightcheckbox &&
                      el.correctionByEssenLearnWeightcheckbox[""] &&
                      el.correctionByEssenLearnWeightcheckbox[""] == true &&
                      questionEval.weight
                    ) {
                      newElement.evaluation = questionEval.evaluation + "/" + questionEval.weight;
                    } else {
                      newElement.evaluation = questionEval.evaluation;
                    }
                    if (questionEval.evaluationQualitativeLevel) {
                      /* newElement.evaluation += " (" + questionEval.evaluationQualitativeLevel + ")"; */
                      newElement.evaluation = questionEval.evaluationQualitativeLevel;
                    }
                    if (
                      selectedRowEvalInstrumentQualitativeLevels &&
                      selectedRowEvalInstrumentQualitativeLevels.performanceCritDesc &&
                      questionEval.performanceCrit &&
                      questionEval.evaluationQualitativeLevel
                    ) {
                      selectedRowEvalInstrumentQualitativeLevels.performanceCritDesc.forEach((performanceCritDesc) => {
                        if (
                          performanceCritDesc.performanceCrit == questionEval.performanceCrit &&
                          performanceCritDesc.qualitativeLevel == questionEval.evaluationQualitativeLevel
                        ) {
                          newElement.performanceDesc = performanceCritDesc.performanceDesc;
                        }
                      });
                    }
                    newElement.essentialLearning = questionEval.essentialLearning;
                    newElement.performanceCrit = questionEval.performanceCrit;
                    newElement.name = studEval.studentID;
                    if (studEval.student) {
                      newElement.classOrder = studEval.student.classOrder;
                    } else {
                      newElement.classOrder = studEval.studentClassOrder;
                    }
                    newElement.feedbackStud = studEval.feedbackStud;
                    newElement.questionEvalFlag = true;
                    newElement.splitFlag = true;
                    prepareNewElementToBePushed(newElement);
                    data.push(newElement);
                  }
                });
                // Get essential learning instrument evals
                for (const essenLearn in studEval.essenLearnFinalEvalMap) {
                  if (studEval.essenLearnFinalEvalMap.hasOwnProperty(essenLearn)) {
                    const essenLearnEval = studEval.essenLearnFinalEvalMap[essenLearn];
                    let newElement = JSON.parse(JSON.stringify(el));

                    newElement.essentialLearning = essenLearnEval.essentialLearning;
                    if (essenLearnEval.evaluation == null) {
                      continue;
                    }
                    studEvalWithQuantitativeEvals = true;
                    if (finalEvalFlag) {
                      newElement.essentialLearningEval = Math.round(essenLearnEval.evaluation * 100) / 100;
                    } else {
                      newElement.essentialLearningEval = Math.round(essenLearnEval.evaluation);
                    }
                    newElement.name = studEval.studentID;
                    if (studEval.student) {
                      newElement.classOrder = studEval.student.classOrder;
                    } else {
                      newElement.classOrder = studEval.studentClassOrder;
                    }
                    newElement.feedbackStud = studEval.feedbackStud;
                    newElement.splitFlag = true;
                    newElement.finalEssentialLearningEvalFlag = true;
                    prepareNewElementToBePushed(newElement);
                    data.push(newElement);
                  }
                }
              }
              // Get qualitative evals
              if (studEval.qualitativeEvalMap != null && !studEvalWithQuantitativeEvals) {
                for (const essenLearn in studEval.qualitativeEvalMap) {
                  if (studEval.qualitativeEvalMap.hasOwnProperty(essenLearn)) {
                    let essenLearnEvals = studEval.qualitativeEvalMap[essenLearn];
                    if (
                      essenLearnEvals == null ||
                      (studEval.completeQualitativeEvals != null && studEval.completeQualitativeEvals == false)
                    ) {
                      continue;
                    }
                    if (finalEvalFlag) {
                      essenLearnEvals.map(function (essenLearnEval) {
                        let newElement = JSON.parse(JSON.stringify(el));
                        newElement.essentialLearningEval = essenLearnEval.essentialLearningEval;
                        newElement.essentialLearning = essenLearn;
                        newElement.name = studEval.studentID;
                        if (studEval.student) {
                          newElement.classOrder = studEval.student.classOrder;
                        } else {
                          newElement.classOrder = studEval.studentClassOrder;
                        }
                        newElement.feedbackStud = studEval.feedbackStud;
                        newElement.splitFlag = true;
                        prepareNewElementToBePushed(newElement);
                        data.push(newElement);
                      });
                    } else {
                      let newElement = JSON.parse(JSON.stringify(el));
                      newElement.essentialLearningEval = "";
                      essenLearnEvals.map(function (essenLearnEval) {
                        if (newElement.essentialLearningEval == "") {
                          newElement.essentialLearningEval += essenLearnEval.essentialLearningEval;
                        } else {
                          newElement.essentialLearningEval += ", " + essenLearnEval.essentialLearningEval;
                        }
                      });
                      newElement.essentialLearning = essenLearn;
                      newElement.name = studEval.studentID;
                      if (studEval.student) {
                        newElement.classOrder = studEval.student.classOrder;
                      } else {
                        newElement.classOrder = studEval.studentClassOrder;
                      }
                      newElement.feedbackStud = studEval.feedbackStud;
                      newElement.splitFlag = true;
                      newElement.finalEssentialLearningEvalFlag = true;
                      prepareNewElementToBePushed(newElement);
                      data.push(newElement);
                    }
                  }
                }
              }
            }
          }

          data.splice(0, 1);
        }

        // Parse and clean new element to be pushed
        function prepareNewElementToBePushed(element) {
          if (element.name != null) {
            element.name = scope.parseValue(element.name, "name", "Class_Plan_Students");
          }
          if (element.essentialLearning != null) {
            if (element.module == null) {
              element.essentialLearning = scope.parseValue(
                element.essentialLearning,
                "essentialLearning",
                "Class_Plan_Essential_Learnings"
              );
            } else {
              element.essentialLearning = scope.parseValue(
                element.essentialLearning,
                "essentialLearning",
                "Class_Plan_Module_Essential_Learnings"
              );
            }
          }
          if (element.performanceCrit != null) {
            element.performanceCrit = scope.parseValue(
              element.performanceCrit,
              "performanceCrit",
              "Class_Plan_Performance_Crits"
            );
          }
          if (element.studentEvaluations != null) {
            delete element.studentEvaluations;
          }
          if (element.createdBy != null) {
            delete element.createdBy;
          }
          if (element.modifiedBy != null) {
            delete element.modifiedBy;
          }
        }

        let essenLearnMap = {};

        for (let j = 0; j < data.length; j++) {
          let el = data[j];

          //Parse qualitative eval if necessary
          let parsedQuantitativeEval;
          if (typeof el.essentialLearningEval == "string" && el.evalInstrumentQualitativeLevels) {
            let parsedEvalInstrumentQualitativeLevels = JSON.parse(el.evalInstrumentQualitativeLevels);
            if (
              parsedEvalInstrumentQualitativeLevels.qualitativeLevels &&
              Array.isArray(parsedEvalInstrumentQualitativeLevels.qualitativeLevels) &&
              parsedEvalInstrumentQualitativeLevels.qualitativeLevels.length > 0
            ) {
              let quantitativeEval = classPlanGetQuantitativeEvalsFromQualitative(
                new Array(el.essentialLearningEval),
                parsedEvalInstrumentQualitativeLevels.qualitativeLevels
              );
              if (!isNaN(Number(quantitativeEval))) {
                parsedQuantitativeEval = quantitativeEval;
              } else {
                continue;
              }
            } else {
              continue;
            }
          }

          if (essenLearnMap[el.name] == null) {
            essenLearnMap[el.name] = {};
          }
          if (essenLearnMap[el.name][el.essentialLearning] == null) {
            essenLearnMap[el.name][el.essentialLearning] = {};
          }
          if (essenLearnMap[el.name][el.essentialLearning].essenLearnTotal != null) {
            if (parsedQuantitativeEval != null) {
              essenLearnMap[el.name][el.essentialLearning].essenLearnTotal += parsedQuantitativeEval;
            } else {
              essenLearnMap[el.name][el.essentialLearning].essenLearnTotal += el.essentialLearningEval;
            }
            essenLearnMap[el.name][el.essentialLearning].totalEvals += 1;
          } else {
            if (parsedQuantitativeEval != null) {
              essenLearnMap[el.name][el.essentialLearning].essenLearnTotal = parsedQuantitativeEval;
            } else {
              essenLearnMap[el.name][el.essentialLearning].essenLearnTotal = el.essentialLearningEval;
            }
            essenLearnMap[el.name][el.essentialLearning].totalEvals = 1;
          }
        }

        for (let w = 0; w < data.length; w++) {
          let el = data[w];
          for (const studName in essenLearnMap) {
            if (essenLearnMap.hasOwnProperty(studName)) {
              let essLearn = essenLearnMap[studName];
              if (el.name == studName && essLearn[el.essentialLearning] != null) {
                if (essLearn[el.essentialLearning].essenLearnTotal != null) {
                  if (finalEvalFlag) {
                    el.essenLearnAvg =
                      Math.round(
                        (essLearn[el.essentialLearning].essenLearnTotal / essLearn[el.essentialLearning].totalEvals) *
                          100
                      ) / 100;
                  } else {
                    el.essenLearnAvg = Math.round(
                      essLearn[el.essentialLearning].essenLearnTotal / essLearn[el.essentialLearning].totalEvals
                    );
                    el.essentialLearningEvalLevel = ptConvertEvalToFinalEvalLevel(el, "essentialLearningEval");
                  }
                }
              }
            }
          }
        }
        if (scope.selectedRow.module == null) {
          scope.genericFactory.setRouteName("Class_Plan_School_Records");
        } else {
          scope.genericFactory.setRouteName("Class_Plan_Prof_School_Records");
        }

        data = genericReportExport(data, scope, reportConfig);
        data = parseData(scope, data);

        let splitData = [];

        let finalSplitArray = Object.values(groupBy2(data, "name"));
        for (let i = 0; i < finalSplitArray.length; i++) {
          const el = finalSplitArray[i];
          splitData.push(el);
        }

        resolve(splitData);
        return true;
      });
  });
}

function ptEvalInstrumentByStudent(data, scope, reportConfig) {
  data = genericReportExport(data, scope, reportConfig);

  let splitData = [];

  let finalSplitArray = Object.values(groupBy2(data, "name"));
  for (let i = 0; i < finalSplitArray.length; i++) {
    const el = finalSplitArray[i];
    splitData.push(el);
  }

  return splitData;
}

function ptEvalInstrumentEssenLearning(data, scope, reportConfig) {
  data = genericReportExport(data, scope, reportConfig);

  let splitData = [];

  Object.values(groupBy2(data, "schoolYear")).map((arr) =>
    Object.values(groupBy2(arr, "class")).map((arr) =>
      Object.values(groupBy2(arr, "subject")).map((arr) =>
        Object.values(groupBy2(arr, "period")).map((arr) =>
          Object.values(groupBy2(arr, "evalInstrument")).map(function (arr) {
            let finalSplitArray = Object.values(groupBy2(arr, "evalDate"));
            for (let i = 0; i < finalSplitArray.length; i++) {
              const el = finalSplitArray[i];
              splitData.push(el);
            }
          })
        )
      )
    )
  );

  return splitData;
}

function ptEvalInstrument(data, scope, reportConfig) {
  data = genericReportExport(data, scope, reportConfig);

  let splitData = [];

  Object.values(groupBy2(data, "schoolYear")).map((arr) =>
    Object.values(groupBy2(arr, "class")).map((arr) =>
      Object.values(groupBy2(arr, "subject")).map((arr) =>
        Object.values(groupBy2(arr, "period")).map(function (arr) {
          let finalSplitArray = Object.values(groupBy2(arr, "evalInstrument"));
          for (let i = 0; i < finalSplitArray.length; i++) {
            const el = finalSplitArray[i];
            splitData.push(el);
          }
        })
      )
    )
  );

  //Get eval instrument question structure

  let studEvalsMultipleInstr = [];

  splitData.forEach((evalInstrumentEvals) => {
    let studEvalsMultipleQuest = [];
    let essenLearnClassAvg = {};
    let evalInstrStructure = "";
    let evalInstrDetailsFlag = false;
    let studEvalQuestions = [];
    let studEvalEssenLearns = [];
    evalInstrumentEvals.forEach((evalInstrumentStudEval) => {
      let studEval = evalInstrumentStudEval;

      //Flag to tag unique row by stud
      studEval.groupedQuestionsFlag = true;

      //Insert question structure in studEval obj
      studEval.questionStructure = [];
      studEval.questionStructure.push(JSON.parse(JSON.stringify(studEval)));

      //Insert essen learn avg column
      studEval[studEval.essentialLearning + " (Aval)"] = studEval.essentialLearningEval;
      if (studEval.essentialLearningEvalLevel != null) {
        studEval[studEval.essentialLearning + " (Nível)"] = studEval.essentialLearningEvalLevel;
      }
      if (!evalInstrDetailsFlag) {
        studEvalEssenLearns.push(studEval.essentialLearning + " (Aval)");
        if (studEval.essentialLearningEvalLevel != null) {
          studEvalEssenLearns.push(studEval.essentialLearning + " (Nível)");
        }
      }

      //Insert class essen learn avg
      if (studEval.essentialLearningEval && !isNaN(Number(studEval.essentialLearningEval))) {
        if (essenLearnClassAvg[studEval.essentialLearning] == null) {
          essenLearnClassAvg[studEval.essentialLearning] = {};
          essenLearnClassAvg[studEval.essentialLearning].essentialLearningEval = 0;
          essenLearnClassAvg[studEval.essentialLearning].numEvals = 0;
        }
        if (Number(studEval.essentialLearningEval) > 0) {
          essenLearnClassAvg[studEval.essentialLearning].essentialLearningEval += studEval.essentialLearningEval;
          essenLearnClassAvg[studEval.essentialLearning].numEvals += 1;
        }
      }

      /*  //Check in question column exists
       if (studEval[studEval.question] != null) {
         studEval.question = studEval.question + "_";
       }

       //Insert question column
       studEval[studEval.question] = studEval.questionEval; */
      if (!evalInstrDetailsFlag) {
        studEvalQuestions.push(studEval.question);
      }

      //Flag to stop for cycle when it changes student
      let alreadyGroupedQuestions = false;

      for (let w = 0; w < evalInstrumentEvals.length; ) {
        let evalInstrumentStudEvalAux = evalInstrumentEvals[w];
        if (evalInstrumentStudEvalAux.name != studEval.name && alreadyGroupedQuestions) {
          break;
        }
        if (evalInstrumentStudEvalAux.groupedQuestionsFlag == null && evalInstrumentStudEvalAux.name == studEval.name) {
          alreadyGroupedQuestions = true;

          //Insert question structure in studEval obj
          studEval.questionStructure.push(JSON.parse(JSON.stringify(evalInstrumentStudEvalAux)));
          if (!evalInstrDetailsFlag) {
            studEvalQuestions.push(evalInstrumentStudEvalAux.question);
            /* if (studEvalQuestions.indexOf(evalInstrumentStudEvalAux.question) == -1) {
              studEvalQuestions.push(evalInstrumentStudEvalAux.question);
            } */
          }

          //Insert essen learn avg column
          studEval[evalInstrumentStudEvalAux.essentialLearning + " (Aval)"] =
            evalInstrumentStudEvalAux.essentialLearningEval;
          if (evalInstrumentStudEvalAux.essentialLearningEvalLevel != null) {
            studEval[evalInstrumentStudEvalAux.essentialLearning + " (Nível)"] =
              evalInstrumentStudEvalAux.essentialLearningEvalLevel;
          }
          if (!evalInstrDetailsFlag) {
            if (studEvalEssenLearns.indexOf(evalInstrumentStudEvalAux.essentialLearning + " (Aval)") == -1) {
              studEvalEssenLearns.push(evalInstrumentStudEvalAux.essentialLearning + " (Aval)");
            }
            if (
              evalInstrumentStudEvalAux.essentialLearningEvalLevel != null &&
              studEvalEssenLearns.indexOf(evalInstrumentStudEvalAux.essentialLearning + " (Nível)") == -1
            ) {
              studEvalEssenLearns.push(evalInstrumentStudEvalAux.essentialLearning + " (Nível)");
            }
          }

          //Insert class essen learn avg
          if (
            evalInstrumentStudEvalAux.essentialLearningEval &&
            !isNaN(Number(evalInstrumentStudEvalAux.essentialLearningEval))
          ) {
            if (essenLearnClassAvg[evalInstrumentStudEvalAux.essentialLearning] == null) {
              essenLearnClassAvg[evalInstrumentStudEvalAux.essentialLearning] = {};
              essenLearnClassAvg[evalInstrumentStudEvalAux.essentialLearning].essentialLearningEval = 0;
              essenLearnClassAvg[evalInstrumentStudEvalAux.essentialLearning].numEvals = 0;
            }
            if (Number(evalInstrumentStudEvalAux.essentialLearningEval) > 0) {
              essenLearnClassAvg[evalInstrumentStudEvalAux.essentialLearning].essentialLearningEval +=
                evalInstrumentStudEvalAux.essentialLearningEval;
              essenLearnClassAvg[evalInstrumentStudEvalAux.essentialLearning].numEvals += 1;
            }
          }

          /* //Check in question column exists
          if (studEval[evalInstrumentStudEvalAux.question] != null) {
            evalInstrumentStudEvalAux.question = evalInstrumentStudEvalAux.question + "_";
          }

          //Insert question column
          studEval[evalInstrumentStudEvalAux.question] = evalInstrumentStudEvalAux.questionEval; */

          //Remove row with single question eval
          evalInstrumentEvals.splice(w, 1);
        } else {
          w++;
        }
      }

      let studEvalCopy = JSON.parse(JSON.stringify(studEval));
      studEvalCopy.columns = studEvalCopy.columns.concat(studEvalQuestions);
      //studEvalCopy.rowColumnKeys = studEvalCopy.rowColumnKeys.concat(studEvalQuestions);

      //Sort essen learn avg columns
      studEvalEssenLearns.sort();

      studEvalCopy.columns = studEvalCopy.columns.concat(studEvalEssenLearns);
      //studEvalCopy.rowColumnKeys = studEvalCopy.rowColumnKeys.concat(studEvalEssenLearns);

      studEvalCopy.studEvalEssenLearns = studEvalEssenLearns;
      studEvalCopy.numColumns += studEvalQuestions.length;
      studEvalCopy.numColumns += studEvalEssenLearns.length;

      //Parse eval instrument question structure
      if (!evalInstrDetailsFlag) {
        studEvalCopy.evalInstrStructure = "";
        for (let m = 0; m < studEvalCopy.questionStructure.length; m++) {
          let question = studEvalCopy.questionStructure[m];
          if (studEvalCopy.evalInstrStructure == "") {
            studEvalCopy.evalInstrStructure =
              question.question + " (" + question.essentialLearning + " " + question.weight + "%)";
          } else {
            studEvalCopy.evalInstrStructure +=
              ", " + question.question + " (" + question.essentialLearning + " " + question.weight + "%)";
          }
        }
        evalInstrStructure = studEvalCopy.evalInstrStructure;
      } else {
        studEvalCopy.evalInstrStructure = evalInstrStructure;
      }

      evalInstrDetailsFlag = true;
      studEvalsMultipleQuest.push(studEvalCopy);
    });

    //Calculate class essen learn avg
    let essenLearnClassAvgDetails = "";
    let orderedClassEssenLearns = [];
    if (Object.keys(essenLearnClassAvg).length > 0) {
      orderedClassEssenLearns = Object.keys(essenLearnClassAvg).sort();
    }
    for (let l = 0; l < orderedClassEssenLearns.length; l++) {
      const essenLearn = orderedClassEssenLearns[l];
      const elementDetails = essenLearnClassAvg[essenLearn];
      if (essenLearnClassAvgDetails == "") {
        essenLearnClassAvgDetails =
          essenLearn +
          ": " +
          Math.round((Number(elementDetails.essentialLearningEval) / elementDetails.numEvals) * 100) / 100;
      } else {
        essenLearnClassAvgDetails +=
          ", " +
          essenLearn +
          ": " +
          Math.round((Number(elementDetails.essentialLearningEval) / elementDetails.numEvals) * 100) / 100;
      }
    }
    if (essenLearnClassAvgDetails != "") {
      studEvalsMultipleQuest[0].essenLearnClassAvg = essenLearnClassAvgDetails;
    }
    studEvalsMultipleInstr.push(studEvalsMultipleQuest);
  });

  return studEvalsMultipleInstr;
}

function ptFinalEvalScore(data, scope, reportConfig) {
  data = genericReportExport(data, scope, reportConfig);

  for (let j = 0; j < data.length; ) {
    let dataEl = data[j];
    if (dataEl.module) {
      dataEl.moduleUFCD = dataEl.module;
    }
    if (dataEl.studFinalEval == null) {
      data.splice(j, 1);
    } else {
      j++;
    }
  }

  let splitData = [];

  Object.values(groupBy2(data, "schoolYear")).map((arr) =>
    Object.values(groupBy2(arr, "class")).map((arr) =>
      Object.values(groupBy2(arr, "subject")).map(function (arr) {
        if (scope.module.collection == "Class_Plan_Prof_School_Records") {
          Object.values(groupBy2(arr, "module")).map((arr) =>
            Object.values(groupBy2(arr, "period")).map(function (arr) {
              let finalSplitArray = Object.values(groupBy2(arr, "evalDate"));
              for (let i = 0; i < finalSplitArray.length; i++) {
                const el = finalSplitArray[i];
                splitData.push(el);
              }
            })
          );
        } else {
          Object.values(groupBy2(arr, "period")).map(function (arr) {
            let finalSplitArray = Object.values(groupBy2(arr, "evalDate"));
            for (let i = 0; i < finalSplitArray.length; i++) {
              const el = finalSplitArray[i];
              splitData.push(el);
            }
          });
        }
      })
    )
  );

  return splitData;
}

function ptFinalEvalStud(data, scope, reportConfig) {
  data = genericReportExport(data, scope, reportConfig);

  for (let j = 0; j < data.length; j++) {
    let el = data[j];
    if (el.module) {
      el.moduleUFCD = el.module;
    }
    if (el.studAECritEval == null) {
      el.subject = null;
      if (scope.module.collection == "Class_Plan_Prof_School_Records") {
        el.module = " ";
      }
    }
    if (el.studFinalEval == null) {
      el.evalDate = null;
    }
    if (el.studAEEval != null && el.evalCrit != null) {
      let studAEEvalFinalLevel;
      studAEEvalFinalLevel = ptConvertEvalToFinalEvalLevel(el, "evalCrit");
      if (studAEEvalFinalLevel != null) {
        el.evalCrit = el.evalCrit + " -> " + studAEEvalFinalLevel;
      }
    }
  }

  let splitData = [];

  Object.values(groupBy2(data, "schoolYear")).map((arr) =>
    Object.values(groupBy2(arr, "class")).map((arr) =>
      Object.values(groupBy2(arr, "period")).map(function (arr) {
        let finalSplitArray = Object.values(groupBy2(arr, "name"));
        for (let i = 0; i < finalSplitArray.length; i++) {
          const el = finalSplitArray[i];
          splitData.push(el);
        }
      })
    )
  );

  return splitData;
}

function ptFinalEvalStudSimplified(data, scope, reportConfig) {
  data = genericReportExport(data, scope, reportConfig);

  let lastSubject = "";
  let lastStudentID = "";
  for (let j = 0; j < data.length; ) {
    let el = data[j];

    if (el.studAECritEval != null) {
      data.splice(j, 1);
      continue;
    } else {
      j++;
    }

    if (lastSubject != el.subject || lastStudentID != el.studentID) {
      lastSubject = el.subject;
      lastStudentID = el.studentID;
    } else {
      el.subject = null;
      if (scope.module.collection == "Class_Plan_Prof_School_Records") {
        el.module = " ";
      }
    }

    if (el.essenLearn && el.essenLearn.indexOf("(") != -1) {
      let parts = el.essenLearn.split("(");
      el.essenLearn = parts[0];
    }

    if (el.essenLearn) {
      el.essenLearn = "Domínio: " + el.essenLearn;
    }

    if (el.studFinalEval == null) {
      el.evalDate = null;
      el.period = null;
    } else {
      if (el.feedbackStud) {
        el.essenLearn = "Feedback para o Aluno: " + el.feedbackStud;
      }
    }

    if (el.studAEEval != null && el.evalCrit != null) {
      let studAEEvalFinalLevel;
      studAEEvalFinalLevel = ptConvertEvalToFinalEvalLevel(el, "evalCrit");
      if (studAEEvalFinalLevel != null) {
        el.evalCrit = studAEEvalFinalLevel;
      } else {
        el.evalCrit = el.evalCrit;
      }
    }

    // Insert learning profiles
    if (el.learningProfile) {
      let learningProfileEl = JSON.parse(JSON.stringify(el));
      learningProfileEl.subject = null;
      learningProfileEl.evalCrit = null;
      learningProfileEl.essenLearn = "Descritor de Perfil de Aprendizagem: " + learningProfileEl.learningProfile;
      data.splice(j, 0, learningProfileEl);
      j++;
    }
  }

  let splitData = [];

  Object.values(groupBy2(data, "schoolYear")).map((arr) =>
    Object.values(groupBy2(arr, "class")).map(function (arr) {
      let finalSplitArray = Object.values(groupBy2(arr, "name"));
      for (let i = 0; i < finalSplitArray.length; i++) {
        const el = finalSplitArray[i];
        splitData.push(el);
      }
    })
  );

  return splitData;
}

function ptModuleEvalClass(data, scope, reportConfig) {
  data = genericReportExport(data, scope, reportConfig);
  let newData = [];

  let lastName = "";
  for (let j = 0; j < data.length; j++) {
    let el = data[j];
    if (el.module) {
      el.moduleUFCD = el.module;
    }
    if (!el.studAECritEval) {
      if (el.name != lastName) {
        lastName = el.name;
      } else {
        el.name = null;
        el.classOrder = null;
      }
      newData.push(el);
    }
  }

  let splitData = [];

  Object.values(groupBy2(newData, "schoolYear")).map((arr) =>
    Object.values(groupBy2(arr, "class")).map((arr) =>
      Object.values(groupBy2(arr, "period")).map((arr) =>
        Object.values(groupBy2(arr, "subject")).map(function (arr) {
          let finalSplitArray = Object.values(groupBy2(arr, "module"));
          for (let i = 0; i < finalSplitArray.length; i++) {
            const el = finalSplitArray[i];
            splitData.push(el);
          }
        })
      )
    )
  );

  return splitData;
}

function ptModuleExpertiseFieldEval(data, scope, reportConfig) {
  data = genericReportExport(data, scope, reportConfig);

  let lastName = "";
  for (let j = 0; j < data.length; j++) {
    let el = data[j];
    if (el.name != lastName) {
      lastName = el.name;
    } else {
      el.name = null;
      el.classOrder = null;
    }
  }

  let splitData = [];

  Object.values(groupBy2(data, "schoolYear")).map((arr) =>
    Object.values(groupBy2(arr, "class")).map((arr) =>
      Object.values(groupBy2(arr, "period")).map((arr) =>
        Object.values(groupBy2(arr, "subject")).map(function (arr) {
          let finalSplitArray = Object.values(groupBy2(arr, "module"));
          for (let i = 0; i < finalSplitArray.length; i++) {
            const el = finalSplitArray[i];
            splitData.push(el);
          }
        })
      )
    )
  );

  return splitData;
}

function ptSubjectExpertiseFieldEval(data, scope, reportConfig) {
  data = genericReportExport(data, scope, reportConfig);

  let lastNameEvalDate = "";
  for (let j = 0; j < data.length; j++) {
    let el = data[j];
    if (el.name + el.evalDate != lastNameEvalDate) {
      lastNameEvalDate = el.name + el.evalDate;
    } else {
      el.name = null;
      el.classOrder = null;
    }
  }

  let splitData = [];

  Object.values(groupBy2(data, "schoolYear")).map((arr) =>
    Object.values(groupBy2(arr, "class")).map((arr) =>
      Object.values(groupBy2(arr, "period")).map((arr) =>
        Object.values(groupBy2(arr, "subject")).map(function (arr) {
          let finalSplitArray = Object.values(groupBy2(arr, "evalDate"));
          for (let i = 0; i < finalSplitArray.length; i++) {
            const el = finalSplitArray[i];
            splitData.push(el);
          }
        })
      )
    )
  );

  return splitData;
}

function ptFinalEvalScoreByClass(data, scope, reportConfig) {
  data = genericReportExport(data, scope, reportConfig);

  for (let j = 0; j < data.length; ) {
    let dataEl = data[j];
    if (dataEl.studFinalEval == null) {
      data.splice(j, 1);
    } else {
      j++;
    }
  }

  let splitData = [];

  Object.values(groupBy2(data, "schoolYear")).map((arr) =>
    Object.values(groupBy2(arr, "class")).map(function (arr) {
      if (scope.module.collection == "Class_Plan_Prof_School_Records") {
        Object.values(groupBy2(arr, "period")).map(function (arr) {
          let finalSplitArray = Object.values(groupBy2(arr, "evalDate"));
          for (let i = 0; i < finalSplitArray.length; i++) {
            const el = finalSplitArray[i];
            splitData.push(el);
          }
        });
      } else {
        Object.values(groupBy2(arr, "period")).map(function (arr) {
          let finalSplitArray = Object.values(groupBy2(arr, "evalDate"));
          for (let i = 0; i < finalSplitArray.length; i++) {
            const el = finalSplitArray[i];
            splitData.push(el);
          }
        });
      }
    })
  );

  let targetEvalField = "subject";
  let targetAggField = "Class_Plan_Subjects";
  if (scope.module.collection == "Class_Plan_Prof_School_Records") {
    targetEvalField = "module";
    targetAggField = "Class_Plan_Prof_Modules";
  }

  /* for (let i = 0; i < splitData.length; i++) {
    let splitDataArr = splitData[i];
    for (let m = 0; m < splitDataArr.length; m++) {
      let splitDataArrStudEl = splitDataArr[m];
      if (splitDataArrStudEl.processedFlag == true) {
        continue;
      }
      splitDataArrStudEl[splitDataArrStudEl[targetEvalField]] = splitDataArrStudEl.finalEvalLevel;
      for (let k = 0; k < splitDataArr.length; k++) {
        let splitDataArrEl = splitDataArr[k];
        if (k == m) {
          continue;
        }
        if (splitDataArrStudEl.processedFlag == true) {
          continue;
        }
        if (splitDataArrStudEl.studentID == splitDataArrEl.studentID) {
          splitDataArrStudEl[splitDataArrEl[targetEvalField]] = splitDataArrEl.finalEvalLevel;
          splitDataArrEl.processedFlag = true;
        }
      }
    }
    for (let w = 0; w < splitDataArr.length; ) {
      let dataEl = splitDataArr[w];
      if (dataEl.processedFlag == true) {
        splitDataArr.splice(w, 1);
      } else {
        w++;
      }
    }
  } */

  let finalSplitData = [];

  splitData.forEach((subjectModuleEvals) => {
    let allEvaluatedSubjectModules = [];

    // Set up array with one object per student with all subject evals
    for (let m = 0; m < subjectModuleEvals.length; m++) {
      let subjectModuleEvalsStudEl = subjectModuleEvals[m];
      if (subjectModuleEvalsStudEl.processedFlag == true) {
        continue;
      }
      if (allEvaluatedSubjectModules.indexOf(subjectModuleEvalsStudEl[targetEvalField]) == -1) {
        allEvaluatedSubjectModules.push(subjectModuleEvalsStudEl[targetEvalField]);
      }
      subjectModuleEvalsStudEl[subjectModuleEvalsStudEl[targetEvalField]] = subjectModuleEvalsStudEl.finalEvalLevel;
      for (let k = 0; k < subjectModuleEvals.length; k++) {
        let subjectModuleEvalsEl = subjectModuleEvals[k];
        if (k == m) {
          continue;
        }
        if (subjectModuleEvalsStudEl.processedFlag == true) {
          continue;
        }
        if (subjectModuleEvalsStudEl.studentID == subjectModuleEvalsEl.studentID) {
          subjectModuleEvalsStudEl[subjectModuleEvalsEl[targetEvalField]] = subjectModuleEvalsEl.finalEvalLevel;
          if (allEvaluatedSubjectModules.indexOf(subjectModuleEvalsEl[targetEvalField]) == -1) {
            allEvaluatedSubjectModules.push(subjectModuleEvalsEl[targetEvalField]);
          }
          subjectModuleEvalsEl.processedFlag = true;
        }
      }
    }

    // Remove objects that were processed previously
    for (let w = 0; w < subjectModuleEvals.length; ) {
      let dataEl = subjectModuleEvals[w];
      if (dataEl.processedFlag == true) {
        subjectModuleEvals.splice(w, 1);
      } else {
        w++;
      }
    }

    let studentsWithClassOrder = [];
    let studentWithoutClassOrder = [];

    studentsWithClassOrder = subjectModuleEvals.filter((stud) => stud.classOrder != null);
    studentWithoutClassOrder = subjectModuleEvals.filter((stud) => stud.classOrder == null);

    studentsWithClassOrder = studentsWithClassOrder.sort(function compareModules(a, b) {
      if (a.classOrder < b.classOrder) {
        return -1;
      }
      if (a.classOrder > b.classOrder) {
        return 1;
      }

      return 0;
    });

    studentWithoutClassOrder = studentWithoutClassOrder.sort(function compareModules(a, b) {
      if (
        a.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "") <
        b.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
      ) {
        return -1;
      }

      if (
        a.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "") >
        b.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
      ) {
        return 1;
      }

      return 0;
    });

    subjectModuleEvals = studentsWithClassOrder.concat(studentWithoutClassOrder);
    /*  subjectModuleEvals.forEach((subjectModuleStudEvals) => {
      let studEval = subjectModuleStudEvals;
      if (allEvaluatedSubjectModules.indexOf(studEval[targetEvalField]) == -1) {
        allEvaluatedSubjectModules.push(studEval[targetEvalField]);
      }
    });
    let parsedAllEvaluatedSubjectModules = [];
    allEvaluatedSubjectModules.forEach((subjectModule) => {
      let parsedSubjectModule = scope.getFromTableData(targetAggField).filter((cl) => cl.id == subjectModule);
      if (parsedSubjectModule != null && Array.isArray(parsedSubjectModule) && parsedSubjectModule.length > 0) {
        parsedAllEvaluatedSubjectModules.push(parsedSubjectModule[0][targetEvalField]);
      }
    }); */
    subjectModuleEvals.forEach((subjectModuleStudEvals) => {
      let studEval = subjectModuleStudEvals;
      studEval.columns = ["Nº", "Aluno"];
      studEval.numColumns = 2;
      studEval.columns = studEval.columns.concat(allEvaluatedSubjectModules.sort());
      studEval.numColumns += allEvaluatedSubjectModules.length;
      studEval.allEvaluatedSubjectModules = allEvaluatedSubjectModules;
    });
    finalSplitData.push(subjectModuleEvals);
  });

  return finalSplitData;
}

function ptFinalEvalScoreByClassEvalDesc(data, scope, reportConfig) {
  data = genericReportExport(data, scope, reportConfig);

  for (let j = 0; j < data.length; ) {
    let dataEl = data[j];
    if (dataEl.studFinalEval == null) {
      data.splice(j, 1);
    } else {
      j++;
    }
  }

  data.sort(function (a, b) {
    if (a["schoolYear"] < b["schoolYear"]) {
      return -1;
    }
    if (a["schoolYear"] > b["schoolYear"]) {
      return 1;
    }

    if (a["class"] < b["class"]) {
      return -1;
    }
    if (a["class"] > b["class"]) {
      return 1;
    }
  });

  let splitData = [];

  Object.values(groupBy2(data, "schoolYear")).map(function (arr) {
    let finalSplitArray = Object.values(groupBy2(arr, "class"));
    for (let j = 0; j < finalSplitArray.length; j++) {
      const el = finalSplitArray[j];
      splitData.push(el);
    }
  });

  let targetEvalField = "subject";
  if (scope.module.collection == "Class_Plan_Prof_School_Records") {
    targetEvalField = "module";
  }

  function parseEvalDesc(evalDesc, period) {
    if (evalDesc.indexOf("/") != -1) {
      if (period.indexOf("1") != -1) {
        return "AF1S";
      } else if (period.indexOf("2") != -1) {
        return "AF2S";
      }
    }
    if (evalDesc == "Intercalar") {
      if (period.indexOf("1") != -1) {
        return "AI1S";
      } else if (period.indexOf("2") != -1) {
        return "AI2S";
      }
    }
    if (evalDesc == "Final") {
      return "Final";
    }
  }

  let finalSplitData = [];

  splitData.forEach((subjectModuleEvals) => {
    let allEvaluatedSubjectModules = [];

    // Set up array with one object per student with all subject evals
    for (let m = 0; m < subjectModuleEvals.length; m++) {
      let subjectModuleEvalsStudEl = subjectModuleEvals[m];
      let key =
        subjectModuleEvalsStudEl[targetEvalField]
          .split(" ")
          .map((el, index) => {
            return index == 0 ? el.slice(0, 3) : el;
          })
          .map((el, index) => {
            return index == 0 ? el : el[0] == el[0].toLowerCase() ? "" : el[0];
          })
          .join("") +
        " " +
        parseEvalDesc(subjectModuleEvalsStudEl.evalDesc, subjectModuleEvalsStudEl.period);

      if (subjectModuleEvalsStudEl.processedFlag == true) {
        continue;
      }
      if (allEvaluatedSubjectModules.indexOf(key) == -1) {
        allEvaluatedSubjectModules.push(key);
      }
      subjectModuleEvalsStudEl[subjectModuleEvalsStudEl[targetEvalField]] = subjectModuleEvalsStudEl.finalEvalLevel;
      for (let k = 0; k < subjectModuleEvals.length; k++) {
        let subjectModuleEvalsEl = subjectModuleEvals[k];
        let subKey =
          subjectModuleEvalsEl[targetEvalField]
            .split(" ")
            .map((el, index) => {
              return index == 0 ? el.slice(0, 3) : el;
            })
            .map((el, index) => {
              return index == 0 ? el : el[0] == el[0].toLowerCase() ? "" : el[0];
            })
            .join("") +
          " " +
          parseEvalDesc(subjectModuleEvalsEl.evalDesc, subjectModuleEvalsEl.period);
        /* if (k == m) {
          continue;
        } */
        if (subjectModuleEvalsStudEl.processedFlag == true) {
          continue;
        }
        if (subjectModuleEvalsStudEl.studentID == subjectModuleEvalsEl.studentID) {
          subjectModuleEvalsStudEl[subKey] = subjectModuleEvalsEl.finalEvalLevel;
          if (allEvaluatedSubjectModules.indexOf(subKey) == -1) {
            allEvaluatedSubjectModules.push(subKey);
          }
          if (k != m) {
            subjectModuleEvalsEl.processedFlag = true;
          }
        }
      }
    }

    // Remove objects that were processed previously
    for (let w = 0; w < subjectModuleEvals.length; ) {
      let dataEl = subjectModuleEvals[w];
      if (dataEl.processedFlag == true) {
        subjectModuleEvals.splice(w, 1);
      } else {
        w++;
      }
    }

    // Order evals correctly
    allEvaluatedSubjectModules = allEvaluatedSubjectModules.sort();
    let orderedAllEvaluatedSubjectModules = [];
    let auxArr = [];
    let lastSubMod = "";
    let sortingString = "AI1SAF1SAI2SAF2SFinal";
    for (let n = 0; n < allEvaluatedSubjectModules.length; n++) {
      let subMod = allEvaluatedSubjectModules[n];
      if (subMod.split(" ")[0] != lastSubMod && lastSubMod != "") {
        auxArr.sort(function (a, b) {
          return sortingString.indexOf(a.split(" ")[1]) - sortingString.indexOf(b.split(" ")[1]);
        });
        orderedAllEvaluatedSubjectModules = orderedAllEvaluatedSubjectModules.concat(
          JSON.parse(JSON.stringify(auxArr))
        );
        auxArr = [];
      }
      auxArr.push(subMod);
      lastSubMod = subMod.split(" ")[0];
      if (n == allEvaluatedSubjectModules.length - 1) {
        auxArr.sort(function (a, b) {
          return sortingString.indexOf(a.split(" ")[1]) - sortingString.indexOf(b.split(" ")[1]);
        });
        orderedAllEvaluatedSubjectModules = orderedAllEvaluatedSubjectModules.concat(
          JSON.parse(JSON.stringify(auxArr))
        );
      }
    }

    let studentsWithClassOrder = [];
    let studentWithoutClassOrder = [];

    studentsWithClassOrder = subjectModuleEvals.filter((stud) => stud.classOrder != null);
    studentWithoutClassOrder = subjectModuleEvals.filter((stud) => stud.classOrder == null);

    studentsWithClassOrder = studentsWithClassOrder.sort(function compareModules(a, b) {
      if (a.classOrder < b.classOrder) {
        return -1;
      }
      if (a.classOrder > b.classOrder) {
        return 1;
      }

      return 0;
    });

    studentWithoutClassOrder = studentWithoutClassOrder.sort(function compareModules(a, b) {
      if (
        a.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "") <
        b.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
      ) {
        return -1;
      }

      if (
        a.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "") >
        b.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
      ) {
        return 1;
      }

      return 0;
    });

    subjectModuleEvals = studentsWithClassOrder.concat(studentWithoutClassOrder);

    let splitSubjectModuleStudEvals = [];
    let lastSubject = "";
    let auxCounter = 0;
    let subModArr = [];
    for (let k = 0; k < orderedAllEvaluatedSubjectModules.length; k++) {
      let subEl = orderedAllEvaluatedSubjectModules[k];

      if (auxCounter < 4) {
        if (lastSubject != subEl.split(" ")[0]) {
          auxCounter += 1;
        }
        subModArr.push(subEl);
        lastSubject = subEl.split(" ")[0];
        if (k == orderedAllEvaluatedSubjectModules.length - 1) {
          splitSubjectModuleStudEvals.push(JSON.parse(JSON.stringify(subModArr)));
        }
      } else {
        if (lastSubject == subEl.split(" ")[0]) {
          subModArr.push(subEl);
          lastSubject = subEl.split(" ")[0];
          if (k == orderedAllEvaluatedSubjectModules.length - 1) {
            splitSubjectModuleStudEvals.push(JSON.parse(JSON.stringify(subModArr)));
          }
        } else {
          auxCounter = 0;
          if (k == orderedAllEvaluatedSubjectModules.length - 1) {
            subModArr.push(subEl);
            splitSubjectModuleStudEvals.push(JSON.parse(JSON.stringify(subModArr)));
          } else {
            splitSubjectModuleStudEvals.push(JSON.parse(JSON.stringify(subModArr)));
            subModArr = [];
            subModArr.push(subEl);
          }
        }
      }
    }
    if (splitSubjectModuleStudEvals.length == 0 && subModArr.length > 0) {
      splitSubjectModuleStudEvals.push(JSON.parse(JSON.stringify(subModArr)));
    }

    for (let w = 0; w < splitSubjectModuleStudEvals.length; w++) {
      let splitSubEvaluatedSubMods = splitSubjectModuleStudEvals[w];
      let newArr = [];
      for (let m = 0; m < subjectModuleEvals.length; m++) {
        let studEval = JSON.parse(JSON.stringify(subjectModuleEvals[m]));
        studEval.columns = ["Nº", "Aluno"];
        studEval.numColumns = 2;
        studEval.columns = studEval.columns.concat(JSON.parse(JSON.stringify(splitSubEvaluatedSubMods)));
        studEval.numColumns += JSON.parse(JSON.stringify(splitSubEvaluatedSubMods)).length;
        studEval.allEvaluatedSubjectModules = JSON.parse(JSON.stringify(splitSubEvaluatedSubMods));
        newArr.push(studEval);
      }
      finalSplitData.push(newArr);
    }
  });

  return finalSplitData;
}

function ptFinalEvalScoreByClassAvgEvalDesc(data, scope, reportConfig) {
  data = genericReportExport(data, scope, reportConfig);

  for (let j = 0; j < data.length; ) {
    let dataEl = data[j];
    if (dataEl.studFinalEval == null) {
      data.splice(j, 1);
    } else {
      j++;
    }
  }

  data.sort(function (a, b) {
    if (a["schoolYear"] < b["schoolYear"]) {
      return -1;
    }
    if (a["schoolYear"] > b["schoolYear"]) {
      return 1;
    }

    if (a["class"] < b["class"]) {
      return -1;
    }
    if (a["class"] > b["class"]) {
      return 1;
    }
  });

  let splitData = [];

  Object.values(groupBy2(data, "schoolYear")).map(function (arr) {
    let finalSplitArray = Object.values(groupBy2(arr, "class"));
    for (let j = 0; j < finalSplitArray.length; j++) {
      const el = finalSplitArray[j];
      splitData.push(el);
    }
  });

  let targetEvalField = "subject";
  if (scope.module.collection == "Class_Plan_Prof_School_Records") {
    targetEvalField = "module";
  }

  function parseEvalDesc(evalDesc, period) {
    if (evalDesc.indexOf("/") != -1) {
      if (period.indexOf("1") != -1) {
        return "AF1S";
      } else if (period.indexOf("2") != -1) {
        return "AF2S";
      }
    }
    if (evalDesc == "Intercalar") {
      if (period.indexOf("1") != -1) {
        return "AI1S";
      } else if (period.indexOf("2") != -1) {
        return "AI2S";
      }
    }
    if (evalDesc == "Final") {
      return "Final";
    }
  }

  let finalSplitData = [];

  splitData.forEach((subjectModuleEvals) => {
    let allEvaluatedSubjectModules = [];

    // Set up all evaluations with the corresponding eval desc
    for (let m = 0; m < subjectModuleEvals.length; m++) {
      let subjectModuleEvalsStudEl = subjectModuleEvals[m];
      let key = parseEvalDesc(subjectModuleEvalsStudEl.evalDesc, subjectModuleEvalsStudEl.period);
      subjectModuleEvalsStudEl[key] = subjectModuleEvalsStudEl.finalEvalLevel;
      subjectModuleEvalsStudEl[key + "%"] = subjectModuleEvalsStudEl.finalEval;
      if (allEvaluatedSubjectModules.indexOf(key) == -1) {
        allEvaluatedSubjectModules.push(key);
        allEvaluatedSubjectModules.push(key + "%");
      }
    }

    // Order evals correctly
    let sortingString = "AI1SAI1S%AF1SAF1S%AI2SAI2S%AF2SAF2S%FinalFinal%";
    allEvaluatedSubjectModules.sort(function (a, b) {
      return sortingString.indexOf(a) - sortingString.indexOf(b);
    });
    let orderedAllEvaluatedSubjectModules = allEvaluatedSubjectModules;
    /* let auxArr = [];
    let lastSubMod = "";
    let sortingString = "AI1SAF1SAI2SAF2SFinal";
    for (let n = 0; n < allEvaluatedSubjectModules.length; n++) {
      let subMod = allEvaluatedSubjectModules[n];
      if (subMod.split(" ")[0] != lastSubMod && lastSubMod != "") {
        auxArr.sort(function (a, b) {
          return sortingString.indexOf(a.split(" ")[1]) - sortingString.indexOf(b.split(" ")[1]);
        });
        orderedAllEvaluatedSubjectModules = orderedAllEvaluatedSubjectModules.concat(
          JSON.parse(JSON.stringify(auxArr))
        );
        auxArr = [];
      }
      auxArr.push(subMod);
      lastSubMod = subMod.split(" ")[0];
      if (n == allEvaluatedSubjectModules.length - 1) {
        auxArr.sort(function (a, b) {
          return sortingString.indexOf(a.split(" ")[1]) - sortingString.indexOf(b.split(" ")[1]);
        });
        orderedAllEvaluatedSubjectModules = orderedAllEvaluatedSubjectModules.concat(
          JSON.parse(JSON.stringify(auxArr))
        );
      }
    } */

    let studentsWithClassOrder = [];
    let studentWithoutClassOrder = [];

    studentsWithClassOrder = subjectModuleEvals.filter((stud) => stud.classOrder != null);
    studentWithoutClassOrder = subjectModuleEvals.filter((stud) => stud.classOrder == null);

    studentsWithClassOrder = studentsWithClassOrder.sort(function compareModules(a, b) {
      if (a.classOrder < b.classOrder) {
        return -1;
      }
      if (a.classOrder > b.classOrder) {
        return 1;
      }

      return 0;
    });

    studentWithoutClassOrder = studentWithoutClassOrder.sort(function compareModules(a, b) {
      if (
        a.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "") <
        b.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
      ) {
        return -1;
      }

      if (
        a.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "") >
        b.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
      ) {
        return 1;
      }

      return 0;
    });

    subjectModuleEvals = studentsWithClassOrder.concat(studentWithoutClassOrder);

    /* let splitSubjectModuleStudEvals = [];
    let lastSubject = "";
    let auxCounter = 0;
    let subModArr = [];
    for (let k = 0; k < orderedAllEvaluatedSubjectModules.length; k++) {
      let subEl = orderedAllEvaluatedSubjectModules[k];

      if (auxCounter < 4) {
        if (lastSubject != subEl.split(" ")[0]) {
          auxCounter += 1;
        }
        subModArr.push(subEl);
        lastSubject = subEl.split(" ")[0];
        if (k == orderedAllEvaluatedSubjectModules.length - 1) {
          splitSubjectModuleStudEvals.push(JSON.parse(JSON.stringify(subModArr)));
        }
      } else {
        if (lastSubject == subEl.split(" ")[0]) {
          subModArr.push(subEl);
          lastSubject = subEl.split(" ")[0];
          if (k == orderedAllEvaluatedSubjectModules.length - 1) {
            splitSubjectModuleStudEvals.push(JSON.parse(JSON.stringify(subModArr)));
          }
        } else {
          auxCounter = 0;
          if (k == orderedAllEvaluatedSubjectModules.length - 1) {
            subModArr.push(subEl);
            splitSubjectModuleStudEvals.push(JSON.parse(JSON.stringify(subModArr)));
          } else {
            splitSubjectModuleStudEvals.push(JSON.parse(JSON.stringify(subModArr)));
            subModArr = [];
            subModArr.push(subEl);
          }
        }
      }
    }
    if (splitSubjectModuleStudEvals.length == 0 && subModArr.length > 0) {
      splitSubjectModuleStudEvals.push(JSON.parse(JSON.stringify(subModArr)));
    } */

    let lastStudentID;
    let auxArr = [];
    let avgAuxArr = [];
    for (let m = 0; m < subjectModuleEvals.length; m++) {
      let studEval = JSON.parse(JSON.stringify(subjectModuleEvals[m]));
      if (lastStudentID == null) {
        lastStudentID = studEval.studentID;
      }
      if (lastStudentID != null && lastStudentID != studEval.studentID) {
        lastStudentID = studEval.studentID;
        let avgRow = getAvgRow(auxArr);
        auxArr = auxArr.concat(avgRow);
        avgAuxArr = avgAuxArr.concat(avgRow);
        auxArr = [];
      }
      studEval.columns = ["Nº", "Aluno"];
      studEval.numColumns = 2;
      studEval.columns = studEval.columns.concat(JSON.parse(JSON.stringify(orderedAllEvaluatedSubjectModules)));
      studEval.numColumns += JSON.parse(JSON.stringify(orderedAllEvaluatedSubjectModules)).length;
      studEval.allEvaluatedSubjectModules = JSON.parse(JSON.stringify(orderedAllEvaluatedSubjectModules));
      auxArr.push(studEval);
      if (auxArr.length > 0 && m == subjectModuleEvals.length - 1) {
        lastStudentID = studEval.studentID;
        let avgRow = getAvgRow(auxArr);
        auxArr = auxArr.concat(avgRow);
        avgAuxArr = avgAuxArr.concat(avgRow);
        auxArr = [];
      }
    }
    let finalSplitDataAvgRow = getAvgRow(avgAuxArr);
    finalSplitDataAvgRow.classOrder = null;
    finalSplitDataAvgRow.name = "Média";
    avgAuxArr = avgAuxArr.concat(finalSplitDataAvgRow);
    finalSplitData.push(avgAuxArr);

    function getAvgRow(rows) {
      let evalsDescEvals = {};
      for (let w = 0; w < rows.length; w++) {
        let auxArrEl = rows[w];
        orderedAllEvaluatedSubjectModules.forEach((evalDesc) => {
          if (evalsDescEvals[evalDesc] == null) {
            evalsDescEvals[evalDesc] = {};
            evalsDescEvals[evalDesc].numberEvals = 0;
            evalsDescEvals[evalDesc].evalsTotals = 0;
            evalsDescEvals[evalDesc].evalsPercentageTotals = 0;
          }
          if (auxArrEl[evalDesc] != null && !isNaN(Number(auxArrEl[evalDesc]))) {
            evalsDescEvals[evalDesc].numberEvals += 1;
            evalsDescEvals[evalDesc].evalsTotals += auxArrEl[evalDesc];
          }
          if (auxArrEl[evalDesc + "%"] != null && !isNaN(Number(auxArrEl[evalDesc + "%"]))) {
            evalsDescEvals[evalDesc].evalsPercentageTotals += auxArrEl[evalDesc + "%"];
          }
        });
      }
      let avgRow = JSON.parse(JSON.stringify(rows[0]));
      /* avgRow.targetEvalField = "Média"; */
      orderedAllEvaluatedSubjectModules.forEach((evalDesc) => {
        if (evalsDescEvals[evalDesc].numberEvals > 0) {
          avgRow[evalDesc] =
            Math.round((evalsDescEvals[evalDesc].evalsTotals / evalsDescEvals[evalDesc].numberEvals) * 10) / 10;
          avgRow[evalDesc + "%"] =
            Math.round((evalsDescEvals[evalDesc].evalsPercentageTotals / evalsDescEvals[evalDesc].numberEvals) * 10) /
            10;
        }
      });
      return avgRow;
    }
  });
  return finalSplitData;
}

function ptFinalEvalScoreByStudentEvalDesc(data, scope, reportConfig) {
  data = genericReportExport(data, scope, reportConfig);

  for (let j = 0; j < data.length; ) {
    let dataEl = data[j];
    if (dataEl.studFinalEval == null) {
      data.splice(j, 1);
    } else {
      j++;
    }
  }

  data.sort(function (a, b) {
    if (a["name"] < b["name"]) {
      return -1;
    }
    if (a["name"] > b["name"]) {
      return 1;
    }

    if (a["subject"] < b["subject"]) {
      return -1;
    }
    if (a["subject"] > b["subject"]) {
      return 1;
    }
  });

  let splitData = [];

  Object.values(groupBy2(data, "name")).map(function (arr) {
    splitData.push(arr);
  });

  let targetEvalField = "subject";
  if (scope.module.collection == "Class_Plan_Prof_School_Records") {
    targetEvalField = "module";
  }

  function parseEvalDesc(evalDesc, period) {
    if (evalDesc.indexOf("/") != -1) {
      if (period.indexOf("1") != -1) {
        return "AF1S";
      } else if (period.indexOf("2") != -1) {
        return "AF2S";
      }
    }
    if (evalDesc == "Intercalar") {
      if (period.indexOf("1") != -1) {
        return "AI1S";
      } else if (period.indexOf("2") != -1) {
        return "AI2S";
      }
    }
    if (evalDesc == "Final") {
      return "Final";
    }
  }

  let finalSplitData = [];

  splitData.forEach((subjectModuleEvals) => {
    let allEvaluatedSubjectModules = [];

    // Set up array with one object per student with all subject evals
    for (let m = 0; m < subjectModuleEvals.length; m++) {
      let subjectModuleEvalsStudEl = subjectModuleEvals[m];
      let key = parseEvalDesc(subjectModuleEvalsStudEl.evalDesc, subjectModuleEvalsStudEl.period);

      if (subjectModuleEvalsStudEl.processedFlag == true) {
        continue;
      }
      if (allEvaluatedSubjectModules.indexOf(key) == -1) {
        allEvaluatedSubjectModules.push(key);
      }
      subjectModuleEvalsStudEl.targetEvalField = subjectModuleEvalsStudEl[targetEvalField];
      subjectModuleEvalsStudEl[subjectModuleEvalsStudEl[targetEvalField]] = subjectModuleEvalsStudEl.finalEvalLevel;
      for (let k = 0; k < subjectModuleEvals.length; k++) {
        let subjectModuleEvalsEl = subjectModuleEvals[k];
        let subKey = parseEvalDesc(subjectModuleEvalsEl.evalDesc, subjectModuleEvalsEl.period);
        /* if (k == m) {
          continue;
        } */
        if (subjectModuleEvalsStudEl.processedFlag == true) {
          continue;
        }
        if (
          subjectModuleEvalsStudEl.studentID == subjectModuleEvalsEl.studentID &&
          subjectModuleEvalsStudEl[targetEvalField] == subjectModuleEvalsEl[targetEvalField]
        ) {
          subjectModuleEvalsStudEl[subKey] = subjectModuleEvalsEl.finalEvalLevel;
          if (allEvaluatedSubjectModules.indexOf(subKey) == -1) {
            allEvaluatedSubjectModules.push(subKey);
          }
          if (k != m) {
            subjectModuleEvalsEl.processedFlag = true;
          }
        }
      }
    }

    // Remove objects that were processed previously
    for (let w = 0; w < subjectModuleEvals.length; ) {
      let dataEl = subjectModuleEvals[w];
      if (dataEl.processedFlag == true) {
        subjectModuleEvals.splice(w, 1);
      } else {
        w++;
      }
    }

    // Order evals correctly
    let sortingString = "AI1SAF1SAI2SAF2SFinal";
    allEvaluatedSubjectModules.sort(function (a, b) {
      return sortingString.indexOf(a) - sortingString.indexOf(b);
    });
    let orderedAllEvaluatedSubjectModules = allEvaluatedSubjectModules;
    /* let auxArr = [];
    let lastSubMod = "";
    let sortingString = "AI1SAF1SAI2SAF2SFinal";
    for (let n = 0; n < allEvaluatedSubjectModules.length; n++) {
      let subMod = allEvaluatedSubjectModules[n];
      if (subMod.split(" ")[0] != lastSubMod && lastSubMod != "") {
        auxArr.sort(function (a, b) {
          return sortingString.indexOf(a.split(" ")[1]) - sortingString.indexOf(b.split(" ")[1]);
        });
        orderedAllEvaluatedSubjectModules = orderedAllEvaluatedSubjectModules.concat(
          JSON.parse(JSON.stringify(auxArr))
        );
        auxArr = [];
      }
      auxArr.push(subMod);
      lastSubMod = subMod.split(" ")[0];
      if (n == allEvaluatedSubjectModules.length - 1) {
        auxArr.sort(function (a, b) {
          return sortingString.indexOf(a.split(" ")[1]) - sortingString.indexOf(b.split(" ")[1]);
        });
        orderedAllEvaluatedSubjectModules = orderedAllEvaluatedSubjectModules.concat(
          JSON.parse(JSON.stringify(auxArr))
        );
      }
    } */

    let studentsWithClassOrder = [];
    let studentWithoutClassOrder = [];

    studentsWithClassOrder = subjectModuleEvals.filter((stud) => stud.classOrder != null);
    studentWithoutClassOrder = subjectModuleEvals.filter((stud) => stud.classOrder == null);

    studentsWithClassOrder = studentsWithClassOrder.sort(function compareModules(a, b) {
      if (a.classOrder < b.classOrder) {
        return -1;
      }
      if (a.classOrder > b.classOrder) {
        return 1;
      }

      return 0;
    });

    studentWithoutClassOrder = studentWithoutClassOrder.sort(function compareModules(a, b) {
      if (
        a.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "") <
        b.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
      ) {
        return -1;
      }

      if (
        a.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "") >
        b.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
      ) {
        return 1;
      }

      return 0;
    });

    subjectModuleEvals = studentsWithClassOrder.concat(studentWithoutClassOrder);

    /* let splitSubjectModuleStudEvals = [];
    let lastSubject = "";
    let auxCounter = 0;
    let subModArr = [];
    for (let k = 0; k < orderedAllEvaluatedSubjectModules.length; k++) {
      let subEl = orderedAllEvaluatedSubjectModules[k];

      if (auxCounter < 4) {
        if (lastSubject != subEl.split(" ")[0]) {
          auxCounter += 1;
        }
        subModArr.push(subEl);
        lastSubject = subEl.split(" ")[0];
        if (k == orderedAllEvaluatedSubjectModules.length - 1) {
          splitSubjectModuleStudEvals.push(JSON.parse(JSON.stringify(subModArr)));
        }
      } else {
        if (lastSubject == subEl.split(" ")[0]) {
          subModArr.push(subEl);
          lastSubject = subEl.split(" ")[0];
          if (k == orderedAllEvaluatedSubjectModules.length - 1) {
            splitSubjectModuleStudEvals.push(JSON.parse(JSON.stringify(subModArr)));
          }
        } else {
          auxCounter = 0;
          if (k == orderedAllEvaluatedSubjectModules.length - 1) {
            subModArr.push(subEl);
            splitSubjectModuleStudEvals.push(JSON.parse(JSON.stringify(subModArr)));
          } else {
            splitSubjectModuleStudEvals.push(JSON.parse(JSON.stringify(subModArr)));
            subModArr = [];
            subModArr.push(subEl);
          }
        }
      }
    }
    if (splitSubjectModuleStudEvals.length == 0 && subModArr.length > 0) {
      splitSubjectModuleStudEvals.push(JSON.parse(JSON.stringify(subModArr)));
    } */

    let lastStudentID;
    let auxArr = [];
    for (let m = 0; m < subjectModuleEvals.length; m++) {
      let studEval = JSON.parse(JSON.stringify(subjectModuleEvals[m]));
      if (lastStudentID != null && lastStudentID != studEval.studentID) {
        lastStudentID = studEval.studentID;
        let avgRow = getAvgRow(auxArr);
        auxArr = auxArr.concat(avgRow);
        finalSplitData.push(JSON.parse(JSON.stringify(auxArr)));
        auxArr = [];
      }
      studEval.columns = ["Disciplina/Módulo"];
      studEval.numColumns = 1;
      studEval.columns = studEval.columns.concat(JSON.parse(JSON.stringify(orderedAllEvaluatedSubjectModules)));
      studEval.numColumns += JSON.parse(JSON.stringify(orderedAllEvaluatedSubjectModules)).length;
      studEval.allEvaluatedSubjectModules = JSON.parse(JSON.stringify(orderedAllEvaluatedSubjectModules));
      auxArr.push(studEval);
      if (auxArr.length > 0 && m == subjectModuleEvals.length - 1) {
        lastStudentID = studEval.studentID;
        let avgRow = getAvgRow(auxArr);
        auxArr = auxArr.concat(avgRow);
        finalSplitData.push(JSON.parse(JSON.stringify(auxArr)));
        auxArr = [];
      }
    }

    function getAvgRow(rows) {
      let evalsDescEvals = {};
      for (let w = 0; w < rows.length; w++) {
        let auxArrEl = auxArr[w];
        orderedAllEvaluatedSubjectModules.forEach((evalDesc) => {
          if (evalsDescEvals[evalDesc] == null) {
            evalsDescEvals[evalDesc] = {};
            evalsDescEvals[evalDesc].numberEvals = 0;
            evalsDescEvals[evalDesc].evalsTotals = 0;
          }
          if (auxArrEl[evalDesc] != null && !isNaN(Number(auxArrEl[evalDesc]))) {
            evalsDescEvals[evalDesc].numberEvals += 1;
            evalsDescEvals[evalDesc].evalsTotals += auxArrEl[evalDesc];
          }
        });
      }
      let avgRow = JSON.parse(JSON.stringify(rows[0]));
      avgRow.targetEvalField = "Média";
      orderedAllEvaluatedSubjectModules.forEach((evalDesc) => {
        avgRow[evalDesc] =
          Math.round((evalsDescEvals[evalDesc].evalsTotals / evalsDescEvals[evalDesc].numberEvals) * 10) / 10;
      });
      return avgRow;
    }
  });

  return finalSplitData;
}

function ptFinalEvalScoreByEvalDesc(data, scope, reportConfig) {
  data = genericReportExport(data, scope, reportConfig);

  for (let j = 0; j < data.length; ) {
    let dataEl = data[j];
    if (dataEl.studFinalEval == null) {
      data.splice(j, 1);
    } else {
      j++;
    }
  }

  data.sort(function (a, b) {
    if (a["schoolYear"] < b["schoolYear"]) {
      return -1;
    }
    if (a["schoolYear"] > b["schoolYear"]) {
      return 1;
    }

    if (a["class"] < b["class"]) {
      return -1;
    }
    if (a["class"] > b["class"]) {
      return 1;
    }

    if (a["subject"] < b["subject"]) {
      return -1;
    }
    if (a["subject"] > b["subject"]) {
      return 1;
    }

    if (scope.module.collection == "Class_Plan_Prof_School_Records") {
      if (a["moduleUFCD"] < b["moduleUFCD"]) {
        return -1;
      }
      if (a["moduleUFCD"] > b["moduleUFCD"]) {
        return 1;
      }
    }
  });

  let splitData = [];

  if (scope.module.collection == "Class_Plan_School_Records") {
    Object.values(groupBy2(data, "schoolYear")).map((arr) =>
      Object.values(groupBy2(arr, "class")).map(function (arr) {
        let finalSplitArray = Object.values(groupBy2(arr, "subject"));
        for (let i = 0; i < finalSplitArray.length; i++) {
          const el = finalSplitArray[i];
          splitData.push(el);
        }
      })
    );
  } else {
    Object.values(groupBy2(data, "schoolYear")).map((arr) =>
      Object.values(groupBy2(data, "class")).map((arr) =>
        Object.values(groupBy2(arr, "subject")).map(function (arr) {
          let finalSplitArray = Object.values(groupBy2(arr, "module"));
          for (let i = 0; i < finalSplitArray.length; i++) {
            const el = finalSplitArray[i];
            splitData.push(el);
          }
        })
      )
    );
  }

  let finalSplitData = [];

  splitData.forEach((subjectModuleEvals) => {
    let allEvaluatedEvalDescs = [];

    // Set up array with one object per student with all subject evals
    for (let m = 0; m < subjectModuleEvals.length; m++) {
      let subjectModuleEvalsStudEl = subjectModuleEvals[m];
      let key =
        subjectModuleEvalsStudEl.evalDesc +
        " " +
        subjectModuleEvalsStudEl.period +
        " " +
        subjectModuleEvalsStudEl.evalDate;
      if (subjectModuleEvalsStudEl.processedFlag == true) {
        continue;
      }
      if (allEvaluatedEvalDescs.indexOf(key) == -1) {
        allEvaluatedEvalDescs.push(key);
      }
      subjectModuleEvalsStudEl[key] = subjectModuleEvalsStudEl.finalEvalLevel;
      for (let k = 0; k < subjectModuleEvals.length; k++) {
        let subjectModuleEvalsEl = subjectModuleEvals[k];
        let subKey =
          subjectModuleEvalsEl.evalDesc + " " + subjectModuleEvalsEl.period + " " + subjectModuleEvalsEl.evalDate;
        if (k == m) {
          continue;
        }
        if (subjectModuleEvalsStudEl.processedFlag == true) {
          continue;
        }
        if (subjectModuleEvalsStudEl.studentID == subjectModuleEvalsEl.studentID) {
          subjectModuleEvalsStudEl[subKey] = subjectModuleEvalsEl.finalEvalLevel;
          if (allEvaluatedEvalDescs.indexOf(subKey) == -1) {
            allEvaluatedEvalDescs.push(subKey);
          }
          subjectModuleEvalsEl.processedFlag = true;
        }
      }
    }

    // Remove objects that were processed previously
    for (let w = 0; w < subjectModuleEvals.length; ) {
      let dataEl = subjectModuleEvals[w];
      if (dataEl.processedFlag == true) {
        subjectModuleEvals.splice(w, 1);
      } else {
        w++;
      }
    }

    let studentsWithClassOrder = [];
    let studentWithoutClassOrder = [];

    studentsWithClassOrder = subjectModuleEvals.filter((stud) => stud.classOrder != null);
    studentWithoutClassOrder = subjectModuleEvals.filter((stud) => stud.classOrder == null);

    studentsWithClassOrder = studentsWithClassOrder.sort(function compareModules(a, b) {
      if (a.classOrder < b.classOrder) {
        return -1;
      }
      if (a.classOrder > b.classOrder) {
        return 1;
      }

      return 0;
    });

    studentWithoutClassOrder = studentWithoutClassOrder.sort(function compareModules(a, b) {
      if (
        a.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "") <
        b.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
      ) {
        return -1;
      }

      if (
        a.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "") >
        b.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
      ) {
        return 1;
      }

      return 0;
    });

    subjectModuleEvals = studentsWithClassOrder.concat(studentWithoutClassOrder);
    subjectModuleEvals.forEach((subjectModuleStudEvals) => {
      let studEval = subjectModuleStudEvals;
      studEval.columns = ["Nº", "Aluno"];
      studEval.numColumns = 2;
      studEval.columns = studEval.columns.concat(allEvaluatedEvalDescs.sort());
      studEval.numColumns += allEvaluatedEvalDescs.length;
      studEval.allEvaluatedEvalDescs = allEvaluatedEvalDescs;
    });
    finalSplitData.push(subjectModuleEvals);
  });

  return finalSplitData;
}

function ptFinalEvalInstrDetails(data, scope, reportConfig) {
  data = genericReportExport(data, scope, reportConfig);

  let splitData = [];

  Object.values(groupBy2(data, "schoolYear")).map((arr) =>
    Object.values(groupBy2(arr, "class")).map((arr) =>
      Object.values(groupBy2(arr, "subject")).map(function (arr) {
        if (scope.module.collection == "Class_Plan_Prof_School_Records") {
          Object.values(groupBy2(arr, "module")).map(function (arr) {
            Object.values(groupBy2(arr, "period")).map(function (arr) {
              let finalSplitArray = Object.values(groupBy2(arr, "evalDate"));
              for (let i = 0; i < finalSplitArray.length; i++) {
                const el = finalSplitArray[i];
                splitData.push(el);
              }
            });
          });
        } else {
          Object.values(groupBy2(arr, "period")).map(function (arr) {
            let finalSplitArray = Object.values(groupBy2(arr, "evalDate"));
            for (let i = 0; i < finalSplitArray.length; i++) {
              const el = finalSplitArray[i];
              splitData.push(el);
            }
          });
        }
      })
    )
  );

  splitData.forEach((subjectModuleEvals) => {
    let allEvaluatedEssenLearns = [];
    let allEvaluatedSecondaryCrits = [];
    subjectModuleEvals.forEach((subjectModuleStudEvals) => {
      let studEval = subjectModuleStudEvals;
      studEval.evaluatedEssenLearns.forEach((essenLearn) => {
        if (allEvaluatedEssenLearns.indexOf(essenLearn) == -1) {
          allEvaluatedEssenLearns.push(essenLearn);
        }
      });
      studEval.evaluatedSecondaryCrits.forEach((secondaryCrit) => {
        if (allEvaluatedSecondaryCrits.indexOf(secondaryCrit) == -1) {
          allEvaluatedSecondaryCrits.push(secondaryCrit);
        }
      });
    });
    subjectModuleEvals.forEach((subjectModuleStudEvals) => {
      let studEval = subjectModuleStudEvals;
      if (studEval.module) {
        studEval.moduleUFCD = studEval.module;
      }
      studEval.columns = ["Aluno"];
      studEval.numColumns = 1;
      studEval.columns = studEval.columns.concat(allEvaluatedEssenLearns.sort());
      studEval.numColumns += allEvaluatedEssenLearns.length;
      studEval.columns = studEval.columns.concat(allEvaluatedSecondaryCrits.sort());
      studEval.numColumns += allEvaluatedSecondaryCrits.length;
      studEval.columns = studEval.columns.concat(["Avaliação Final", "Nível Aval Final"]);
      studEval.numColumns += 2;
    });
  });

  return splitData;
}

function ptFinalEvalInstrDetailsByStudent(data, scope, reportConfig) {
  data = genericReportExport(data, scope, reportConfig);

  for (let j = 0; j < data.length; j++) {
    let el = data[j];
    if (el.module) {
      el.moduleUFCD = el.module;
    }
  }
  data.sort(function (a, b) {
    if (a["name"].localeCompare(b["name"], "pt") == -1) {
      return -1;
    }
    if (a["name"].localeCompare(b["name"], "pt") == 1) {
      return 1;
    }

    if (a["subject"] < b["subject"]) {
      return -1;
    }
    if (a["subject"] > b["subject"]) {
      return 1;
    }

    if (a["period"] < b["period"]) {
      return -1;
    }
    if (a["period"] > b["period"]) {
      return 1;
    }

    if (a.evalDate && b.evalDate) {
      if (parseSpecialDate(a.evalDate) < parseSpecialDate(b.evalDate)) {
        return -1;
      }
      if (parseSpecialDate(a.evalDate) > parseSpecialDate(b.evalDate)) {
        return 1;
      }
    }
  });

  function parseSpecialDate(date) {
    if (date) {
      let splitDate = [];
      splitDate = date.split("/");
      if (splitDate.length == 3) {
        return new Date(splitDate[1] + "/" + splitDate[0] + "/" + splitDate[2]).getTime();
      }
    }
  }

  let splitData = [];

  /*  Object.values(groupBy2(data, "schoolYear")).map((arr) =>
    Object.values(groupBy2(arr, "class")).map((arr) =>
      Object.values(groupBy2(arr, "subject")).map(function (arr) {
        if (scope.module.collection == "Class_Plan_Prof_School_Records") {
          Object.values(groupBy2(arr, "module")).map(function (arr) {
            Object.values(groupBy2(arr, "period")).map(function (arr) {
              let finalSplitArray = Object.values(groupBy2(arr, "evalDate"));
              for (let i = 0; i < finalSplitArray.length; i++) {
                const el = finalSplitArray[i];
                splitData.push(el);
              }
            });
          });
        } else {
          Object.values(groupBy2(arr, "period")).map(function (arr) {
            let finalSplitArray = Object.values(groupBy2(arr, "evalDate"));
            for (let i = 0; i < finalSplitArray.length; i++) {
              const el = finalSplitArray[i];
              splitData.push(el);
            }
          });
        }
      })
    )
  ); */

  Object.values(groupBy2(data, "year")).map(function (arr) {
    let finalSplitArray = Object.values(groupBy2(arr, "name"));
    for (let i = 0; i < finalSplitArray.length; i++) {
      const el = finalSplitArray[i];
      splitData.push(el);
    }
  });

  splitData.forEach((subjectModuleEvals) => {
    subjectModuleEvals.forEach((subjectModuleStudEvals) => {
      subjectModuleStudEvals.evaluatedEssenLearns.forEach((evaluatedEssenLearn) => {
        if (subjectModuleStudEvals[evaluatedEssenLearn] != null && subjectModuleStudEvals[evaluatedEssenLearn] != "") {
          if (subjectModuleStudEvals.appliedEvalInstruments == null) {
            subjectModuleStudEvals.appliedEvalInstruments =
              subjectModuleStudEvals[evaluatedEssenLearn].split(". Aval Domínio:")[0];
          } else {
            subjectModuleStudEvals.appliedEvalInstruments =
              subjectModuleStudEvals.appliedEvalInstruments +
              ", " +
              subjectModuleStudEvals[evaluatedEssenLearn].split(". Aval Domínio:")[0];
          }
        }
      });
    });
  });

  return splitData;
}

function ptProfClassSubjectEval(data, scope, reportConfig) {
  data = genericReportExport(data, scope, reportConfig);

  let lastName = "";
  for (let j = 0; j < data.length; j++) {
    let el = data[j];
    if (el.name != lastName) {
      lastName = el.name;
    } else {
      el.name = null;
    }
    if (el.evalSubject == null) {
      el.evalDate = null;
    }
  }

  let splitData = [];

  Object.values(groupBy2(data, "schoolYear")).map((arr) =>
    Object.values(groupBy2(arr, "subject")).map((arr) =>
      Object.values(groupBy2(arr, "class")).map(function (arr) {
        let finalSplitArray = Object.values(groupBy2(arr, "period"));
        for (let i = 0; i < finalSplitArray.length; i++) {
          const el = finalSplitArray[i];
          splitData.push(el);
        }
      })
    )
  );

  return splitData;
}

function ptProfStudSubjectEval(data, scope, reportConfig) {
  data = genericReportExport(data, scope, reportConfig);

  let lastSubject = "";
  for (let j = 0; j < data.length; j++) {
    let el = data[j];
    if (el.subject != lastSubject) {
      lastSubject = el.subject;
    } else {
      el.subject = null;
    }
    if (el.evalSubject == null) {
      el.evalDate = null;
    }
  }

  let splitData = [];

  Object.values(groupBy2(data, "schoolYear")).map((arr) =>
    Object.values(groupBy2(arr, "class")).map((arr) =>
      Object.values(groupBy2(arr, "period")).map(function (arr) {
        let finalSplitArray = Object.values(groupBy2(arr, "name"));
        for (let i = 0; i < finalSplitArray.length; i++) {
          const el = finalSplitArray[i];
          splitData.push(el);
        }
      })
    )
  );

  return splitData;
}

function ptProfCourseEvals(data, scope, reportConfig) {
  data = genericReportExport(data, scope, reportConfig);

  let lastName = "";
  for (let j = 0; j < data.length; j++) {
    let el = data[j];
    if (el.name != lastName) {
      lastName = el.name;
    } else {
      el.name = null;
    }
    if (el.evalCourse == null) {
      el.evalDate = null;
    }
  }

  let splitData = [];

  Object.values(groupBy2(data, "course")).map(function (arr) {
    let finalSplitArray = Object.values(groupBy2(arr, "coursePlanRef"));
    for (let i = 0; i < finalSplitArray.length; i++) {
      const el = finalSplitArray[i];
      splitData.push(el);
    }
  });

  return splitData;
}

function ptMeasureStud(data, scope, reportConfig) {
  data = genericReportExport(data, scope, reportConfig);

  if (reportConfig.exportTitle.indexOf("Universais") != -1) {
    data = data.filter((el) => el.measureType == "Universal");
  }

  let splitData = [];

  Object.values(groupBy2(data, "schoolYear")).map((arr) =>
    Object.values(groupBy2(arr, "class")).map(function (arr) {
      let finalSplitArray = Object.values(groupBy2(arr, "name"));
      for (let i = 0; i < finalSplitArray.length; i++) {
        const el = finalSplitArray[i];
        splitData.push(el);
      }
    })
  );

  splitData.forEach((splitDataArr) => {
    let lastSubject = "";
    for (let j = 0; j < splitDataArr.length; j++) {
      let el = splitDataArr[j];
      if (el.subject != lastSubject) {
        lastSubject = el.subject;
      } else {
        el.subject = null;
      }
    }
  });

  return splitData;
}

function ptMeasureByStudAndSubject(data, scope, reportConfig) {
  data = genericReportExport(data, scope, reportConfig);

  /*  data.sort(function (a, b) {
    if (a["indisciplineDate"] > b["indisciplineDate"]) {
      return -1;
    }
    if (a["indisciplineDate"] < b["indisciplineDate"]) {
      return 1;
    }
  }); */
  let columns = [];

  let measures = scope.getFromTableData("Class_Plan_Measures");
  measures = JSON.parse(JSON.stringify(measures));
  let measureTypes = scope.getFromTableData("Class_Plan_Measure_Types");
  let measureOriginalSimplifiedMap = {};
  let measureSimplifiedOriginalMap = {};
  let measureStructure = [];

  measures.forEach((measure) => {
    let measureType = measureTypes.filter((mType) => mType.id == measure.measureType);
    if (measureType != null && measureType.length) {
      let simplifiedMeasureName = getSimplifiedMeasureTypeName(measureType[0].measureType) + ". " + measure.measure;
      let originalMeasureName = JSON.parse(JSON.stringify(measure.measure));
      measureOriginalSimplifiedMap[originalMeasureName] = simplifiedMeasureName;
      measureSimplifiedOriginalMap[getMinifiedMeasureName(JSON.parse(JSON.stringify(simplifiedMeasureName)))] =
        originalMeasureName;
      measure.measure = simplifiedMeasureName;
    } else {
      measureOriginalSimplifiedMap[JSON.parse(JSON.stringify(measure.measure))] = "Sem Tipo " + measure.measure;
      measure.measure = "Sem Tipo - " + measure.measure;
    }

    columns.push(getMinifiedMeasureName(measure.measure));
  });

  // Order columns

  let universalMeasures = sortArrayByLocale(columns.filter((el) => el.indexOf("U. ") != -1));
  let selectiveMeasures = sortArrayByLocale(columns.filter((el) => el.indexOf("S. ") != -1));
  let additionalMeasures = sortArrayByLocale(columns.filter((el) => el.indexOf("A. ") != -1));
  let adaptiveMeasures = sortArrayByLocale(columns.filter((el) => el.indexOf("APA. ") != -1));

  function sortArrayByLocale(arr) {
    return arr.sort(function (a, b) {
      if (a.localeCompare(b, "pt") == -1) {
        return -1;
      }
      if (a.localeCompare(b, "pt") == 1) {
        return 1;
      }
    });
  }

  columns = universalMeasures.concat(selectiveMeasures.concat(additionalMeasures).concat(adaptiveMeasures));

  measureStructure = JSON.parse(JSON.stringify(columns));

  columns.unshift("Disciplina");

  let numColumns = columns.length;

  // Create caption

  let caption = [];
  caption.push("Universais");
  universalMeasures.forEach((simplifiedMeasure) => {
    let originalMeasure = measureSimplifiedOriginalMap[simplifiedMeasure];
    if (originalMeasure != null) {
      caption.push(" - " + originalMeasure);
    }
  });
  caption.push("Seletivas");
  selectiveMeasures.forEach((simplifiedMeasure) => {
    let originalMeasure = measureSimplifiedOriginalMap[simplifiedMeasure];
    if (originalMeasure != null) {
      caption.push(" - " + originalMeasure);
    }
  });
  caption.push("Adicionais");
  additionalMeasures.forEach((simplifiedMeasure) => {
    let originalMeasure = measureSimplifiedOriginalMap[simplifiedMeasure];
    if (originalMeasure != null) {
      caption.push(" - " + originalMeasure);
    }
  });
  caption.push("Adaptações Processo Avaliação");
  adaptiveMeasures.forEach((simplifiedMeasure) => {
    let originalMeasure = measureSimplifiedOriginalMap[simplifiedMeasure];
    if (originalMeasure != null) {
      caption.push(" - " + originalMeasure);
    }
  });

  let caption1 = (caption || []).slice(0, getIndexOfHalfOfArray(caption));
  let caption2 = (caption || []).slice(getIndexOfHalfOfArray(caption));

  let splitCaptions = [];

  for (let i = 0; i < caption1.length; i++) {
    let obj = {};
    if (caption1[i] != null) {
      obj.left = caption1[i];
    }
    if (caption2[i] != null) {
      obj.right = caption2[i];
    }
    obj.halfNumColumns = Math.floor(numColumns / 2);
    splitCaptions.push(obj);
  }

  function getSimplifiedMeasureTypeName(measureType) {
    let splitMeasureType = measureType.split(" ");
    if (splitMeasureType.length > 1) {
      let simplifiedMeasureTypeName = "";
      splitMeasureType.forEach((measureTypeEl) => {
        simplifiedMeasureTypeName += measureTypeEl[0];
      });
      return simplifiedMeasureTypeName;
    } else {
      return measureType[0];
    }
  }

  for (let j = 0; j < data.length; j++) {
    let dataEl = data[j];
    dataEl.caption = splitCaptions;
    dataEl.numColumns = numColumns;
    dataEl.columns = columns;
    dataEl.measureStructure = measureStructure;
    for (let i = 0; i < data.length; ) {
      let dataSubEl = data[i];
      if (dataEl.studentID == dataSubEl.studentID && dataEl.subject == dataSubEl.subject) {
        if (measureOriginalSimplifiedMap[dataSubEl.measure]) {
          dataEl[getMinifiedMeasureName(measureOriginalSimplifiedMap[dataSubEl.measure])] = "X";
        }
        if (j != i) {
          data.splice(i, 1);
          continue;
        }
      }
      i++;
    }
  }

  function getMinifiedMeasureName(measure) {
    if (measure.indexOf(")") != -1 && measure.indexOf(")") < 8) {
      return measure.slice(0, measure.indexOf(")") + 1);
    } else {
      return measure.substring(0, 8);
    }
  }

  data.sort(function (a, b) {
    if (a.name.localeCompare(b.name, "pt") == -1) {
      return -1;
    }
    if (a.name.localeCompare(b.name, "pt") == 1) {
      return 1;
    }
  });

  let splitData = [];

  Object.values(groupBy2(data, "year")).map((arr) =>
    Object.values(groupBy2(arr, "schoolYear")).map((arr) =>
      Object.values(groupBy2(arr, "class")).map(function (arr) {
        let finalSplitArray = Object.values(groupBy2(arr, "name"));
        for (let i = 0; i < finalSplitArray.length; i++) {
          const el = finalSplitArray[i];
          splitData.push(el);
        }
      })
    )
  );

  splitData.forEach((splitDataArr) => {
    splitDataArr.sort(function (a, b) {
      if (a.subject.localeCompare(b.subject, "pt") == -1) {
        return -1;
      }
      if (a.subject.localeCompare(b.subject, "pt") == 1) {
        return 1;
      }
    });
  });

  return splitData;
}

function ptEvalQualitativeLevel(data, scope, reportConfig) {
  data = genericReportExport(data, scope, reportConfig);
  let dataToBeSplit = [];

  data.forEach((el) => {
    //Remove duplicate subjects
    if (el.subject != null && el.subject != "") {
      let subjectArray = el.subject.split(",");
      if (subjectArray.length > 1) {
        el.subject = subjectArray
          .filter(function (item, pos, self) {
            return self.indexOf(item) == pos;
          })
          .toString();
      }
    }

    //Columns
    el.columns.length = 0;
    el.columns.push("Critério");

    //Parsed qualitative levels
    if (el.qualitativeLevels != null && Array.isArray(el.qualitativeLevels) && el.qualitativeLevels.length) {
      let parsedQualitativeLevels = [];
      el.qualitativeLevelsNamesArr = [];
      el.qualitativeLevels.forEach((qL) => {
        let toBeAddedQl = "";
        if (qL.qualitativeLevel) {
          //Add qualitative level unique columns
          el.columns.push(qL.qualitativeLevel);
          //Add qualitativeLevelsNamesArr field for the pdf template to loop through
          el.qualitativeLevelsNamesArr.push(qL.qualitativeLevel);
          toBeAddedQl += qL.qualitativeLevel;
        }
        if (qL.quantitativeLevel != null) {
          toBeAddedQl += " " + qL.quantitativeLevel + "%";
        }
        parsedQualitativeLevels.push(toBeAddedQl);
      });
      if (parsedQualitativeLevels.length) {
        el.parsedQualitativeLevels = parsedQualitativeLevels.toString().replace(/\,/g, ", ");
      }
    }

    //Parsed performance crits
    if (el.performanceCrits != null && Array.isArray(el.performanceCrits) && el.performanceCrits.length) {
      let parsedPerformanceCrits = [];
      el.performanceCrits.forEach((pC) => {
        parsedPerformanceCrits.push(pC.performanceCrit);
      });
      if (parsedPerformanceCrits.length) {
        el.parsedPerformanceCrits = parsedPerformanceCrits.toString().replace(/\,/g, ", ");
      }
    }

    if (
      el.qualitativeLevels != null &&
      Array.isArray(el.qualitativeLevels) &&
      el.qualitativeLevels.length &&
      el.performanceCritDesc != null &&
      Array.isArray(el.performanceCritDesc) &&
      el.performanceCritDesc.length &&
      el.performanceCrits != null &&
      Array.isArray(el.performanceCrits) &&
      el.performanceCrits.length
    ) {
      //Sort performanceCritDescs and performanceCrits
      el.performanceCritDesc.sort(function (a, b) {
        if (a["performanceCrit"] < b["performanceCrit"]) {
          return -1;
        }
        if (a["performanceCrit"] > b["performanceCrit"]) {
          return 1;
        }
      });
      el.performanceCrits.sort(function (a, b) {
        if (a["performanceCrit"] < b["performanceCrit"]) {
          return -1;
        }
        if (a["performanceCrit"] > b["performanceCrit"]) {
          return 1;
        }
      });

      //Push the performanceCritDescs to the specific qualitative level column
      for (let l = 0; l < el.performanceCrits.length; l++) {
        let preformCrit = el.performanceCrits[l];
        let elToBeAdded = JSON.parse(JSON.stringify(el));
        elToBeAdded.performanceDescAddedFlag = false;
        for (let k = 0; k < el.performanceCritDesc.length; k++) {
          let preformCritDesc = el.performanceCritDesc[k];
          if (preformCrit.performanceCrit == preformCritDesc.performanceCrit) {
            elToBeAdded.performanceCrit = preformCritDesc.performanceCrit;
            if (elToBeAdded[preformCritDesc.qualitativeLevel] == null) {
              elToBeAdded[preformCritDesc.qualitativeLevel] = preformCritDesc.performanceDesc;
              elToBeAdded.performanceDescAddedFlag = true;
            }
            /* else if (elToBeAdded[preformCritDesc.qualitativeLevel + "_1"] == null) {
              elToBeAdded[preformCritDesc.qualitativeLevel + "_1"] = preformCritDesc.performanceDesc;
            } else if (elToBeAdded[preformCritDesc.qualitativeLevel + "_2"] == null) {
              elToBeAdded[preformCritDesc.qualitativeLevel + "_2"] = preformCritDesc.performanceDesc;
            } */
          }
        }
        dataToBeSplit.push(elToBeAdded);
      }
    }
  });

  let splitData = [];

  Object.values(groupBy2(dataToBeSplit, "year")).map((arr) =>
    Object.values(groupBy2(arr, "schoolYear")).map((arr) =>
      Object.values(groupBy2(arr, "subject")).map(function (arr) {
        let finalSplitArray = Object.values(groupBy2(arr, "performanceLevelDesc"));
        for (let i = 0; i < finalSplitArray.length; i++) {
          const el = finalSplitArray[i];
          splitData.push(el);
        }
      })
    )
  );

  return splitData;
}

function ptIndisciplineOccurrences(data, scope, reportConfig) {
  data = genericReportExport(data, scope, reportConfig);

  data.sort(function (a, b) {
    if (a["indisciplineDate"] > b["indisciplineDate"]) {
      return -1;
    }
    if (a["indisciplineDate"] < b["indisciplineDate"]) {
      return 1;
    }
  });

  let splitData = [];

  Object.values(groupBy2(data, "schoolYear")).map((arr) =>
    Object.values(groupBy2(arr, "class")).map(function (arr) {
      let finalSplitArray = Object.values(groupBy2(arr, "name"));
      for (let i = 0; i < finalSplitArray.length; i++) {
        const el = finalSplitArray[i];
        splitData.push(el);
      }
    })
  );

  return splitData;
}

function ptClassDescriptions(data, scope, reportConfig) {
  if (reportConfig.activeTab) {
    data = genericParentCommunicationReportExport(data, scope, reportConfig);
  } else {
    data = genericReportExport(data, scope, reportConfig);
  }

  data.sort(function (a, b) {
    if (a["classDescDate"] > b["classDescDate"]) {
      return -1;
    }
    if (a["classDescDate"] < b["classDescDate"]) {
      return 1;
    }
  });

  let splitData = [];

  Object.values(groupBy2(data, "year")).map((arr) =>
    Object.values(groupBy2(arr, "schoolYear")).map((arr) =>
      Object.values(groupBy2(arr, "class")).map(function (arr) {
        let finalSplitArray = Object.values(groupBy2(arr, "classDescDate"));
        for (let i = 0; i < finalSplitArray.length; i++) {
          const el = finalSplitArray[i];
          splitData.push(el);
        }
      })
    )
  );

  return splitData;
}

function ptEduPlanProjects(data, scope, reportConfig) {
  if (reportConfig.activeTab) {
    data = genericParentCommunicationReportExport(data, scope, reportConfig);
  } else {
    data = genericReportExport(data, scope, reportConfig);
  }

  data.sort(function (a, b) {
    if (a["projectStartDateMs"] > b["projectStartDateMs"]) {
      return -1;
    }
    if (a["projectStartDateMs"] < b["projectStartDateMs"]) {
      return 1;
    }
  });

  let splitData = [];

  let finalSplitArray = Object.values(groupBy2(data, "projectName"));
  for (let i = 0; i < finalSplitArray.length; i++) {
    const el = finalSplitArray[i];
    splitData.push(el);
  }

  return splitData;
}

function ptFCTIntership(data, scope, reportConfig) {
  if (reportConfig.activeTab) {
    data = genericParentCommunicationReportExport(data, scope, reportConfig);
  } else {
    data = genericReportExport(data, scope, reportConfig);
  }

  data.sort(function (a, b) {
    if (a["year"] > b["year"]) {
      return -1;
    }
    if (a["year"] < b["year"]) {
      return 1;
    }

    if (a["name"] > b["name"]) {
      return -1;
    }
    if (a["name"] < b["name"]) {
      return 1;
    }
  });

  let splitData = [];

  Object.values(groupBy2(data, "year")).map((arr) =>
    Object.values(groupBy2(arr, "entity")).map((arr) =>
      Object.values(groupBy2(arr, "internship")).map(function (arr) {
        let finalSplitArray = Object.values(groupBy2(arr, "name"));
        for (let i = 0; i < finalSplitArray.length; i++) {
          const el = finalSplitArray[i];
          splitData.push(el);
        }
      })
    )
  );

  return splitData;
}

function ptFCTEntityStuds(data, scope, reportConfig) {
  if (reportConfig.activeTab) {
    data = genericParentCommunicationReportExport(data, scope, reportConfig);
  } else {
    data = genericReportExport(data, scope, reportConfig);
  }

  data.forEach((stud) => {
    stud.numColumns = 9;
    stud.internshipPeriod = stud.internshipPeriodStart + " - " + stud.internshipPeriodEnd;
  });

  data.sort(function (a, b) {
    return a["name"].localeCompare(b["name"], "pt");
  });

  let splitData = [];

  Object.values(groupBy2(data, "year")).map(function (arr) {
    let finalSplitArray = Object.values(groupBy2(arr, "course"));
    for (let i = 0; i < finalSplitArray.length; i++) {
      const el = finalSplitArray[i];
      splitData.push(el);
    }
  });

  return splitData;
}

function ptFCTInsuranceStuds(data, scope, reportConfig) {
  if (reportConfig.activeTab) {
    data = genericParentCommunicationReportExport(data, scope, reportConfig);
  } else {
    data = genericReportExport(data, scope, reportConfig);
  }

  data.forEach((stud) => {
    stud.numColumns = 5;
    stud.internshipPeriod = stud.internshipPeriodStart + " - " + stud.internshipPeriodEnd;
  });

  data.sort(function (a, b) {
    return a["name"].localeCompare(b["name"], "pt");
  });

  let splitData = [];

  Object.values(groupBy2(data, "year")).map(function (arr) {
    let finalSplitArray = Object.values(groupBy2(arr, "course"));
    for (let i = 0; i < finalSplitArray.length; i++) {
      const el = finalSplitArray[i];
      splitData.push(el);
    }
  });

  return splitData;
}

function vfxOrderByName(data) {
  data.sort(function (a, b) {
    return a["name"].localeCompare(b["name"], "pt");
  });

  return data;
}

function vfxTransportSplitStudentListingTransp(data, scope) {
  return new Promise((resolve, reject) => {
    data.sort(function (a, b) {
      return a["name"].localeCompare(b["name"], "pt");
    });

    scope.genericFactory.setRouteName("Transp_Trans");
    scope.genericFactory.getByProperty("organization", scope.currentUser.organization).then(function (transps) {
      var position = scope.$mdPanel.newPanelPosition().absolute().center();
      var config = {
        attachTo: angular.element(document.body),
        disableParentScroll: false,
        controller: "StudentListingTranspPanelCtrl",
        controllerAs: "ctrl",
        templateUrl: "templates/studentListingTranspPanel.html",
        hasBackdrop: true,
        panelClass: "a-panel",
        position: position,
        trapFocus: true,
        zIndex: 1,
        clickOutsideToClose: true,
        escapeToClose: true,
        focusOnOpen: true,
        onDomRemoved: function () {
          scope.selectedRow = null;
          scope.genericFactory.setRouteName("Transp_Studs");
          scope.getModuleData();
        },
        locals: {
          splitDataBySelectedTransports: splitDataBySelectedTransports,
          genericScope: scope,
          transps: transps,
        },
      };

      scope.$mdPanel.open(config).then(function (result) {
        scope.panelRef = result;
      });

      function splitDataBySelectedTransports(transport) {
        if (transport == "Todas") {
          resolve(true);
          scope.genericFactory.setRouteName("Transp_Studs");
          return data;
        } else {
          for (let j = 0; j < data.length; ) {
            let dataEl = data[j];
            if (dataEl.transportInfo) {
              for (let k = 0; k < dataEl.transportInfo.length; ) {
                let dataElTransportInfo = dataEl.transportInfo[k];
                if (
                  dataElTransportInfo.transport != transport ||
                  (dataElTransportInfo.card && dataElTransportInfo.card[0] == "Pedido")
                ) {
                  dataEl.transportInfo.splice(k, 1);
                } else {
                  k++;
                }
              }
              if (dataEl.transportInfo.length == 0) {
                data.splice(j, 1);
              } else {
                j++;
              }
            } else {
              data.splice(j, 1);
            }
          }
          resolve(true);
          scope.genericFactory.setRouteName("Transp_Studs");
          return data;
        }
      }
    });
  });
}

function vfxTransportSplitVouchRelation(data) {
  data.sort(function (a, b) {
    if (a["distributionPoint"] < b["distributionPoint"]) {
      return -1;
    }
    if (a["distributionPoint"] > b["distributionPoint"]) {
      return 1;
    }

    if (a["school"] < b["school"]) {
      return -1;
    }
    if (a["school"] > b["school"]) {
      return 1;
    }

    if (a["transport"] < b["transport"]) {
      return -1;
    }
    if (a["transport"] > b["transport"]) {
      return 1;
    }

    if (a["ticket"] < b["ticket"]) {
      return -1;
    }
    if (a["ticket"] > b["ticket"]) {
      return 1;
    }

    return a["name"].localeCompare(b["name"], "pt");
  });

  let splitData = [];

  Object.values(groupBy2(data, "distributionPoint")).map((arr) =>
    Object.values(groupBy2(arr, "school")).map((arr) =>
      Object.values(groupBy2(arr, "transport")).map(function (arr) {
        let finalSplitArray = Object.values(groupBy2(arr, "ticket"));
        for (let i = 0; i < finalSplitArray.length; i++) {
          const el = finalSplitArray[i];
          splitData.push(el);
        }
      })
    )
  );

  return splitData;
}

function vfxTransportSplitSignatureRequest(data, scope) {
  return new Promise((resolve, reject) => {
    data.sort(function (a, b) {
      if (a["transport"] < b["transport"]) {
        return -1;
      }
      if (a["transport"] > b["transport"]) {
        return 1;
      }

      if (a["school"] < b["school"]) {
        return -1;
      }
      if (a["school"] > b["school"]) {
        return 1;
      }

      if (a["distributionPoint"] < b["distributionPoint"]) {
        return -1;
      }
      if (a["distributionPoint"] > b["distributionPoint"]) {
        return 1;
      }

      if (a["origin"] < b["origin"]) {
        return -1;
      }
      if (a["origin"] > b["origin"]) {
        return 1;
      }

      if (a["destination"] < b["destination"]) {
        return -1;
      }
      if (a["destination"] > b["destination"]) {
        return 1;
      }

      return a["name"].localeCompare(b["name"], "pt");
    });

    for (let i = 0; i < data.length; ) {
      const el = data[i];
      if (el.transportInfo[0].card && el.transportInfo[0].card[0] != "Pedido") {
        data.splice(i, 1);
      } else {
        i++;
      }
    }

    let imagesToProcess = 0;
    let processedImages = 0;

    let containerName = scope.currentUser.organization + scope.module.collection;
    scope.storageFactory.getContainer(containerName).then((ct) => {
      scope.storageFactory
        .listFilesInContainer(containerName)
        .then((fileList) => {
          //Check if stud photo was inserted
          for (let w = 0; w < data.length; w++) {
            let el = data[w];
            for (let k = 0; k < fileList.data.length; k++) {
              let storedFile = fileList.data[k];
              if (storedFile.name.indexOf(el.id) != -1) {
                imagesToProcess += 1;
                //el.studPhoto = storedFile.name;
                /* console.log(scope.storageFactory.downloadFile(containerName, storedFile.name));
              var reader = new FileReader();
              reader.readAsDataURL(scope.storageFactory.downloadFile(containerName, storedFile.name));
              reader.onloadend = function () {
                el.studPhoto = reader.result;
              } */

                let img = document.createElement("img");
                img.src = "/api/containers/" + containerName + "/download/" + storedFile.name;
                //img.src = 'http://0.0.0.0:3000/api/containers/' + containerName + '/download/' + storedFile.name;
                img.onload = function () {
                  let c = document.createElement("canvas");
                  let ctx = c.getContext("2d");
                  ctx.drawImage(img, c.width / 2 - img.width / 2, c.height / 2 - img.height / 2);
                  el.studPhoto = c.toDataURL();
                  processedImages += 1;
                  tryToResolve();
                };
              }
            }
          }

          let splitData = [];

          Object.values(groupBy2(data, "transport")).map((arr) =>
            Object.values(groupBy2(arr, "school")).map((arr) =>
              Object.values(groupBy2(arr, "distributionPoint")).map((arr) =>
                Object.values(groupBy2(arr, "origin")).map(function (arr) {
                  let finalSplitArray = Object.values(groupBy2(arr, "destination"));
                  for (let i = 0; i < finalSplitArray.length; i++) {
                    const el = finalSplitArray[i];
                    splitData.push(el);
                  }
                })
              )
            )
          );

          data.length = 0;
          for (let l = 0; l < splitData.length; l++) {
            const splitElement = splitData[l];
            data.push(splitElement);
          }

          if (imagesToProcess == 0) {
            resolve(true);
          }

          function tryToResolve() {
            if (imagesToProcess == processedImages) {
              resolve(true);
            }
          }
        })
        .catch((response) => {
          let splitData = [];

          Object.values(groupBy2(data, "transport")).map((arr) =>
            Object.values(groupBy2(arr, "school")).map((arr) =>
              Object.values(groupBy2(arr, "distributionPoint")).map((arr) =>
                Object.values(groupBy2(arr, "origin")).map(function (arr) {
                  let finalSplitArray = Object.values(groupBy2(arr, "destination"));
                  for (let i = 0; i < finalSplitArray.length; i++) {
                    const el = finalSplitArray[i];
                    splitData.push(el);
                  }
                })
              )
            )
          );

          data.length = 0;
          for (let l = 0; l < splitData.length; l++) {
            const splitElement = splitData[l];
            data.push(splitElement);
          }

          resolve(true);
        });
    });
  });
}

function vfxAccountingStatement(data) {
  let groupedData = [];

  for (let i = 0; i < data.length; i++) {
    const dataEl = data[i];

    if (dataEl.transport && dataEl.transport == "BV") {
      data.splice(i, 1);
      i--;
      continue;
    }

    let updatedGroupedData = false;
    let ageDesc;

    if (dataEl.adultPrice != null && dataEl.adultPrice != "") {
      ageDesc = "adult";
    } else if (dataEl.childPrice != null && dataEl.childPrice != "") {
      ageDesc = "child";
    }

    for (let j = 0; j < groupedData.length; j++) {
      const groupedDataEl = groupedData[j];
      if (
        groupedDataEl.ticket === dataEl.ticket &&
        groupedDataEl.transport === dataEl.transport &&
        ageDesc == "adult"
      ) {
        groupedDataEl.sent += 1;
        updatedGroupedData = true;
        break;
      } else if (
        groupedDataEl.ticket === dataEl.ticket + " M" &&
        groupedDataEl.transport === dataEl.transport &&
        ageDesc == "child"
      ) {
        groupedDataEl.sent += 1;
        updatedGroupedData = true;
        break;
      }
    }

    if (!updatedGroupedData) {
      if (ageDesc == "adult") {
        groupedData.push({
          ticket: dataEl.ticket,
          sent: 1,
          unitaryPrice: dataEl.adultPrice,
          transport: dataEl.transport,
          year: dataEl.year,
          month: dataEl.month,
          distributionPoint: dataEl.distributionPoint,
        });
      } else if (ageDesc == "child") {
        groupedData.push({
          ticket: dataEl.ticket + " M",
          sent: 1,
          unitaryPrice: dataEl.childPrice,
          transport: dataEl.transport,
          year: dataEl.year,
          month: dataEl.month,
          distributionPoint: dataEl.distributionPoint,
        });
      }
    }
  }

  groupedData.sort(function (a, b) {
    if (a["transport"] < b["transport"]) {
      return -1;
    }
    if (a["transport"] > b["transport"]) {
      return 1;
    }

    if (a["ticket"] < b["ticket"]) {
      return -1;
    }
    if (a["ticket"] > b["ticket"]) {
      return 1;
    }
  });

  return {
    data: groupedData,
    length: data.length,
  };
}

function ptSchoolRecordClass(data, scope) {
  data.sort(function (a, b) {
    if (a["name"].localeCompare(b["name"], "pt") == -1) {
      return -1;
    }
    if (a["name"].localeCompare(b["name"], "pt") == 1) {
      return 1;
    }

    if (a["essentialLearning"] < b["essentialLearning"]) {
      return -1;
    }
    if (a["essentialLearning"] > b["essentialLearning"]) {
      return 1;
    }
  });

  let splitData = [];

  let finalSplitArray = Object.values(groupBy2(data, "evalInstrument"));
  for (let i = 0; i < finalSplitArray.length; i++) {
    const el = finalSplitArray[i];
    splitData.push(el);
  }

  if (splitData.length > 0) {
    splitData[0][0].cluster = scope.$parent.clientOrgName;
  }

  return {
    data: splitData,
    length: data.length,
  };
}

function ptSchoolRecordClassByEssenLearn(data, scope) {
  data.sort(function (a, b) {
    if (a["name"].localeCompare(b["name"], "pt") == -1) {
      return -1;
    }
    if (a["name"].localeCompare(b["name"], "pt") == 1) {
      return 1;
    }

    if (a["evalInstrument"] < b["evalInstrument"]) {
      return -1;
    }
    if (a["evalInstrument"] > b["evalInstrument"]) {
      return 1;
    }
  });

  let splitData = [];

  let finalSplitArray = Object.values(groupBy2(data, "essentialLearning"));
  for (let i = 0; i < finalSplitArray.length; i++) {
    const el = finalSplitArray[i];
    splitData.push(el);
  }

  if (splitData.length > 0) {
    splitData[0][0].cluster = scope.$parent.clientOrgName;
  }

  return {
    data: splitData,
    length: data.length,
  };
}

function schoolMilkMonthlyConsumptionByClusterSchoolCMS(data, scope, reportConfig) {
  data = genericReportExport(data, scope, reportConfig);

  data.forEach((dataEl) => {
    dataEl.month = parseSpecialDate(dataEl.consumptionDateMs).getMonth() + 1;
  });

  data.sort(function (a, b) {
    if (a["year"] < b["year"]) {
      return -1;
    }
    if (a["year"] > b["year"]) {
      return 1;
    }

    if (a["cluster"].localeCompare(b["cluster"], "pt") == -1) {
      return -1;
    }
    if (a["cluster"].localeCompare(b["cluster"], "pt") == 1) {
      return 1;
    }

    if (a["school"].localeCompare(b["school"], "pt") == -1) {
      return -1;
    }
    if (a["school"].localeCompare(b["school"], "pt") == 1) {
      return 1;
    }

    if (a["milkType"].localeCompare(b["milkType"], "pt") == -1) {
      return -1;
    }
    if (a["milkType"].localeCompare(b["milkType"], "pt") == 1) {
      return 1;
    }

    if (parseSpecialDate(a.consumptionDateMs).getTime() < parseSpecialDate(b.consumptionDateMs).getTime()) {
      return -1;
    }
    if (parseSpecialDate(a.consumptionDateMs).getTime() > parseSpecialDate(b.consumptionDateMs).getTime()) {
      return 1;
    }
  });

  function parseSpecialDate(date) {
    if (date) {
      let splitDate = [];
      splitDate = date.split("/");
      if (splitDate.length == 3) {
        return new Date(splitDate[1] + "/" + splitDate[0] + "/" + splitDate[2]);
      }
    }
  }

  let splitData = [];

  Object.values(groupBy2(data, "year")).map(function (arr) {
    let finalSplitArray = Object.values(groupBy2(arr, "cluster"));
    for (let i = 0; i < finalSplitArray.length; i++) {
      const el = finalSplitArray[i];
      splitData.push(el);
    }
  });

  let totalPacksConsumed = 0;

  splitData.forEach((dataArr) => {
    for (let k = 0; k < dataArr.length; k++) {
      let el = dataArr[k];

      // Add pastMonthPacksConsumed sum
      if (!isNaN(Number(el.pastMonthPacksConsumed))) {
        totalPacksConsumed = totalPacksConsumed + el.pastMonthPacksConsumed;
      }

      if (k == dataArr.length - 1) {
        let totalRow = JSON.parse(JSON.stringify(el));
        totalRow.totalRow = true;
        totalRow.pastMonthPacksConsumed = totalPacksConsumed;
        dataArr.push(totalRow);
        break;
      }
    }
    totalPacksConsumed = 0;
  });

  return splitData;
}

function ptAFCArticulationByEducationLevel(data, scope, reportConfig) {
  data = genericReportExport(utilFunctions["ptSplitAFCRecords"](scope, data), scope, reportConfig);

  let splitData = [];

  Object.values(groupBy2(data, "year")).map(function (arr) {
    let finalSplitArray = Object.values(groupBy2(arr, "educationLevel"));
    for (let i = 0; i < finalSplitArray.length; i++) {
      const el = finalSplitArray[i];
      splitData.push(el);
    }
  });

  let splitDataBySchoolYear = [];

  splitData.forEach((splitDataArr) => {
    let sumBySchoolYear = {};
    let newSplitDataArr = [];

    for (let j = 0; j < splitDataArr.length; j++) {
      let dataEl = splitDataArr[j];
      if (sumBySchoolYear[dataEl.schoolYear] == null) {
        sumBySchoolYear[dataEl.schoolYear] = dataEl;
        sumBySchoolYear[dataEl.schoolYear].concludedProjects = 0;
        sumBySchoolYear[dataEl.schoolYear].plannedProjects = 0;
      }
      switch (dataEl.articulationState) {
        case "Concluída":
          sumBySchoolYear[dataEl.schoolYear].concludedProjects += 1;
          break;
        case "Planificada":
          sumBySchoolYear[dataEl.schoolYear].plannedProjects += 1;
          break;
        default:
          break;
      }
      if (j == splitDataArr.length - 1) {
        newSplitDataArr = Object.values(sumBySchoolYear);
        newSplitDataArr.sort(function (a, b) {
          if (a["schoolYear"].localeCompare(b["schoolYear"], "pt") == -1) {
            return -1;
          }
          if (a["schoolYear"].localeCompare(b["schoolYear"], "pt") == 1) {
            return 1;
          }
        });
        let totalRow = {};
        totalRow.schoolYear = "Total";
        totalRow.concludedProjects = 0;
        totalRow.plannedProjects = 0;
        for (const schoolYear in sumBySchoolYear) {
          if (Object.hasOwnProperty.call(sumBySchoolYear, schoolYear)) {
            let schoolYearTotals = sumBySchoolYear[schoolYear];
            totalRow.concludedProjects += schoolYearTotals.concludedProjects;
            totalRow.plannedProjects += schoolYearTotals.plannedProjects;
          }
        }
        newSplitDataArr.push(totalRow);
        splitDataBySchoolYear.push(newSplitDataArr);
        break;
      }
    }
  });

  return splitDataBySchoolYear;
}

function ptAFCArticulationBySubject(data, scope, reportConfig) {
  data = genericReportExport(utilFunctions["ptSplitAFCRecords"](scope, data, true), scope, reportConfig);

  let splitData = [];

  Object.values(groupBy2(data, "year")).map(function (arr) {
    let finalSplitArray = Object.values(groupBy2(arr, "educationLevel"));
    for (let i = 0; i < finalSplitArray.length; i++) {
      const el = finalSplitArray[i];
      splitData.push(el);
    }
  });

  let splitDataBySubject = [];

  splitData.forEach((splitDataArr) => {
    let sumBySubject = {};
    let newSplitDataArr = [];

    for (let j = 0; j < splitDataArr.length; j++) {
      let dataEl = splitDataArr[j];
      if (sumBySubject[dataEl.parsedSubjectModule] == null) {
        sumBySubject[dataEl.parsedSubjectModule] = dataEl;
        sumBySubject[dataEl.parsedSubjectModule].concludedProjects = 0;
        sumBySubject[dataEl.parsedSubjectModule].plannedProjects = 0;
      }
      switch (dataEl.articulationState) {
        case "Concluída":
          sumBySubject[dataEl.parsedSubjectModule].concludedProjects += 1;
          break;
        case "Planificada":
          sumBySubject[dataEl.parsedSubjectModule].plannedProjects += 1;
          break;
        default:
          break;
      }
      if (j == splitDataArr.length - 1) {
        newSplitDataArr = Object.values(sumBySubject);
        newSplitDataArr.sort(function (a, b) {
          if (a["parsedSubjectModule"].localeCompare(b["parsedSubjectModule"], "pt") == -1) {
            return -1;
          }
          if (a["parsedSubjectModule"].localeCompare(b["parsedSubjectModule"], "pt") == 1) {
            return 1;
          }
        });
        let totalRow = {};
        totalRow.parsedSubjectModule = "Total";
        totalRow.concludedProjects = 0;
        totalRow.plannedProjects = 0;
        for (const subject in sumBySubject) {
          if (Object.hasOwnProperty.call(sumBySubject, subject)) {
            let subjectTotals = sumBySubject[subject];
            totalRow.concludedProjects += subjectTotals.concludedProjects;
            totalRow.plannedProjects += subjectTotals.plannedProjects;
          }
        }
        newSplitDataArr.push(totalRow);
        splitDataBySubject.push(newSplitDataArr);
        break;
      }
    }
  });

  return splitDataBySubject;
}

function ptAnnualActivityPlanByEducationPlanObjetives(data, scope, reportConfig) {
  data = genericReportExport(data, scope, reportConfig);

  data.sort(function (a, b) {
    if (a["year"] < b["year"]) {
      return -1;
    }
    if (a["year"] > b["year"]) {
      return 1;
    }

    if (a["cluster"] < b["cluster"]) {
      return -1;
    }
    if (a["cluster"] > b["cluster"]) {
      return 1;
    }

    if (a["stratObjective"] < b["stratObjective"]) {
      return -1;
    }
    if (a["stratObjective"] > b["stratObjective"]) {
      return 1;
    }

    if (a["opObjective"] < b["opObjective"]) {
      return -1;
    }
    if (a["opObjective"] > b["opObjective"]) {
      return 1;
    }
  });

  let splitData = [];

  Object.values(groupBy2(data, "year")).map((arr) =>
    Object.values(groupBy2(arr, "cluster")).map((arr) =>
      Object.values(groupBy2(arr, "stratObjective")).map(function (arr) {
        let finalSplitArray = Object.values(groupBy2(arr, "opObjective"));
        for (let i = 0; i < finalSplitArray.length; i++) {
          const el = finalSplitArray[i];
          splitData.push(el);
        }
      })
    )
  );
  return splitData;
}

function ptSplitAnnualActivityPlanRecordsBySchool(data, scope, reportConfig) {
  // Criar campo Turmas Destinatárias só para apresentação. Assim o class não fica com o conteúdo lixado
  return new Promise((resolve, reject) => {
    // Set data as selectedRow if a row is selected
    if (scope.selectedRow != null) {
      data = [JSON.parse(JSON.stringify(scope.selectedRow))];
    }

    // Get envolved classes
    let envolvedClasses = [];

    data.forEach((dataEl) => {
      if (dataEl.class != null && Array.isArray(dataEl.class) && dataEl.class.length > 0) {
        dataEl.class.forEach((cl) => {
          if (envolvedClasses.indexOf(cl) == -1) {
            envolvedClasses.push(cl);
          }
        });
      }
    });

    if (envolvedClasses.length > 0) {
      // Load students

      scope.genericFactory.setRouteName("Class_Plan_Students");
      scope.genericFactory
        .getByProperties(
          { class: (envolvedClasses || []).slice(0, getIndexOfHalfOfArray(envolvedClasses)) },
          scope.currentUser.organization
        )
        .then(function (studentsData1) {
          scope.genericFactory
            .getByProperties(
              { class: (envolvedClasses || []).slice(getIndexOfHalfOfArray(envolvedClasses)) },
              scope.currentUser.organization
            )
            .then(function (studentsData2) {
              data.forEach((dataEl) => {
                if (reportConfig.customFlags && reportConfig.customFlags.indexOf("showActivityStudents") != -1) {
                  let activityStudents = [];
                  if (dataEl.class != null && Array.isArray(dataEl.class) && dataEl.class.length > 0) {
                    if (studentsData1 != null && Array.isArray(studentsData1) && studentsData1.length > 0) {
                      activityStudents = studentsData1.filter(
                        (stud) => stud.status_matricula == "Matriculado" && dataEl.class.indexOf(stud.class) != -1
                      );
                    }
                    if (
                      studentsData2 != null &&
                      Array.isArray(studentsData2) &&
                      studentsData2.length > 0 &&
                      envolvedClasses.length > 1
                    ) {
                      activityStudents = activityStudents.concat(
                        studentsData2.filter(
                          (stud) => stud.status_matricula == "Matriculado" && dataEl.class.indexOf(stud.class) != -1
                        )
                      );
                    }
                    /*  let studentsWithClassOrder = [];
                    let studentWithoutClassOrder = [];

                    studentsWithClassOrder = activityStudents.filter((stud) => stud.classOrder != null);
                    studentWithoutClassOrder = activityStudents.filter((stud) => stud.classOrder == null);

                    studentsWithClassOrder = studentsWithClassOrder.sort(function compareModules(a, b) {
                      if (a.classOrder < b.classOrder) {
                        return -1;
                      }
                      if (a.classOrder > b.classOrder) {
                        return 1;
                      }

                      return 0;
                    });

                    studentWithoutClassOrder = studentWithoutClassOrder.sort(function compareModules(a, b) {
                      if (
                        a.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "") <
                        b.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
                      ) {
                        return -1;
                      }

                      if (
                        a.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "") >
                        b.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
                      ) {
                        return 1;
                      }

                      return 0;
                    });

                    activityStudents = studentsWithClassOrder.concat(studentWithoutClassOrder); */

                    let classesMap = {};
                    activityStudents.forEach((activityStudent) => {
                      if (activityStudent.class != null && activityStudent.class != "") {
                        if (classesMap[activityStudent.class] == null) {
                          let parsedClass = scope
                            .getFromTableData("Class_Plan_Classes")
                            .filter((cl) => cl.id == activityStudent.class);
                          if (parsedClass != null && Array.isArray(parsedClass) && parsedClass.length > 0) {
                            classesMap[JSON.parse(JSON.stringify(activityStudent.class))] = parsedClass[0].class;
                            activityStudent.parsedClass = parsedClass[0].class;
                          }
                        } else {
                          activityStudent.parsedClass = classesMap[activityStudent.class];
                        }
                      }
                      activityStudent.parsedName =
                        (activityStudent.parsedClass ? activityStudent.parsedClass + " - " : "") +
                        (activityStudent.classOrder
                          ? activityStudent.classOrder < 10
                            ? "0" + activityStudent.classOrder + " - "
                            : activityStudent.classOrder + " - "
                          : "") +
                        activityStudent.name.split(" ")[0] +
                        " " +
                        activityStudent.name.split(" ")[activityStudent.name.split(" ").length - 1];
                    });

                    activityStudents = activityStudents.sort(function compareStuds(a, b) {
                      if (a.parsedName < b.parsedName) {
                        return -1;
                      }

                      if (a.parsedName > b.parsedName) {
                        return 1;
                      }

                      return 0;
                    });

                    let parsedActivityStudents = [];

                    let lastClass = null;
                    activityStudents.forEach((activityStudent) => {
                      if (activityStudent.parsedName.indexOf("-") != -1) {
                        let studClass = activityStudent.parsedName.split("-")[0];
                        if (studClass != lastClass && lastClass == null) {
                          lastClass = studClass;
                          parsedActivityStudents.push("Turma: " + studClass);
                        } else if (studClass != lastClass) {
                          lastClass = studClass;
                          parsedActivityStudents.push(" ");
                          parsedActivityStudents.push("Turma: " + studClass);
                        }
                      }
                      parsedActivityStudents.push(activityStudent.parsedName);
                    });

                    dataEl.activityStudents = [];

                    let activityStudents1 = (parsedActivityStudents || []).slice(
                      0,
                      getIndexOfHalfOfArray(parsedActivityStudents)
                    );
                    let activityStudents2 = (parsedActivityStudents || []).slice(
                      getIndexOfHalfOfArray(parsedActivityStudents)
                    );

                    for (let i = 0; i < activityStudents1.length; i++) {
                      let obj = {};
                      if (activityStudents1[i] != null) {
                        obj.left = activityStudents1[i];
                      }
                      if (activityStudents2[i] != null) {
                        obj.right = activityStudents2[i];
                      }
                      dataEl.activityStudents.push(obj);
                    }
                  }
                  sortAndSplitData();
                } else {
                  sortAndSplitData();
                }
              });
            });
        });
    } else {
      sortAndSplitData();
    }

    function sortAndSplitData() {
      data = genericReportExport(data, scope, reportConfig);
      data = parseData(scope, data);

      data.forEach((dataEl) => {
        if (dataEl.envolvedClasses != null && dataEl.envolvedClasses.length) {
          dataEl.envolvedClasses = dataEl.envolvedClasses.split(",").sort().toString().replace(/\,/g, ", ");
        }
        if (reportConfig.customFlags && reportConfig.customFlags.indexOf("showActivityStudents") != -1) {
          dataEl.showActivityStudents = true;
        }
        if (reportConfig.customFlags && reportConfig.customFlags.indexOf("showEducationPlanObjectives") != -1) {
          dataEl.showEducationPlanObjectives = true;
        }
      });

      scope.genericFactory.setRouteName("Class_Plan_Annual_Activity_Plans");
      data.sort(function (a, b) {
        if (a["year"] < b["year"]) {
          return -1;
        }
        if (a["year"] > b["year"]) {
          return 1;
        }

        if (a["cluster"] < b["cluster"]) {
          return -1;
        }
        if (a["cluster"] > b["cluster"]) {
          return 1;
        }

        if (a["school"] < b["school"]) {
          return -1;
        }
        if (a["school"] > b["school"]) {
          return 1;
        }

        if (a["activityName"] < b["activityName"]) {
          return -1;
        }
        if (a["activityName"] > b["activityName"]) {
          return 1;
        }
      });

      let splitData = [];

      Object.values(groupBy2(data, "year")).map((arr) =>
        Object.values(groupBy2(arr, "cluster")).map((arr) =>
          Object.values(groupBy2(arr, "school")).map(function (arr) {
            let finalSplitArray = Object.values(groupBy2(arr, "activityName"));
            for (let i = 0; i < finalSplitArray.length; i++) {
              const el = finalSplitArray[i];
              splitData.push(el);
            }
          })
        )
      );
      resolve(splitData);
      return splitData;
    }
  });
}

function ptHonorBoardDiploma(data, scope, reportConfig) {
  data = genericReportExport(data, scope, reportConfig);

  data.sort(function (a, b) {
    if (a["year"] < b["year"]) {
      return -1;
    }
    if (a["year"] > b["year"]) {
      return 1;
    }

    if (a["period"] < b["period"]) {
      return -1;
    }
    if (a["period"] > b["period"]) {
      return 1;
    }

    if (a["name"].localeCompare(b["name"], "pt") == -1) {
      return -1;
    }
    if (a["name"].localeCompare(b["name"], "pt") == 1) {
      return 1;
    }

    if (a["parsedHonorBoardPrizeType"] < b["parsedHonorBoardPrizeType"]) {
      return -1;
    }
    if (a["parsedHonorBoardPrizeType"] > b["parsedHonorBoardPrizeType"]) {
      return 1;
    }

    if (a["parsedHonorBoardPrize"] < b["parsedHonorBoardPrize"]) {
      return -1;
    }
    if (a["parsedHonorBoardPrize"] > b["parsedHonorBoardPrize"]) {
      return 1;
    }
  });

  let splitData = [];

  Object.values(groupBy2(data, "year")).map((arr) =>
    Object.values(groupBy2(arr, "period")).map((arr) =>
      Object.values(groupBy2(arr, "name")).map((arr) =>
        Object.values(groupBy2(arr, "parsedHonorBoardPrizeType")).map(function (arr) {
          let finalSplitArray = Object.values(groupBy2(arr, "parsedHonorBoardPrize"));
          for (let i = 0; i < finalSplitArray.length; i++) {
            const el = finalSplitArray[i];
            splitData.push(el);
          }
        })
      )
    )
  );

  return splitData;
}

function ptClassPlanReport(data, scope) {
  return new Promise((resolve, reject) => {
    data[0].classDescription = true;
    data[0].classPlanTopic = "Caracterização de Turma";
    data[0].classStrategies = JSON.stringify(data[0].classStrategies.map((obj) => (obj = obj.strategy)))
      .replace(/\"/g, "")
      .replace(/\[/g, "")
      .replace(/\]/g, "")
      .replace(/\,/g, ", ");
    data[0].classPositiveQualities = JSON.stringify(
      data[0].classPositiveQualities.map((obj) => (obj = obj.classification))
    )
      .replace(/\"/g, "")
      .replace(/\[/g, "")
      .replace(/\]/g, "")
      .replace(/\,/g, ", ");
    data[0].classNegativeQualities = JSON.stringify(
      data[0].classNegativeQualities.map((obj) => (obj = obj.classification))
    )
      .replace(/\"/g, "")
      .replace(/\[/g, "")
      .replace(/\]/g, "")
      .replace(/\,/g, ", ");

    if (data.length > 0) {
      data[0].cluster = scope.$parent.clientOrgName;
    }

    /* let map = {};
    map.schoolYear = selected.schoolYear;
    map.class = selected.class; */
    scope.genericFactory.setRouteName("Class_Plan_School_Records");
    scope.genericFactory.getByProperty("organization", scope.currentUser.organization).then(function (schoolRecord) {
      data.push({});
      data[1].evals = utilFunctions["ptSplitFinalEvalsSchoolRecord"](scope, schoolRecord);
      data[1].schoolRecord = true;
      data[1].classPlanTopic = "Avaliação";

      scope.genericFactory.setRouteName("Class_Plan_Subjects");
      scope.genericFactory.getByProperty("organization", scope.currentUser.organization).then(function (subjects) {
        for (let j = 0; j < data[1].evals.length; j++) {
          let eva = data[1].evals[j];
          if (eva.subject != null) {
            eva.subject = subjects.filter((el) => el.id == eva.subject)[0].subject;
          }
        }

        scope.genericFactory.setRouteName("Class_Plan_Education_Plan_Clusters");
        scope.genericFactory
          .getByProperty("organization", scope.currentUser.organization)
          .then(function (clusterEduPlan) {
            data.unshift({});
            data[0].schoolYear = data[1].schoolYear;
            data[0].class = data[1].class;
            data[0].cluster = data[1].cluster;

            data[0].clusterEduPlanObjs = clusterEduPlan;
            data[0].clusterEduPlan = true;
            data[0].classPlanTopic = "Projeto Educativo";

            scope.genericFactory.setRouteName("Class_Plan_Education_Plan_Areas");
            scope.genericFactory
              .getByProperty("organization", scope.currentUser.organization)
              .then(function (eduPlanAreas) {
                for (let k = 0; k < data[0].clusterEduPlanObjs.length; k++) {
                  let eduPlanObj = data[0].clusterEduPlanObjs[k];
                  eduPlanObj.cluster = scope.$parent.clientOrgName;
                  if (eduPlanObj.area != null) {
                    eduPlanObj.area = eduPlanAreas.filter((el) => el.id == eduPlanObj.area)[0].area;
                  }
                }

                /* let map = {};
            map.schoolYear = selected.schoolYear;
            map.class = selected.class; */
                scope.genericFactory.setRouteName("Class_Plan_Indisciplines");
                scope.genericFactory
                  .getByProperty("organization", scope.currentUser.organization)
                  .then(function (indisciplines) {
                    data.push({});
                    data[3].indisciplines = indisciplines;
                    data[3].indiscipline = true;
                    data[3].classPlanTopic = "Indisciplina";

                    /* let map = {};
            map.schoolYear = selected.schoolYear;
            map.class = selected.class; */
                    scope.genericFactory.setRouteName("Class_Plan_Students");
                    scope.genericFactory
                      .getByProperty("organization", scope.currentUser.organization)
                      .then(function (studs) {
                        scope.genericFactory.setRouteName("Class_Plan_Indiscipline_Occurrence_Types");
                        scope.genericFactory
                          .getByProperty("organization", scope.currentUser.organization)
                          .then(function (ocuTypes) {
                            for (let j = 0; j < data[3].indisciplines.length; j++) {
                              let inds = data[3].indisciplines[j];
                              if (inds.subject != null) {
                                inds.subject = subjects.filter((el) => el.id == inds.subject)[0].subject;
                              }
                              if (inds.name != null) {
                                inds.name = studs.filter((el) => el.id == inds.name)[0].name;
                              }
                              if (inds.indisciplineOccurrenceType != null) {
                                inds.indisciplineOccurrenceType = ocuTypes.filter(
                                  (el) => el.id == inds.indisciplineOccurrenceType
                                )[0].indisciplineOccurrenceType;
                              }
                            }

                            /* let map = {};
                  map.schoolYear = selected.schoolYear;
                  map.class = selected.class; */
                            scope.genericFactory.setRouteName("Class_Plan_Action_Plans");
                            scope.genericFactory
                              .getByProperty("organization", scope.currentUser.organization)
                              .then(function (actionPlan) {
                                data.push({});
                                data[4] = actionPlan[0];
                                data[4].studentMeasures = actionPlan[0].studentMeasures;
                                data[4].actionPlan = true;
                                data[4].classPlanTopic = "Medidas de Suporte e Apoio à Aprendizagem";

                                for (let j = 0; j < data[4].studentMeasures.length; j++) {
                                  let actPlan = data[4].studentMeasures[j];
                                  actPlan.subject = data[4].subject;
                                  actPlan.period = data[4].period;
                                  if (actPlan.subject != null) {
                                    actPlan.subject = subjects.filter((el) => el.id == actPlan.subject)[0].subject;
                                  }
                                  /* if (actPlan.name != null) {
                        actPlan.name = studs.filter(el => el.id == actPlan.name)[0].name;
                      } */
                                  if (actPlan.universalMeasures.length != 0) {
                                    actPlan.numUniversalMeasures = actPlan.universalMeasures.length;
                                  } else {
                                    actPlan.numUniversalMeasures = "0";
                                  }
                                  if (actPlan.selectiveMeasures.length != 0) {
                                    actPlan.numSelectiveMeasures = actPlan.selectiveMeasures.length;
                                  } else {
                                    actPlan.numSelectiveMeasures = "0";
                                  }
                                  if (actPlan.additionalMeasures.length != 0) {
                                    actPlan.numAdditionalMeasures = actPlan.additionalMeasures.length;
                                  } else {
                                    actPlan.numAdditionalMeasures = "0";
                                  }
                                }

                                /* let map = {};
                    map.schoolYear = selected.schoolYear;
                    map.class = selected.class; */
                                scope.genericFactory.setRouteName("Class_Plan_FlexEdu_Horizontal_Articulations");
                                scope.genericFactory
                                  .getByProperty("organization", scope.currentUser.organization)
                                  .then(function (flexHorizontal) {
                                    data.push({});
                                    data[5].flexHorizontals = flexHorizontal;
                                    data[5].flexHorizontal = true;
                                    data[5].classPlanTopic = "AFC Articulação Horizontal";

                                    scope.genericFactory.setRouteName("Class_Plan_Essential_Learnings");
                                    scope.genericFactory
                                      .getByProperty("organization", scope.currentUser.organization)
                                      .then(function (essentialLearnings) {
                                        for (let j = 0; j < data[5].flexHorizontals.length; j++) {
                                          let flex = data[5].flexHorizontals[j];
                                          flex.dacSubjectsDesc = [];
                                          flex.essenLearnDesc = [];
                                          for (let m = 0; m < flex.dacSubjects.length; m++) {
                                            let dacs = flex.dacSubjects[m];
                                            if (dacs.subject != null) {
                                              flex.dacSubjectsDesc.push(
                                                subjects.filter((el) => el.id == dacs.subject)[0].subject
                                              );
                                            }
                                            for (let n = 0; n < dacs.essentialLearning.length; n++) {
                                              let essenLearn = dacs.essentialLearning[n];
                                              flex.essenLearnDesc.push(
                                                essentialLearnings.filter((el) => el.id == essenLearn)[0]
                                                  .essentialLearning
                                              );
                                            }
                                          }
                                          flex.dacSubjectsDesc = JSON.stringify(flex.dacSubjectsDesc)
                                            .replace(/\"/g, "")
                                            .replace(/\[/g, "")
                                            .replace(/\]/g, "")
                                            .replace(/\,/g, ", ");
                                          flex.essenLearnDesc = JSON.stringify(flex.essenLearnDesc)
                                            .replace(/\"/g, "")
                                            .replace(/\[/g, "")
                                            .replace(/\]/g, "")
                                            .replace(/\,/g, ", ");
                                        }

                                        scope.genericFactory.setRouteName("Class_Plan_FlexEdu_Vertical_Articulations");
                                        scope.genericFactory
                                          .getByProperty("organization", scope.currentUser.organization)
                                          .then(function (flexVertical) {
                                            data.push({});
                                            data[6].flexVerticals = flexVertical;
                                            data[6].flexVertical = true;
                                            data[6].classPlanTopic = "AFC Articulação Vertical";

                                            for (let j = 0; j < data[6].flexVerticals.length; j++) {
                                              let flex = data[6].flexVerticals[j];
                                              flex.potentials = JSON.stringify(
                                                flex.potentials.map((obj) => (obj = obj.potential))
                                              )
                                                .replace(/\"/g, "")
                                                .replace(/\[/g, "")
                                                .replace(/\]/g, "")
                                                .replace(/\,/g, ", ");
                                              flex.fragilities = JSON.stringify(
                                                flex.fragilities.map((obj) => (obj = obj.fragility))
                                              )
                                                .replace(/\"/g, "")
                                                .replace(/\[/g, "")
                                                .replace(/\]/g, "")
                                                .replace(/\,/g, ", ");
                                            }

                                            scope.genericFactory.setRouteName("Class_Plan_Class_Descriptions");
                                            resolve(true);
                                            return data;
                                          });
                                      });
                                  });
                              });
                          });
                      });
                  });
              });
          });
      });
    });
  });
}

function etiCAFMonthlyPayments(data, scope, reportConfig) {
  data = genericReportExport(data, scope, reportConfig);

  let splitData = [];

  if (reportConfig.splitByClassFlag) {
    Object.values(groupBy2(data, "year")).map((arr) =>
      Object.values(groupBy2(arr, "regMonth")).map((arr) =>
        Object.values(groupBy2(arr, "cluster")).map((arr) =>
          Object.values(groupBy2(arr, "school")).map(function (arr) {
            let finalSplitArray = Object.values(groupBy2(arr, "class"));
            for (let i = 0; i < finalSplitArray.length; i++) {
              const el = finalSplitArray[i];
              splitData.push(el);
            }
          })
        )
      )
    );
  } else {
    Object.values(groupBy2(data, "year")).map((arr) =>
      Object.values(groupBy2(arr, "regMonth")).map((arr) =>
        Object.values(groupBy2(arr, "cluster")).map(function (arr) {
          let finalSplitArray = Object.values(groupBy2(arr, "school"));
          for (let i = 0; i < finalSplitArray.length; i++) {
            const el = finalSplitArray[i];
            splitData.push(el);
          }
        })
      )
    );
  }

  let totals = {};
  let lastNIF;

  splitData.forEach((dataArr) => {
    for (let k = 0; k < dataArr.length; k++) {
      let el = dataArr[k];

      // Remove monthlyTotalPayment from duplicate rows

      if (el.nif != lastNIF) {
        lastNIF = el.nif;
      } else {
        el.monthlyTotalPayment = null;
      }

      // Total row
      if (totals[el.parsedEducationLevel] == null) {
        totals[el.parsedEducationLevel] = 0;
      }
      totals[el.parsedEducationLevel] += el.monthlyPayment;

      //Insert total row by education level at the end
      if (k == dataArr.length - 1) {
        let totalRow = JSON.parse(JSON.stringify(el));
        totalRow.totalRow = true;
        let totalString = "";
        let totalMonthlyPayment = 0;
        for (const eduLevel in totals) {
          if (Object.hasOwnProperty.call(totals, eduLevel)) {
            let eduLevelTotal = Math.round(totals[eduLevel] * 100) / 100;
            let eduLevelName;

            //If education level is missing
            if (eduLevel == "undefined") {
              eduLevelName = "Nível ensino em falta";
            } else {
              eduLevelName = eduLevel;
            }
            totalMonthlyPayment += eduLevelTotal;
            if (totalString != "") {
              totalString += "; " + eduLevelName + ": " + eduLevelTotal + "€";
            } else {
              totalString = eduLevelName + ": " + eduLevelTotal + "€";
            }
          }
        }
        totalString += ". Total: " + Math.round(totalMonthlyPayment * 100) / 100 + "€";
        totalRow.monthlyPayment = totalString;
        dataArr.push(totalRow);
        break;
      }
    }
    totals = {};
  });

  return splitData;
}

function etiCAFMonthlyAttendance(data, scope, reportConfig) {
  data = genericReportExport(data, scope, reportConfig);

  let attendanceData = [];

  for (let k = 0; k < data.length; k++) {
    let attendanceRecord = data[k];
    if (attendanceRecord.cluster.indexOf("CIMBAL - ") != -1) {
      let splitCluster = attendanceRecord.cluster.split("CIMBAL - ");
      attendanceRecord.cluster = splitCluster[1];
    }
    for (let j = 0; j < attendanceRecord.studentAttendanceRecords.length; j++) {
      let studAttendance = attendanceRecord.studentAttendanceRecords[j];
      let newStudAttendToAdd = JSON.parse(JSON.stringify(attendanceRecord));

      newStudAttendToAdd.name = studAttendance.name;
      newStudAttendToAdd.attendanceDays = [];

      // Exclude stud records without attendances
      if (studAttendance.monthlyTotal == null) {
        continue;
      }

      let monthlyTotal = 0;

      for (let j = 1; j < newStudAttendToAdd.monthDays; j++) {
        let day = j;
        newStudAttendToAdd.attendanceDays.push(day);
        if (studAttendance["day" + day] != null) {
          if (
            studAttendance["day" + day + "Reservation"] != null &&
            studAttendance["day" + day + "Reservation"] == 1 &&
            studAttendance["day" + day] == 0
          ) {
            newStudAttendToAdd[day] = "F";
            /* if (studAttendance.monthlyTotal && !isNaN(Number(studAttendance.monthlyTotal)) && studAttendance.monthlyTotal > 0) {
              studAttendance.monthlyTotal -= 1;
            } */
          } else if (studAttendance["day" + day] != 0) {
            newStudAttendToAdd[day] = studAttendance["day" + day];
            monthlyTotal += 1;
          } else {
            newStudAttendToAdd[day] = studAttendance["day" + day];
          }
        }
      }

      newStudAttendToAdd.monthlyTotal = monthlyTotal;

      // Push stud attendances split by schedules
      studAttendance.parsedSchedulesArr.forEach((schedule) => {
        newStudAttendToAdd.schedule = schedule;
        cleanElement(newStudAttendToAdd);
        let attendToAddCopy = JSON.parse(JSON.stringify(newStudAttendToAdd));
        attendanceData.push(attendToAddCopy);
      });
    }
  }

  function cleanElement(el) {
    if (el.studentAttendanceRecords != null) {
      delete el.studentAttendanceRecords;
    }
    if (el.createdBy != null) {
      delete el.createdBy;
    }
    if (el.modifiedBy != null) {
      delete el.modifiedBy;
    }
  }

  let splitData = [];

  Object.values(groupBy2(attendanceData, "year")).map((arr) =>
    Object.values(groupBy2(arr, "regMonth")).map((arr) =>
      Object.values(groupBy2(arr, "cluster")).map((arr) =>
        Object.values(groupBy2(arr, "school")).map((arr) =>
          Object.values(groupBy2(arr, "class")).map(function (arr) {
            let finalSplitArray = Object.values(groupBy2(arr, "schedule"));
            for (let i = 0; i < finalSplitArray.length; i++) {
              const el = finalSplitArray[i];
              splitData.push(el);
            }
          })
        )
      )
    )
  );

  let totals = {};

  splitData.forEach((dataArr) => {
    for (let k = 0; k < dataArr.length; k++) {
      let el = dataArr[k];
      for (let j = 1; j < el.monthDays; j++) {
        let day = j;
        if (el[day] != null && (el[day] == 1 || el[day] == "F" || el[day] == "T")) {
          if (totals[day] == null) {
            totals[day] = 0;
          }
          totals[day] += 1;
        }
        // CIMBAL - Vidigueira count Fs, not necessary ?
        /* if (el[day] != null && el[day] == "F" && scope.currentUser.organization == "5fc118cde1fc9e0050504ef5") {
          if (totals[day] == null) {
            totals[day] = 0;
          }
          totals[day] += 1;
        } */
      }
      if (el.monthlyTotal != null && !isNaN(Number(el.monthlyTotal))) {
        if (totals.monthlyTotal == null) {
          totals.monthlyTotal = 0;
        }
        totals.monthlyTotal += el.monthlyTotal;
      }
      if (k == dataArr.length - 1) {
        let totalRow = JSON.parse(JSON.stringify(el));
        totalRow.name = "Total";
        totalRow.monthlyTotal = totals.monthlyTotal == null ? 0 : totals.monthlyTotal;
        for (let j = 1; j < el.monthDays; j++) {
          let day = j;
          if (totals[day] != null && !isNaN(Number(totals[day]))) {
            totalRow[day] = totals[day];
          }
        }
        totalRow.totalRow = true;
        dataArr.push(totalRow);
        break;
      }
    }
    totals = {};
  });

  return splitData;
}

function aseRecordsAggregatedBySchool(data, scope, reportConfig) {
  data = genericReportExport(data, scope, reportConfig);

  let schoolASEEchelonsTotals = {};

  for (let k = 0; k < data.length; k++) {
    let aseRecord = data[k];
    if (aseRecord.aseEchelon == null) {
      continue;
    }
    if (schoolASEEchelonsTotals[aseRecord.school] == null) {
      aseRecord.ATotals = 0;
      aseRecord.BTotals = 0;
      aseRecord.CTotals = 0;
      schoolASEEchelonsTotals[aseRecord.school] = aseRecord;
    }
    schoolASEEchelonsTotals[aseRecord.school][aseRecord.aseEchelon + "Totals"] += 1;
  }

  let schoolASEEchelonsData = Object.values(schoolASEEchelonsTotals);

  let splitData = [];

  Object.values(groupBy2(schoolASEEchelonsData, "year")).map(function (arr) {
    let finalSplitArray = Object.values(groupBy2(arr, "cluster"));
    for (let i = 0; i < finalSplitArray.length; i++) {
      const el = finalSplitArray[i];
      splitData.push(el);
    }
  });

  splitData.forEach((splitDataArr) => {
    let ATotals = 0;
    let BTotals = 0;
    let CTotals = 0;
    for (let j = 0; j < splitDataArr.length; j++) {
      let spliDataArrEl = splitDataArr[j];
      if (spliDataArrEl.ATotals != null) {
        ATotals += spliDataArrEl.ATotals;
      }
      if (spliDataArrEl.BTotals != null) {
        BTotals += spliDataArrEl.BTotals;
      }
      if (spliDataArrEl.CTotals != null) {
        CTotals += spliDataArrEl.CTotals;
      }
    }
    let totalRow = {};
    totalRow.totalRow = true;
    totalRow.school = "Total";
    totalRow.ATotals = ATotals;
    totalRow.BTotals = BTotals;
    totalRow.CTotals = CTotals;
    splitDataArr.push(totalRow);
  });

  return splitData;
}

function aseRecordsNominalList(data, scope, reportConfig) {
  data = genericReportExport(data, scope, reportConfig);

  data.sort(function (a, b) {
    if (a.name) {
      if (a["name"].localeCompare(b["name"], "pt") == -1) {
        return -1;
      }
      if (a["name"].localeCompare(b["name"], "pt") == 1) {
        return 1;
      }
    } else {
      return 1;
    }
  });

  let studsASEEchelonsData = [];

  for (let k = 0; k < data.length; k++) {
    let aseRecord = data[k];
    if (aseRecord.aseEchelon != null) {
      studsASEEchelonsData.push(aseRecord);
    }
  }

  let splitData = [];

  if (reportConfig.exportTitle.indexOf("P/ Escalão ASE") != -1) {
    Object.values(groupBy2(studsASEEchelonsData, "year")).map((arr) =>
      Object.values(groupBy2(arr, "cluster")).map((arr) =>
        Object.values(groupBy2(arr, "school")).map(function (arr) {
          let finalSplitArray = Object.values(groupBy2(arr, "aseEchelon"));
          for (let i = 0; i < finalSplitArray.length; i++) {
            const el = finalSplitArray[i];
            splitData.push(el);
          }
        })
      )
    );
  } else if (reportConfig.exportTitle.indexOf("P/ Turma") != -1) {
    Object.values(groupBy2(studsASEEchelonsData, "year")).map((arr) =>
      Object.values(groupBy2(arr, "cluster")).map((arr) =>
        Object.values(groupBy2(arr, "school")).map(function (arr) {
          let finalSplitArray = Object.values(groupBy2(arr, "class"));
          for (let i = 0; i < finalSplitArray.length; i++) {
            const el = finalSplitArray[i];
            splitData.push(el);
          }
        })
      )
    );
  }

  let totalsByField = {};
  let totalFields = reportConfig.totalRowFields;
  let totalStudents = 0;

  splitData.forEach((dataArr) => {
    for (let k = 0; k < dataArr.length; k++) {
      let el = dataArr[k];

      // Add total students sum
      totalStudents += 1;

      countTotalByFields(el, totalsByField, totalFields);

      if (k == dataArr.length - 1) {
        let totalRow = JSON.parse(JSON.stringify(el));
        totalRow.totalRow = true;

        parseTotalByField(el, totalsByField, totalFields, totalRow);

        totalRow.name = totalStudents;
        dataArr.push(totalRow);
        break;
      }
    }
    totalsByField = {};
    totalStudents = 0;
  });

  return splitData;
}

function caRecordsAggregatedBySchool(data, scope, reportConfig) {
  data = genericReportExport(data, scope, reportConfig);

  let schoolCATotals = {};

  for (let k = 0; k < data.length; k++) {
    let caRecord = data[k];
    if (caRecord.notebookReceived == null) {
      continue;
    }
    if (schoolCATotals[caRecord.school] == null) {
      caRecord.caReceived = 0;
      caRecord.caNotReceived = 0;
      schoolCATotals[caRecord.school] = caRecord;
    }
    if (caRecord.notebookReceived != null && caRecord.notebookReceived == "Sim") {
      schoolCATotals[caRecord.school]["caReceived"] += 1;
    } else {
      schoolCATotals[caRecord.school]["caNotReceived"] += 1;
    }
  }

  let schoolCAData = Object.values(schoolCATotals);

  let splitData = [];

  Object.values(groupBy2(schoolCAData, "year")).map(function (arr) {
    let finalSplitArray = Object.values(groupBy2(arr, "cluster"));
    for (let i = 0; i < finalSplitArray.length; i++) {
      const el = finalSplitArray[i];
      splitData.push(el);
    }
  });

  splitData.forEach((splitDataArr) => {
    let caReceived = 0;
    let caNotReceived = 0;
    for (let j = 0; j < splitDataArr.length; j++) {
      let spliDataArrEl = splitDataArr[j];
      if (spliDataArrEl.caReceived != null) {
        caReceived += spliDataArrEl.caReceived;
      }
      if (spliDataArrEl.caNotReceived != null) {
        caNotReceived += spliDataArrEl.caNotReceived;
      }
    }
    let totalRow = {};
    totalRow.totalRow = true;
    totalRow.school = "Total";
    totalRow.caReceived = caReceived;
    totalRow.caNotReceived = caNotReceived;
    splitDataArr.push(totalRow);
  });

  return splitData;
}

function caRecordsNominalList(data, scope, reportConfig) {
  data = genericReportExport(data, scope, reportConfig);

  data.sort(function (a, b) {
    if (a.name) {
      if (a["name"].localeCompare(b["name"], "pt") == -1) {
        return -1;
      }
      if (a["name"].localeCompare(b["name"], "pt") == 1) {
        return 1;
      }
    } else {
      return 1;
    }
  });

  let studsCAData = [];

  for (let k = 0; k < data.length; k++) {
    let caRecord = data[k];
    if (caRecord.notebookReceived != null) {
      studsCAData.push(caRecord);
    }
  }

  let splitData = [];

  if (reportConfig.exportTitle.indexOf("P/ Recepção") != -1) {
    Object.values(groupBy2(studsCAData, "year")).map((arr) =>
      Object.values(groupBy2(arr, "cluster")).map((arr) =>
        Object.values(groupBy2(arr, "school")).map((arr) =>
          Object.values(groupBy2(arr, "localidade")).map(function (arr) {
            let finalSplitArray = Object.values(groupBy2(arr, "notebookReceived"));
            for (let i = 0; i < finalSplitArray.length; i++) {
              const el = finalSplitArray[i];
              splitData.push(el);
            }
          })
        )
      )
    );
  } else if (reportConfig.exportTitle.indexOf("P/ Turma") != -1) {
    Object.values(groupBy2(studsCAData, "year")).map((arr) =>
      Object.values(groupBy2(arr, "cluster")).map((arr) =>
        Object.values(groupBy2(arr, "school")).map(function (arr) {
          let finalSplitArray = Object.values(groupBy2(arr, "class"));
          for (let i = 0; i < finalSplitArray.length; i++) {
            const el = finalSplitArray[i];
            splitData.push(el);
          }
        })
      )
    );
  }

  let totalsByField = {};
  let totalFields = reportConfig.totalRowFields;
  let totalStudents = 0;

  splitData.forEach((dataArr) => {
    for (let k = 0; k < dataArr.length; k++) {
      let el = dataArr[k];

      // Add total students sum
      totalStudents += 1;

      countTotalByFields(el, totalsByField, totalFields);

      if (k == dataArr.length - 1) {
        let totalRow = JSON.parse(JSON.stringify(el));
        totalRow.totalRow = true;

        parseTotalByField(el, totalsByField, totalFields, totalRow);

        totalRow.name = totalStudents;
        dataArr.push(totalRow);
        break;
      }
    }
    totalsByField = {};
    totalStudents = 0;
  });

  return splitData;
}

function kstkStudentsListing(data, scope, reportConfig) {
  data = genericReportExport(data, scope, reportConfig);

  let kstkFilteredStudents = [];

  for (let k = 0; k < data.length; k++) {
    let studRecord = data[k];
    if (reportConfig.exportTitle.indexOf("Superior") == -1) {
      if (studRecord.courseType == null || studRecord.courseType.indexOf("Superior") == -1) {
        kstkFilteredStudents.push(studRecord);
      }
    } else {
      if (studRecord.courseType && studRecord.courseType.indexOf("Superior") != -1) {
        kstkFilteredStudents.push(studRecord);
      }
    }
  }

  let splitData = [];

  if (reportConfig.exportTitle.indexOf("Ensino Regular P/ Ano de Escolaridade") != -1) {
    Object.values(groupBy2(kstkFilteredStudents, "year")).map((arr) =>
      Object.values(groupBy2(arr, "cluster")).map((arr) =>
        Object.values(groupBy2(arr, "school")).map((arr) =>
          Object.values(groupBy2(arr, "educationLevel")).map(function (arr) {
            let finalSplitArray = Object.values(groupBy2(arr, "schoolYear"));
            for (let i = 0; i < finalSplitArray.length; i++) {
              const el = finalSplitArray[i];
              splitData.push(el);
            }
          })
        )
      )
    );
  } else if (reportConfig.exportTitle.indexOf("Ensino Regular P/ Turma") != -1) {
    Object.values(groupBy2(kstkFilteredStudents, "year")).map((arr) =>
      Object.values(groupBy2(arr, "cluster")).map((arr) =>
        Object.values(groupBy2(arr, "school")).map((arr) =>
          Object.values(groupBy2(arr, "educationLevel")).map(function (arr) {
            let finalSplitArray = Object.values(groupBy2(arr, "class"));
            for (let i = 0; i < finalSplitArray.length; i++) {
              const el = finalSplitArray[i];
              splitData.push(el);
            }
          })
        )
      )
    );
  } else if (reportConfig.exportTitle.indexOf("Ensino Superior") != -1) {
    Object.values(groupBy2(kstkFilteredStudents, "year")).map(function (arr) {
      let finalSplitArray = Object.values(groupBy2(arr, "schoolYear"));
      for (let i = 0; i < finalSplitArray.length; i++) {
        const el = finalSplitArray[i];
        splitData.push(el);
      }
    });
  }

  let totalsByField = {};
  let totalFields = reportConfig.totalRowFields;
  let totalStudents = 0;

  splitData.forEach((dataArr) => {
    for (let k = 0; k < dataArr.length; k++) {
      let el = dataArr[k];

      // Add total students sum
      totalStudents += 1;

      countTotalByFields(el, totalsByField, totalFields);

      if (k == dataArr.length - 1) {
        let totalRow = JSON.parse(JSON.stringify(el));
        totalRow.totalRow = true;

        parseTotalByField(el, totalsByField, totalFields, totalRow);

        totalRow.name = totalStudents;
        dataArr.push(totalRow);
        break;
      }
    }
    totalsByField = {};
    totalStudents = 0;
  });

  return splitData;
}

function kstkStudentsListingAggregatedBySchool(data, scope, reportConfig) {
  data = genericReportExport(data, scope, reportConfig);

  let kstkStudentsTotals = {};

  for (let k = 0; k < data.length; k++) {
    let studRecord = data[k];

    if (studRecord.courseType && studRecord.courseType.indexOf("Regular") == -1) {
      continue;
    }

    if (studRecord.educationLevel) {
      studRecord.educationLevel = studRecord.educationLevel.trim();
    }
    if (studRecord.school) {
      studRecord.school = studRecord.school.trim();
    }
    if (studRecord.class) {
      studRecord.class = studRecord.class.trim();
    }

    if (kstkStudentsTotals[studRecord.cluster + studRecord.educationLevel] == null) {
      let studRecordCopy = JSON.parse(JSON.stringify(studRecord));
      studRecordCopy.school = null;
      studRecordCopy.class = null;
      studRecordCopy.educationLevelRow = true;
      kstkStudentsTotals[studRecord.cluster + studRecord.educationLevel] = studRecordCopy;
      kstkStudentsTotals[studRecord.cluster + studRecord.educationLevel].total = 1;
    } else {
      kstkStudentsTotals[studRecord.cluster + studRecord.educationLevel].total += 1;
    }
    if (kstkStudentsTotals[studRecord.cluster + studRecord.educationLevel + studRecord.school] == null) {
      let studRecordCopy = JSON.parse(JSON.stringify(studRecord));
      studRecordCopy.class = null;
      kstkStudentsTotals[studRecord.cluster + studRecord.educationLevel + studRecord.school] = studRecordCopy;
      kstkStudentsTotals[studRecord.cluster + studRecord.educationLevel + studRecord.school].total = 1;
    } else {
      kstkStudentsTotals[studRecord.cluster + studRecord.educationLevel + studRecord.school].total += 1;
    }
    if (studRecord.class && studRecord.class != "") {
      if (
        kstkStudentsTotals[studRecord.cluster + studRecord.educationLevel + studRecord.school + studRecord.class] ==
        null
      ) {
        kstkStudentsTotals[studRecord.cluster + studRecord.educationLevel + studRecord.school + studRecord.class] =
          studRecord;
        kstkStudentsTotals[
          studRecord.cluster + studRecord.educationLevel + studRecord.school + studRecord.class
        ].total = 1;
      } else {
        kstkStudentsTotals[
          studRecord.cluster + studRecord.educationLevel + studRecord.school + studRecord.class
        ].total += 1;
      }
    }
  }

  let kstkStudentsTotalsData = Object.values(kstkStudentsTotals);

  let splitData = [];

  Object.values(groupBy2(kstkStudentsTotalsData, "year")).map(function (arr) {
    let finalSplitArray = Object.values(groupBy2(arr, "cluster"));
    for (let i = 0; i < finalSplitArray.length; i++) {
      const el = finalSplitArray[i];
      splitData.push(el);
    }
  });

  splitData.forEach((splitDataArr) => {
    let totalStudents = 0;

    for (let j = 0; j < splitDataArr.length; j++) {
      let splitDataEl = splitDataArr[j];
      if (splitDataEl.educationLevelRow) {
        totalStudents += splitDataEl.total;
      }
      if (j == splitDataArr.length - 1) {
        splitDataArr.push({
          totalRow: true,
          total: totalStudents,
        });
        break;
      }
    }

    splitDataArr.sort(function (a, b) {
      if (a["educationLevel"] < b["educationLevel"]) {
        return -1;
      }
      if (a["educationLevel"] > b["educationLevel"]) {
        return 1;
      }

      if (a["school"] < b["school"]) {
        return -1;
      }
      if (a["school"] > b["school"]) {
        return 1;
      }

      /* if (a.class) {
        if (a["class"].localeCompare(b["class"], 'pt') == -1) {
          return -1;
        }
        if (a["class"].localeCompare(b["class"], 'pt') == 1) {
          return 1;
        }
      } */
    });
  });

  return splitData;
}

function kstkEconomicAid(data, scope, reportConfig) {
  data = genericReportExport(data, scope, reportConfig);

  data.sort(function (a, b) {
    if (a["schoolYear"] < b["schoolYear"]) {
      return -1;
    }
    if (a["schoolYear"] > b["schoolYear"]) {
      return 1;
    }

    if (a["name"].localeCompare(b["name"], "pt") == -1) {
      return -1;
    }
    if (a["name"].localeCompare(b["name"], "pt") == 1) {
      return 1;
    }
  });

  let splitData = [];

  let finalSplitArray = Object.values(groupBy2(data, "year"));
  for (let i = 0; i < finalSplitArray.length; i++) {
    const el = finalSplitArray[i];
    splitData.push(el);
  }

  let totalsByField = {};
  let totalFields = reportConfig.totalRowFields;
  let totalStudents = 0;
  let totalEconomicAid = 0;

  splitData.forEach((dataArr) => {
    for (let k = 0; k < dataArr.length; k++) {
      let el = dataArr[k];

      // Add total students sum
      totalStudents += 1;
      if (el.economicAid != null) {
        totalEconomicAid += el.economicAid;
      }

      countTotalByFields(el, totalsByField, totalFields);

      if (k == dataArr.length - 1) {
        let totalRow = JSON.parse(JSON.stringify(el));
        totalRow.totalRow = true;

        parseTotalByField(el, totalsByField, totalFields, totalRow);

        totalRow.name = totalStudents;
        totalRow.economicAid = totalEconomicAid;
        dataArr.push(totalRow);
        break;
      }
    }
    totalsByField = {};
    totalStudents = 0;
    totalEconomicAid = 0;
  });

  return splitData;
}

function kstkCommunityWork(data, scope, reportConfig) {
  data = genericReportExport(data, scope, reportConfig);

  data.sort(function (a, b) {
    if (a["schoolYear"] < b["schoolYear"]) {
      return -1;
    }
    if (a["schoolYear"] > b["schoolYear"]) {
      return 1;
    }

    if (a["name"].localeCompare(b["name"], "pt") == -1) {
      return -1;
    }
    if (a["name"].localeCompare(b["name"], "pt") == 1) {
      return 1;
    }
  });

  let splitData = [];

  Object.values(groupBy2(data, "year")).map(function (arr) {
    let finalSplitArray = Object.values(groupBy2(arr, "localidade"));
    for (let i = 0; i < finalSplitArray.length; i++) {
      const el = finalSplitArray[i];
      splitData.push(el);
    }
  });

  let totalsByField = {};
  let totalFields = reportConfig.totalRowFields;
  let totalStudents = 0;

  splitData.forEach((dataArr) => {
    for (let k = 0; k < dataArr.length; k++) {
      let el = dataArr[k];

      // Add total students sum
      totalStudents += 1;

      countTotalByFields(el, totalsByField, totalFields);

      if (k == dataArr.length - 1) {
        let totalRow = JSON.parse(JSON.stringify(el));
        totalRow.totalRow = true;

        parseTotalByField(el, totalsByField, totalFields, totalRow);

        totalRow.name = totalStudents;
        dataArr.push(totalRow);
        break;
      }
    }
    totalsByField = {};
    totalStudents = 0;
  });

  return splitData;
}

function kstkNonTeachingStaffListingByClusterSchool(data, scope, reportConfig) {
  data = genericReportExport(data, scope, reportConfig);

  data.sort(function (a, b) {
    if (a["cluster"] < b["cluster"]) {
      return -1;
    }
    if (a["cluster"] > b["cluster"]) {
      return 1;
    }

    if (a["school"] < b["school"]) {
      return -1;
    }
    if (a["school"] > b["school"]) {
      return 1;
    }

    if (a["name"].localeCompare(b["name"], "pt") == -1) {
      return -1;
    }
    if (a["name"].localeCompare(b["name"], "pt") == 1) {
      return 1;
    }
  });

  let splitData = [];

  Object.values(groupBy2(data, "year")).map((arr) =>
    Object.values(groupBy2(arr, "cluster")).map((arr) =>
      Object.values(groupBy2(arr, "school")).map(function (arr) {
        let finalSplitArray = Object.values(groupBy2(arr, "school"));
        for (let i = 0; i < finalSplitArray.length; i++) {
          const el = finalSplitArray[i];
          splitData.push(el);
        }
      })
    )
  );

  let totalsByField = {};
  let totalFields = reportConfig.totalRowFields;
  let totalStudents = 0;

  splitData.forEach((dataArr) => {
    for (let k = 0; k < dataArr.length; k++) {
      let el = dataArr[k];

      // Calculate last development points

      if (el.developmentEvaluation && Array.isArray(el.developmentEvaluation) && el.developmentEvaluation.length > 0) {
        for (let i = 0; i < el.developmentEvaluation.length; i++) {
          let devEval = el.developmentEvaluation[i];
          if (i == el.developmentEvaluation.length - 1) {
            el.developmentEvaluationLastPoints =
              "Pontos Acumulados: " +
              (devEval.accumulatedPoints ? devEval.accumulatedPoints : 0) +
              "; Pontos Adquiridos: " +
              (devEval.acquiredPoints ? devEval.acquiredPoints : 0);
          }
        }
      }

      // Add total students sum
      totalStudents += 1;

      countTotalByFields(el, totalsByField, totalFields);

      if (k == dataArr.length - 1) {
        let totalRow = JSON.parse(JSON.stringify(el));
        totalRow.totalRow = true;

        parseTotalByField(el, totalsByField, totalFields, totalRow);

        totalRow.name = totalStudents;
        dataArr.push(totalRow);
        break;
      }
    }
    totalsByField = {};
    totalStudents = 0;
  });

  return splitData;
}

function kstkNonTeachingStaffAbsenceListing(data, scope, reportConfig) {
  data = genericReportExport(data, scope, reportConfig);

  data.sort(function (a, b) {
    if (Number(a["year"]) < Number(b["year"])) {
      return -1;
    }
    if (Number(a["year"]) > Number(b["year"])) {
      return 1;
    }

    if (a["name"].localeCompare(b["name"], "pt") == -1) {
      return -1;
    }
    if (a["name"].localeCompare(b["name"], "pt") == 1) {
      return 1;
    }
  });

  let splitData = [];

  let finalSplitArray = Object.values(groupBy2(data, "year"));
  for (let i = 0; i < finalSplitArray.length; i++) {
    const el = finalSplitArray[i];
    splitData.push(el);
  }

  let totalsByField = {};
  let totalFields = reportConfig.totalRowFields;
  let totalStudents = 0;

  splitData.forEach((dataArr) => {
    for (let k = 0; k < dataArr.length; k++) {
      let el = dataArr[k];

      // Calculate last development points

      // Add total students sum
      totalStudents += 1;

      countTotalByFields(el, totalsByField, totalFields);

      if (k == dataArr.length - 1) {
        let totalRow = JSON.parse(JSON.stringify(el));
        totalRow.totalRow = true;

        parseTotalByField(el, totalsByField, totalFields, totalRow);

        totalRow.name = totalStudents;
        dataArr.push(totalRow);
        break;
      }
    }
    totalsByField = {};
    totalStudents = 0;
  });

  return splitData;
}

function kstkNonTeachingStaffListingByClusterFunction(data, scope, reportConfig) {
  data = genericReportExport(data, scope, reportConfig);

  data.sort(function (a, b) {
    if (a["cluster"] < b["cluster"]) {
      return -1;
    }
    if (a["cluster"] > b["cluster"]) {
      return 1;
    }

    if (a["function"] < b["function"]) {
      return -1;
    }
    if (a["function"] > b["function"]) {
      return 1;
    }

    if (a["name"].localeCompare(b["name"], "pt") == -1) {
      return -1;
    }
    if (a["name"].localeCompare(b["name"], "pt") == 1) {
      return 1;
    }

    if (a["excludedRatio"] < b["excludedRatio"]) {
      return -1;
    }
    if (a["excludedRatio"] > b["excludedRatio"]) {
      return 1;
    }
  });

  let splitData = [];

  Object.values(groupBy2(data, "year")).map((arr) =>
    Object.values(groupBy2(arr, "cluster")).map((arr) =>
      Object.values(groupBy2(arr, "function")).map(function (arr) {
        let finalSplitArray = Object.values(groupBy2(arr, "excludedRatio"));
        for (let i = 0; i < finalSplitArray.length; i++) {
          const el = finalSplitArray[i];
          splitData.push(el);
        }
      })
    )
  );

  let totalsByField = {};
  let totalFields = reportConfig.totalRowFields;
  let totalStudents = 0;

  splitData.forEach((dataArr) => {
    for (let k = 0; k < dataArr.length; k++) {
      let el = dataArr[k];

      // Calculate last development points

      if (el.developmentEvaluation && Array.isArray(el.developmentEvaluation) && el.developmentEvaluation.length > 0) {
        for (let i = 0; i < el.developmentEvaluation.length; i++) {
          let devEval = el.developmentEvaluation[i];
          if (i == el.developmentEvaluation.length - 1) {
            el.developmentEvaluationLastPoints =
              "Pontos Acumulados: " +
              (devEval.accumulatedPoints ? devEval.accumulatedPoints : 0) +
              "; Pontos Adquiridos: " +
              (devEval.acquiredPoints ? devEval.acquiredPoints : 0);
          }
        }
      }

      // Add total students sum
      totalStudents += 1;

      countTotalByFields(el, totalsByField, totalFields);

      if (k == dataArr.length - 1) {
        let totalRow = JSON.parse(JSON.stringify(el));
        totalRow.totalRow = true;

        parseTotalByField(el, totalsByField, totalFields, totalRow);

        totalRow.name = totalStudents;
        dataArr.push(totalRow);
        break;
      }
    }
    totalsByField = {};
    totalStudents = 0;
  });

  return splitData;
}

function kstkNonTeachingStaffListing(data, scope, reportConfig) {
  data = genericReportExport(data, scope, reportConfig);

  data.sort(function (a, b) {
    if (a["cluster"] < b["cluster"]) {
      return -1;
    }
    if (a["cluster"] > b["cluster"]) {
      return 1;
    }

    if (a["school"] < b["school"]) {
      return -1;
    }
    if (a["school"] > b["school"]) {
      return 1;
    }

    if (a["name"].localeCompare(b["name"], "pt") == -1) {
      return -1;
    }
    if (a["name"].localeCompare(b["name"], "pt") == 1) {
      return 1;
    }
  });

  let splitData = [];

  Object.values(groupBy2(data, "year")).map(function (arr) {
    let finalSplitArray = Object.values(groupBy2(arr, "function"));
    for (let i = 0; i < finalSplitArray.length; i++) {
      const el = finalSplitArray[i];
      splitData.push(el);
    }
  });

  let totalStudents = 0;

  splitData.forEach((dataArr) => {
    for (let k = 0; k < dataArr.length; k++) {
      let el = dataArr[k];

      // Add total students sum
      totalStudents += 1;

      if (k == dataArr.length - 1) {
        let totalRow = JSON.parse(JSON.stringify(el));
        totalRow.totalRow = true;
        totalRow.name = totalStudents;
        dataArr.push(totalRow);
        break;
      }
    }
    totalStudents = 0;
  });

  return splitData;
}

function kstkNonTeachingStaffAggregatedBySchool(data, scope, reportConfig) {
  data = genericReportExport(data, scope, reportConfig);

  data.sort(function (a, b) {
    if (a["cluster"] < b["cluster"]) {
      return -1;
    }
    if (a["cluster"] > b["cluster"]) {
      return 1;
    }

    if (a["school"] < b["school"]) {
      return -1;
    }
    if (a["school"] > b["school"]) {
      return 1;
    }
  });

  let schoolNonTeachStaffTotals = {};

  let totalsByField = {};
  let totalsBySchool = {};
  let totalFields = reportConfig.totalRowFields;
  let totalNonTeachStaff = 0;
  let lastCluster = null;
  let totalRow;

  for (let k = 0; k < data.length; k++) {
    let nonTeachStaffRecord = data[k];
    if (nonTeachStaffRecord.situation != "Ativo") {
      continue;
    }
    if (lastCluster != nonTeachStaffRecord.cluster) {
      lastCluster = nonTeachStaffRecord.cluster;
    } else {
      nonTeachStaffRecord.cluster = null;
    }
    totalNonTeachStaff += 1;
    if (schoolNonTeachStaffTotals[nonTeachStaffRecord.school] == null) {
      nonTeachStaffRecord.nonTeachStaffTotal = 0;
      nonTeachStaffRecord.functionTotal = "";
      nonTeachStaffRecord.professionalBondTotal = "";
      nonTeachStaffRecord.netMonthRemunerationTotal = "";
      schoolNonTeachStaffTotals[nonTeachStaffRecord.school] = nonTeachStaffRecord;
    }
    if (totalsBySchool[nonTeachStaffRecord.school] == null) {
      totalsBySchool[nonTeachStaffRecord.school] = {};
    }
    schoolNonTeachStaffTotals[nonTeachStaffRecord.school].nonTeachStaffTotal += 1;

    countTotalByFields(nonTeachStaffRecord, totalsByField, totalFields);
    countTotalByFields(nonTeachStaffRecord, totalsBySchool[nonTeachStaffRecord.school], totalFields);

    if (k == data.length - 1) {
      totalRow = JSON.parse(JSON.stringify(nonTeachStaffRecord));
      totalRow.totalRow = true;

      parseTotalByField(nonTeachStaffRecord, totalsByField, totalFields, totalRow);

      totalRow.nonTeachStaffTotal = totalNonTeachStaff;
      data.push(totalRow);
      break;
    }
  }

  let schoolNonTeachStaffData = Object.values(schoolNonTeachStaffTotals);
  schoolNonTeachStaffData.forEach((el) => {
    parseTotalByField(el, totalsBySchool[el.school], totalFields, el);
  });
  schoolNonTeachStaffData.push(totalRow);

  let splitData = [schoolNonTeachStaffData];

  return splitData;
}

function schoolComplexMaterialBySchool(data, scope, reportConfig) {
  data = genericReportExport(data, scope, reportConfig);

  data.sort(function (a, b) {
    if (a["spaceArea"] < b["spaceArea"]) {
      return -1;
    }
    if (a["spaceArea"] > b["spaceArea"]) {
      return 1;
    }

    if (a["classification"] < b["classification"]) {
      return -1;
    }
    if (a["classification"] > b["classification"]) {
      return 1;
    }

    if (a["equipment"] < b["equipment"]) {
      return -1;
    }
    if (a["equipment"] > b["equipment"]) {
      return 1;
    }
  });

  let dataArray = [];

  if (reportConfig.exportTitle.indexOf("Propostas") == -1) {
    dataArray = data;
  } else {
    for (let k = 0; k < data.length; k++) {
      let el = data[k];
      if (
        el.typeOfDescription == "Caracterização Múltipla" &&
        el.multipleDescription &&
        Array.isArray(el.multipleDescription) &&
        el.multipleDescription.length > 0
      ) {
        for (let w = 0; w < el.multipleDescription.length; w++) {
          let multipleDesc = el.multipleDescription[w];
          if (multipleDesc.correctiveMeasureProposal && multipleDesc.correctiveMeasureProposal == "Sim") {
            dataArray.push(el);
            break;
          }
        }
      }
    }
  }

  let splitData = [];

  Object.values(groupBy2(dataArray, "year")).map((arr) =>
    Object.values(groupBy2(arr, "cluster")).map((arr) =>
      Object.values(groupBy2(arr, "school")).map((arr) =>
        Object.values(groupBy2(arr, "spaceArea")).map(function (arr) {
          let finalSplitArray = Object.values(groupBy2(arr, "cafeteriaModality"));
          for (let i = 0; i < finalSplitArray.length; i++) {
            const el = finalSplitArray[i];
            splitData.push(el);
          }
        })
      )
    )
  );

  let totalsByField = {};
  let totalFields = reportConfig.totalRowFields;
  let totalEquipments = 0;

  splitData.forEach((dataArr) => {
    for (let k = 0; k < dataArr.length; k++) {
      let el = dataArr[k];

      // Add total equipments sum
      totalEquipments += 1;

      // Round equipment quantity
      if (el.quantity != null && !isNaN(el.quantity)) {
        el.quantity = Math.round(el.quantity);
      }

      // Calculated equipment quantity if multiple
      if (
        el.typeOfDescription == "Caracterização Múltipla" &&
        el.multipleDescription &&
        Array.isArray(el.multipleDescription) &&
        el.multipleDescription.length > 0
      ) {
        let equipQuantityState = [];
        let equipQuantity = 0;
        el.multipleDescription.forEach((desc) => {
          if (desc.quantity != null && !isNaN(desc.quantity)) {
            desc.quantity = Math.round(desc.quantity);
            equipQuantity += desc.quantity;
            if (desc.state != null) {
              if (desc.correctiveMeasure != null) {
                if (
                  reportConfig.exportTitle.indexOf("Propostas") != -1 &&
                  desc.correctiveMeasureProposal &&
                  desc.correctiveMeasureProposal == "Sim"
                ) {
                  equipQuantityState.push(
                    desc.quantity + " - " + desc.state + " (" + desc.correctiveMeasure + " - Proposta) ; "
                  );
                } else {
                  equipQuantityState.push(desc.quantity + " - " + desc.state + " (" + desc.correctiveMeasure + ") ; ");
                }
              } else {
                equipQuantityState.push(desc.quantity + " - " + desc.state + "; ");
              }
            } else {
              equipQuantityState.push(desc.quantity + " - Sem estado; ");
            }
          }
        });
        // If total quantity by corrective measure was already calculated
        if (el.total != null) {
          equipQuantity = el.total;
        }
        if (equipQuantityState.length > 0) {
          el.quantity = equipQuantity;
          el.state = equipQuantityState.toString().replace(/,/g, "");
        }
      }

      countTotalByFields(el, totalsByField, totalFields);

      if (k == dataArr.length - 1) {
        let totalRow = JSON.parse(JSON.stringify(el));
        totalRow.totalRow = true;

        parseTotalByField(el, totalsByField, totalFields, totalRow);

        totalRow.equipment = totalEquipments;
        dataArr.push(totalRow);
        break;
      }
    }
    totalsByField = {};
    totalEquipments = 0;
  });

  return splitData;
}

function eqavetStudentListing(data, scope, reportConfig) {
  data = genericReportExport(data, scope, reportConfig);

  let splitData = [];

  Object.values(groupBy2(data, "year")).map((arr) =>
    Object.values(groupBy2(arr, "cluster")).map((arr) =>
      Object.values(groupBy2(arr, "school")).map((arr) =>
        Object.values(groupBy2(arr, "course")).map(function (arr) {
          let finalSplitArray = Object.values(groupBy2(arr, "coursePlanRef"));
          for (let i = 0; i < finalSplitArray.length; i++) {
            const el = finalSplitArray[i];
            splitData.push(el);
          }
        })
      )
    )
  );

  let totals = {};
  let totals2 = {};
  let totalStudents = 0;

  splitData.forEach((dataArr) => {
    for (let k = 0; k < dataArr.length; k++) {
      let el = dataArr[k];

      // Add total students sum
      totalStudents += 1;

      if (reportConfig.exportTitle.indexOf("EQAVET 4a") != -1) {
        if (totals[el.courseConclusionState] == null) {
          totals[el.courseConclusionState] = 0;
        }
        totals[el.courseConclusionState] += 1;
      } else if (reportConfig.exportTitle.indexOf("EQAVET 5a") != -1) {
        if (totals[el.employmentType] == null) {
          totals[el.employmentType] = 0;
        }
        totals[el.employmentType] += 1;
        if (totals2[el.contractType] == null) {
          totals2[el.contractType] = 0;
        }
        totals2[el.contractType] += 1;
      } else if (reportConfig.exportTitle.indexOf("EQAVET 6a") != -1) {
        if (totals[el.professionRelatedToCourseContract] == null) {
          totals[el.professionRelatedToCourseContract] = 0;
        }
        totals[el.professionRelatedToCourseContract] += 1;
      }

      //Insert total row by EQAVET topic
      if (k == dataArr.length - 1) {
        let totalRow = JSON.parse(JSON.stringify(el));
        totalRow.totalRow = true;
        let totalString = "";
        //let sum = 0;
        for (const topic in totals) {
          if (Object.hasOwnProperty.call(totals, topic)) {
            let topicTotal = totals[topic];
            let topicName;

            if (topic == "undefined") {
              topicName = "Em falta";
            } else {
              topicName = topic;
            }
            //sum += topicTotal;
            if (totalString != "") {
              totalString += "; " + topicName + ": " + topicTotal;
            } else {
              totalString = topicName + ": " + topicTotal;
            }
          }
        }
        //totalString += (". Total: " + sum);

        if (reportConfig.exportTitle.indexOf("EQAVET 4a") != -1) {
          totalRow.courseConclusionState = totalString;
        } else if (reportConfig.exportTitle.indexOf("EQAVET 5a") != -1) {
          totalRow.employmentType = totalString;
          let totalString2 = "";
          for (const topic in totals2) {
            if (Object.hasOwnProperty.call(totals2, topic)) {
              let topicTotal = totals2[topic];
              let topicName;

              if (topic == "undefined") {
                topicName = "Em falta";
              } else {
                topicName = topic;
              }
              if (totalString2 != "") {
                totalString2 += "; " + topicName + ": " + topicTotal;
              } else {
                totalString2 = topicName + ": " + topicTotal;
              }
            }
          }
          totalRow.contractType = totalString2;
        } else if (reportConfig.exportTitle.indexOf("EQAVET 6a") != -1) {
          totalRow.professionRelatedToCourseContract = totalString;
        }

        totalRow.name = totalStudents;
        dataArr.push(totalRow);
        break;
      }
    }

    totals = {};
    totals2 = {};
    totalStudents = 0;
  });

  return splitData;
}

function kstkSchoolEntitiesFile(data, scope, reportConfig) {
  data = genericReportExport(data, scope, reportConfig);

  data.sort(function (a, b) {
    if (a.name) {
      if (Number(a["year"]) < Number(b["year"])) {
        return -1;
      }
      if (Number(a["year"]) > Number(b["year"])) {
        return 1;
      }

      if (a["cluster"] < b["cluster"]) {
        return -1;
      }
      if (a["cluster"] > b["cluster"]) {
        return 1;
      }

      if (a["school"] < b["school"]) {
        return -1;
      }
      if (a["school"] > b["school"]) {
        return 1;
      }
    } else {
      return 1;
    }
  });

  data.forEach((el) => {
    let containerName = scope.currentUser.organization + scope.module.collection;
    scope.storageFactory.getContainer(containerName).then((ct) => {
      scope.storageFactory.listFilesInContainer(containerName).then((fileList) => {
        for (let k = 0; k < fileList.data.length; k++) {
          let storedFile = fileList.data[k];
          if (storedFile.name.indexOf(el.id) != -1) {
            if (el.auxDocsParsed == null) {
              el.auxDocsParsed = [];
            }
            el.auxDocsParsed.push(parseFileName(storedFile));
          }
        }
        if (el.auxDocsParsed != null) {
          el.auxDocsParsed = el.auxDocsParsed.sort().toString().replace(/,/g, ", ");
        }
      });
    });
  });

  let splitData = [];

  Object.values(groupBy2(data, "year")).map((arr) =>
    Object.values(groupBy2(arr, "cluster")).map(function (arr) {
      let finalSplitArray = Object.values(groupBy2(arr, "school"));
      for (let i = 0; i < finalSplitArray.length; i++) {
        const el = finalSplitArray[i];
        splitData.push(el);
      }
    })
  );

  return splitData;
}

function kstkTransportStudListing(data, scope, reportConfig) {
  data = genericReportExport(data, scope, reportConfig);

  if (reportConfig.exportTitle.indexOf("Utilizações") != -1) {
    data.sort(function (a, b) {
      if (
        a.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "") <
        b.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
      ) {
        return -1;
      }

      if (
        a.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "") >
        b.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
      ) {
        return 1;
      }
    });
  } else {
    data.sort(function (a, b) {
      if (a.name) {
        if (Number(a["year"]) < Number(b["year"])) {
          return -1;
        }
        if (Number(a["year"]) > Number(b["year"])) {
          return 1;
        }

        if (a["cluster"] < b["cluster"]) {
          return -1;
        }
        if (a["cluster"] > b["cluster"]) {
          return 1;
        }

        if (a["school"] < b["school"]) {
          return -1;
        }
        if (a["school"] > b["school"]) {
          return 1;
        }

        if (Number(a["schoolYear"]) < Number(b["schoolYear"])) {
          return -1;
        }
        if (Number(a["schoolYear"]) > Number(b["schoolYear"])) {
          return 1;
        }

        if (
          a.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "") <
          b.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
        ) {
          return -1;
        }

        if (
          a.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "") >
          b.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
        ) {
          return 1;
        }
      } else {
        return 1;
      }
    });
  }

  let splitData = [];

  if (reportConfig.exportTitle.indexOf("Utilizações") != -1) {
    Object.values(groupBy2(data, "year")).map((arr) =>
      Object.values(groupBy2(arr, "month")).map((arr) =>
        Object.values(groupBy2(arr, "cluster")).map((arr) =>
          Object.values(groupBy2(arr, "school")).map(function (arr) {
            let finalSplitArray = Object.values(groupBy2(arr, "educationLevel"));
            for (let i = 0; i < finalSplitArray.length; i++) {
              const el = finalSplitArray[i];
              splitData.push(el);
            }
          })
        )
      )
    );
  } else {
    Object.values(groupBy2(data, "year")).map((arr) =>
      Object.values(groupBy2(arr, "cluster")).map(function (arr) {
        let finalSplitArray = Object.values(groupBy2(arr, "school"));
        for (let i = 0; i < finalSplitArray.length; i++) {
          const el = finalSplitArray[i];
          splitData.push(el);
        }
      })
    );
  }

  let totalsByField = {};
  let totalFields = reportConfig.totalRowFields;
  let totalStudents = 0;
  let totalPaidValue = 0;

  splitData.forEach((dataArr) => {
    for (let k = 0; k < dataArr.length; k++) {
      let el = dataArr[k];

      // Add total students sum
      totalStudents += 1;
      // Add paid value sum
      if (!isNaN(Number(el.paidValue))) {
        totalPaidValue += el.paidValue;
      }

      countTotalByFields(el, totalsByField, totalFields);

      if (k == dataArr.length - 1) {
        let totalRow = JSON.parse(JSON.stringify(el));
        totalRow.totalRow = true;

        parseTotalByField(el, totalsByField, totalFields, totalRow);

        totalRow.name = totalStudents;
        if (totalPaidValue >= 0) {
          totalRow.paidValue = Math.round(totalPaidValue * 100) / 100;
        }
        dataArr.push(totalRow);
        break;
      }
    }
    totalsByField = {};
    totalStudents = 0;
    totalPaidValue = 0;
  });

  return splitData;
}

function etlStudentAddressInformation(data, scope, reportConfig) {
  data = genericReportExport(data, scope, reportConfig);

  /* data.sort(function (a, b) {
    if (a.name) {
      if (a["name"].localeCompare(b["name"], "pt") == -1) {
        return -1;
      }
      if (a["name"].localeCompare(b["name"], "pt") == 1) {
        return 1;
      }
    } else {
      return 1;
    }
  }); */

  let splitData = [];

  Object.values(groupBy2(data, "agrupamento")).map(function (arr) {
    let finalSplitArray = Object.values(groupBy2(arr, "escola"));
    for (let i = 0; i < finalSplitArray.length; i++) {
      const el = finalSplitArray[i];
      splitData.push(el);
    }
  });

  let totalsByField = {};
  let totalFields = reportConfig.totalRowFields;
  let totalStudents = 0;

  splitData.forEach((dataArr) => {
    for (let k = 0; k < dataArr.length; k++) {
      let el = dataArr[k];

      // Add total students sum
      totalStudents += 1;

      countTotalByFields(el, totalsByField, totalFields);

      if (k == dataArr.length - 1) {
        let totalRow = JSON.parse(JSON.stringify(el));
        totalRow.totalRow = true;

        parseTotalByField(el, totalsByField, totalFields, totalRow);

        totalRow.name = totalStudents;
        dataArr.push(totalRow);
        break;
      }
    }
    totalsByField = {};
    totalStudents = 0;
  });

  return splitData;
}

function leanGameComplaintsKPIs(data, scope, reportConfig) {
  data = genericReportExport(data, scope, reportConfig);

  /* data.sort(function (a, b) {
    if (a["schoolYear"] < b["schoolYear"]) {
      return -1;
    }
    if (a["schoolYear"] > b["schoolYear"]) {
      return 1;
    }

    if (a["name"].localeCompare(b["name"], "pt") == -1) {
      return -1;
    }
    if (a["name"].localeCompare(b["name"], "pt") == 1) {
      return 1;
    }
  }); */

  let avgTimeHandlingComplaints = {};
  let envolvedChannels = [];
  let complaintsCheckedByQM = {};

  for (let d = 0; d < data.length; d++) {
    const dataEl = data[d];
    let gameTeamDataKey = dataEl.game + dataEl.team;
    dataEl.complaint = dataEl.complaint.substring(0, 50) + "...";
    let complaintsCheckedByQMKey =
      reportConfig.customFlags && reportConfig.customFlags.indexOf("splitByTeams") != -1
        ? gameTeamDataKey
        : dataEl.game;
    dataEl.complaintsCheckedByQMKey = complaintsCheckedByQMKey;
    if (complaintsCheckedByQM[complaintsCheckedByQMKey] == null) {
      complaintsCheckedByQM[complaintsCheckedByQMKey] = {};
      complaintsCheckedByQM[complaintsCheckedByQMKey].checked = 0;
      complaintsCheckedByQM[complaintsCheckedByQMKey].notChecked = 0;
    }
    if (dataEl.checkedQM != null && dataEl.checkedQM == "Checked") {
      complaintsCheckedByQM[complaintsCheckedByQMKey].checked += 1;
    } else {
      complaintsCheckedByQM[complaintsCheckedByQMKey].notChecked += 1;
    }

    // Count number of complaints and avg time handling all of the complaints
    if (dataEl.timingIn != null && dataEl.timingOut != null) {
      if (avgTimeHandlingComplaints[dataEl.game] == null) {
        avgTimeHandlingComplaints[dataEl.game] = {};
      }
      if (avgTimeHandlingComplaints[gameTeamDataKey] == null) {
        avgTimeHandlingComplaints[gameTeamDataKey] = {};
      }
      if (avgTimeHandlingComplaints[gameTeamDataKey + dataEl.channel] == null) {
        avgTimeHandlingComplaints[gameTeamDataKey + dataEl.channel] = {};
        if (envolvedChannels.indexOf(dataEl.channel) == -1) {
          envolvedChannels.push(dataEl.channel);
        }
      }

      // Avg per game
      if (avgTimeHandlingComplaints[dataEl.game].handlingTime == null) {
        avgTimeHandlingComplaints[dataEl.game].handlingTime = dataEl.timingOut - dataEl.timingIn;
      } else {
        avgTimeHandlingComplaints[dataEl.game].handlingTime += dataEl.timingOut - dataEl.timingIn;
      }
      if (avgTimeHandlingComplaints[dataEl.game].totalComplaints == null) {
        avgTimeHandlingComplaints[dataEl.game].totalComplaints = 1;
      } else {
        avgTimeHandlingComplaints[dataEl.game].totalComplaints += 1;
      }

      // Avg per game + team
      if (avgTimeHandlingComplaints[gameTeamDataKey].handlingTime == null) {
        avgTimeHandlingComplaints[gameTeamDataKey].handlingTime = dataEl.timingOut - dataEl.timingIn;
      } else {
        avgTimeHandlingComplaints[gameTeamDataKey].handlingTime += dataEl.timingOut - dataEl.timingIn;
      }
      if (avgTimeHandlingComplaints[gameTeamDataKey].totalComplaints == null) {
        avgTimeHandlingComplaints[gameTeamDataKey].totalComplaints = 1;
      } else {
        avgTimeHandlingComplaints[gameTeamDataKey].totalComplaints += 1;
      }

      // Avg per game + team + channel
      if (avgTimeHandlingComplaints[gameTeamDataKey + dataEl.channel].handlingTime == null) {
        avgTimeHandlingComplaints[gameTeamDataKey + dataEl.channel].handlingTime = dataEl.timingOut - dataEl.timingIn;
      } else {
        avgTimeHandlingComplaints[gameTeamDataKey + dataEl.channel].handlingTime += dataEl.timingOut - dataEl.timingIn;
      }
      if (avgTimeHandlingComplaints[gameTeamDataKey + dataEl.channel].totalComplaints == null) {
        avgTimeHandlingComplaints[gameTeamDataKey + dataEl.channel].totalComplaints = 1;
      } else {
        avgTimeHandlingComplaints[gameTeamDataKey + dataEl.channel].totalComplaints += 1;
      }
    }
  }

  // Get the avg in minutes and seconds
  for (const key in avgTimeHandlingComplaints) {
    if (Object.hasOwnProperty.call(avgTimeHandlingComplaints, key)) {
      let gameTeamAvgTimeHandlingComplaints = avgTimeHandlingComplaints[key];
      getParsedAvgTimeHandlingComplaint(gameTeamAvgTimeHandlingComplaints);
    }
  }

  function getParsedAvgTimeHandlingComplaint(complaintHandlingTimes) {
    complaintHandlingTimes.handlingTime =
      Math.round((complaintHandlingTimes.handlingTime / complaintHandlingTimes.totalComplaints) * 100) / 100;
    if (complaintHandlingTimes.handlingTime / 1000 > 60) {
      let minutes = Math.floor(complaintHandlingTimes.handlingTime / 60000);
      let seconds = Math.round((complaintHandlingTimes.handlingTime - minutes * 60000) / 1000);
      if (seconds < 10) {
        seconds = "0" + seconds;
      }
      complaintHandlingTimes.handlingTime = minutes + ":" + seconds;
    } else {
      complaintHandlingTimes.handlingTime = Math.round(complaintHandlingTimes.handlingTime / 1000);
    }
  }

  // Sort envolved channels

  envolvedChannels.sort();

  // Insert avg handling in each dataEl

  data.forEach((dataEl) => {
    if (complaintsCheckedByQM[dataEl.complaintsCheckedByQMKey] != null) {
      dataEl.complaintsCheckedByQM =
        "Checked: " +
        complaintsCheckedByQM[dataEl.complaintsCheckedByQMKey].checked +
        "; Without check: " +
        complaintsCheckedByQM[dataEl.complaintsCheckedByQMKey].notChecked;
    }
    if (avgTimeHandlingComplaints[dataEl.game] != null) {
      dataEl.gameComplaintHandlingTime = avgTimeHandlingComplaints[dataEl.game].handlingTime;
    }
    if (avgTimeHandlingComplaints[dataEl.game + dataEl.team] != null) {
      dataEl.gameTeamComplaintHandlingTime = avgTimeHandlingComplaints[dataEl.game + dataEl.team].handlingTime;
    }
    envolvedChannels.forEach((envolvedChannel) => {
      if (avgTimeHandlingComplaints[dataEl.game + dataEl.team + envolvedChannel] != null) {
        if (dataEl.gameTeamChannelComplaintHandlingTime != null) {
          dataEl.gameTeamChannelComplaintHandlingTime = dataEl.gameTeamChannelComplaintHandlingTime + "; ";
        } else {
          dataEl.gameTeamChannelComplaintHandlingTime = "";
        }
        dataEl.gameTeamChannelComplaintHandlingTime =
          dataEl.gameTeamChannelComplaintHandlingTime +
          envolvedChannel +
          ": " +
          avgTimeHandlingComplaints[dataEl.game + dataEl.team + envolvedChannel].handlingTime;
      }
    });
  });

  let splitData = [];

  if (reportConfig.customFlags && reportConfig.customFlags.indexOf("splitByTeams") != -1) {
    Object.values(groupBy2(data, "game")).map(function (arr) {
      let finalSplitArray = Object.values(groupBy2(arr, "team"));
      for (let i = 0; i < finalSplitArray.length; i++) {
        const el = finalSplitArray[i];
        splitData.push(el);
      }
    });
  } else {
    let finalSplitArray = Object.values(groupBy2(data, "game"));
    for (let i = 0; i < finalSplitArray.length; i++) {
      const el = finalSplitArray[i];
      splitData.push(el);
    }
  }

  let totalsByField = {};
  let totalFields = reportConfig.totalRowFields;
  let totalComplaints = 0;

  splitData.forEach((dataArr) => {
    for (let k = 0; k < dataArr.length; k++) {
      let el = dataArr[k];

      // Add total students sum
      totalComplaints += 1;

      countTotalByFields(el, totalsByField, totalFields);

      if (k == dataArr.length - 1) {
        let totalRow = JSON.parse(JSON.stringify(el));
        totalRow.totalRow = true;

        parseTotalByField(el, totalsByField, totalFields, totalRow);

        totalRow.complaint = totalComplaints;
        dataArr.push(totalRow);
        break;
      }
    }
    totalsByField = {};
    totalComplaints = 0;
  });

  return splitData;
}

// Aux Reporting

function groupBy2(xs, prop) {
  var grouped = {};
  for (var i = 0; i < xs.length; i++) {
    var p = xs[i][prop];
    if (!grouped[p]) {
      grouped[p] = [];
    }
    grouped[p].push(xs[i]);
  }
  return grouped;
}

function countTotalByFields(el, totalsByField, totalFields) {
  totalFields.forEach((field) => {
    if (el[field] != null && el[field] != "") {
      if (totalsByField[field] == null) {
        totalsByField[field] = {};
      }
      if (totalsByField[field][el[field]] == null) {
        totalsByField[field][el[field]] = 0;
      }
      totalsByField[field][el[field]] += 1;
    }
  });
}

function parseTotalByField(el, totalsByField, totalFields, totalRow) {
  totalFields.forEach((field) => {
    let totalString = "";
    for (const topic in totalsByField[field]) {
      if (Object.hasOwnProperty.call(totalsByField[field], topic)) {
        let topicTotal = totalsByField[field][topic];
        let topicName;

        if (topic == "undefined") {
          topicName = "Em falta";
        } else {
          topicName = topic;
        }

        if (totalString != "") {
          totalString += "; " + topicName + ": " + topicTotal;
        } else {
          totalString = topicName + ": " + topicTotal;
        }
      }
    }
    totalRow[field] = totalString;
  });
}
